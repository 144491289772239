import * as React from "react";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { IButtonProps } from "@fluentui/react/lib/Button";
import { FocusTrapZone } from "@fluentui/react/lib/FocusTrapZone";
import { css } from "@emotion/css";

const overflowProps: IButtonProps = { ariaLabel: "More commands" };

export const ScheduleJECommandBar: React.FunctionComponent<{
  onBulkTransferClicked: () => void;
}> = (props) => {
  const [enableFocusTrap] = React.useState(false);

  return (
    <div
      className={css`
        width: 100%;
        min-height: 1px;
        box-sizing: content-box;
        overflow: hidden;
      `}
    >
      <FocusTrapZone disabled={!enableFocusTrap}>
        <CommandBar
          items={[
            {
              key: "editTransfer",
              text: "Bulk Transfer",
              cacheKey: "editTransfer",
              iconProps: { iconName: "Edit" },
              // disabled: props.disableAllButSignOff(),
              onClick: () => props.onBulkTransferClicked()
            }
          ]}
          overflowButtonProps={overflowProps}
          styles={{
            root: {
              paddingLeft: 0
            }
          }}
          ariaLabel="Inbox actions"
          primaryGroupAriaLabel="Bulk Edit actions"
          farItemsGroupAriaLabel="More actions"
        />
      </FocusTrapZone>
    </div>
  );
};
