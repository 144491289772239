import { css } from "@emotion/css";
import {
  IconButton,
  Shimmer,
  ShimmerElementsGroup,
  ShimmerElementType,
  Stack,
  Text,
  TextField,
  Theme,
  TooltipHost
} from "@fluentui/react";

import React from "react";
import { LoadingStatus, RefetchButton } from "../../../Shared";

import { gridStyle, textFieldStyles } from "../../shared";
import { GLJeDetailsState } from "./GLDetailsForm.types";

export interface JETitleProps {
  theme: Theme;
  state: GLJeDetailsState;
  disabled: boolean;
  loadingStatus: LoadingStatus;
  hiddenBackButton: boolean;
  lastRefreshed: number;
  onRefresh: () => void;
  onNavigateBack: () => void;
}

const JEDetailsTitle: React.FC<JETitleProps> = (props) => {
  return (
    <>
      <Stack horizontal={false}>
        <Stack horizontal {...{ tokens: gridStyle }}>
          {props.hiddenBackButton ? null : (
            <Stack.Item align="center">
              <TooltipHost
                content="Back to Previous"
                // This id is used on the tooltip itself, not the host
                // (so an element with this id only exists when the tooltip is shown)
                calloutProps={{ gapSpace: 0 }}
                styles={{ root: { display: "inline-block" } }}
                setAriaDescribedBy={false}
              >
                <IconButton
                  aria-label="Back to Dashboard"
                  iconProps={{
                    iconName: "ArrowUpRightMirrored8",
                    styles: {
                      root: {
                        transform: "rotate(-45deg)"
                      }
                    }
                  }}
                  onClick={() => {
                    props.onNavigateBack();
                  }}
                />
              </TooltipHost>
            </Stack.Item>
          )}
          <Stack.Item
            align="center"
            {...{
              tokens: gridStyle
            }}
            grow
          >
            <div
              className={css(`
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                  `)}
            >
              {props.loadingStatus === LoadingStatus.Pending ? (
                <>
                  <Stack horizontal>
                    <Stack.Item align="center">
                      <Text
                        className={css`
                          display: block;
                          padding-top: 0.33em !important;
                          margin-top: -4px !important;
                          margin-right: 8px !important;
                          line-height: 2.25em;
                          & span {
                            font-size: 12px;
                            color: ${props.theme.palette.neutralSecondary};
                          }
                        `}
                        variant="xxLarge"
                      >
                        JE
                      </Text>
                    </Stack.Item>
                    <Stack.Item align="center">
                      <Shimmer
                        customElementsGroup={
                          <ShimmerElementsGroup
                            shimmerElements={[{ type: ShimmerElementType.line, width: 180, height: 40 }]}
                          />
                        }
                      ></Shimmer>
                    </Stack.Item>
                  </Stack>
                </>
              ) : (
                <Stack
                  horizontal
                  verticalAlign="center"
                  horizontalAlign="space-between"
                  wrap
                  {...{
                    tokens: {
                      childrenGap: 10
                    },
                    styles: {
                      root: {
                        width: "100%"
                      }
                    }
                  }}
                >
                  <Stack.Item align="center" grow>
                    <Text
                      className={css`
                        display: block;
                        padding-top: 0.33em !important;
                        margin-top: -4px !important;
                        line-height: 2.25em;
                        & span {
                          font-size: 12px;
                          color: ${props.theme.palette.neutralSecondary};
                        }
                      `}
                      variant="xxLarge"
                    >
                      JE {props.state.detailsTab.documentNumber}
                    </Text>
                  </Stack.Item>
                  <Stack.Item align="center">
                    <RefetchButton
                      lastRefreshed={props.lastRefreshed}
                      onRefetch={props.onRefresh}
                      disabled={props.disabled}
                      hidden={props.loadingStatus !== LoadingStatus.Resolved}
                    />
                  </Stack.Item>
                </Stack>
              )}
            </div>
          </Stack.Item>
        </Stack>
        <Stack.Item align="start">
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              flex-wrap: wrap;
            `}
          >
            <TextField
              styles={textFieldStyles(props.theme)}
              label="Status"
              borderless
              placeholder={props.state.header.status}
            />
            <TextField
              styles={textFieldStyles(props.theme)}
              label="Author"
              borderless
              placeholder={props.state.header.author}
            />
            <TextField
              styles={textFieldStyles(props.theme)}
              label="Poster"
              borderless
              placeholder={
                props.state.header.isAutoJe && props.state.header.isTenantOverridePosting
                  ? `${props.state.header.author}(${props.state.header.poster})`
                  : props.state.header.poster
              }
            />
            <TextField
              styles={textFieldStyles(props.theme)}
              label="Reviewer"
              borderless
              placeholder={
                props.state.header.reviewerAction !== "" && props.state.header.status === "Signed Off"
                  ? props.state.header.reviewerAction
                  : props.state.header.reviewer.split(";")[0]
              }
            />
            <TextField
              styles={textFieldStyles(props.theme)}
              label="Date Modified"
              borderless
              placeholder={props.state.header.dateModified}
            />
          </div>
        </Stack.Item>
      </Stack>
    </>
  );
};

JEDetailsTitle.displayName = "JETitle";

export { JEDetailsTitle };
