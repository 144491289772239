import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import React, { useEffect } from "react";
import ActionButtons from "../../copiedItems/ActionButtons";
import { JEMChecklistDetailsRow, LocalMessage } from "@jem/components";
import { useForm } from "react-hook-form";
import {
  Stack,
  Label,
  FocusZone,
  DetailsList,
  Text,
  DetailsListLayoutMode,
  MessageBarType,
  MessageBar
} from "@fluentui/react";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";

interface IJEMChecklistDetailsConvertNileToManualPanelProps {
  checklistItems: JEMChecklistDetailsRow[];
  checklistName: string;
  checklistPeriod: string;
  checklistRefGUID: string;
  checklistOwners: string | undefined;
  userAlias: string;
  onClosePanel: () => void;
  onSaveItem: (checklistItems: JEMChecklistDetailsRow[] | []) => void;
  loading?: boolean;
  error: string[];
}

const JEMChecklistDetailsConvertNileToManualPanel = (
  props: IJEMChecklistDetailsConvertNileToManualPanelProps
): JSX.Element => {
  const { checklistItems, onClosePanel, onSaveItem, error, loading, checklistOwners, userAlias } = props;

  const [checkBoxChecked, setCheckboxChecked] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<LocalMessage | null>(null);
  const [notEligibleUserMessage, setNotEligibleUserMessage] = React.useState<LocalMessage | null>(null);
  const [nileChecklistItems, setNileChecklistItems] = React.useState<JEMChecklistDetailsRow[]>([]);

  const form = useForm();
  const { handleSubmit } = form;

  useEffect(() => {
    const nileJes = checklistItems.filter((item) => !item.isTaskEditable && item.dtpIdentifier !== undefined);
    const manualJes = checklistItems.filter((item) => item.isTaskEditable);
    if (manualJes.length > 0) {
      setMessage({
        type: nileJes.length ? MessageBarType.warning : MessageBarType.error,
        message: `This action can be performed only on ${nileJes.length} records. ${manualJes.length} records are ineligible.`
      });
    }
    setNileChecklistItems(nileJes);
  }, [checklistItems]);

  useEffect(() => {
    let eligibleUserFlag = false;
    if (checklistOwners && userAlias) {
      if (checklistOwners.split(";").includes(userAlias)) {
        eligibleUserFlag = true;
      }
    }
    if (eligibleUserFlag === false) {
      setNotEligibleUserMessage({
        type: MessageBarType.error,
        message: `You are not eligible to convert to manual.`
      });
    }
  }, [checklistOwners, userAlias]);

  const _columns = [
    { key: "column1", name: "JETaskName", fieldName: "jeTaskName", minWidth: 100, maxWidth: 200, isResizable: true },
    {
      key: "column2",
      name: "PublishedState",
      fieldName: "publishedState",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  const onHandleSubmit = () => {
    onSaveItem(nileChecklistItems);
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText="Convert to manual"
      isOpen
      onDismiss={onClosePanel}
      hasCloseButton
      closeButtonAriaLabel="Close Convert to manual JE panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          disabled={nileChecklistItems.length == 0 || loading || !checkBoxChecked || notEligibleUserMessage !== null}
          mutationLoading={false}
          closePanel={onClosePanel}
          handleSubmit={handleSubmit(onHandleSubmit)}
          saveLabel="Convert"
          saveTitle="Convert"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
          hasCheckbox={true}
          checkboxMessage="Are you sure you want to convert this entry to manual?"
          onCheckBoxChecked={(checked: boolean) => setCheckboxChecked(checked)}
          checkBoxState={checkBoxChecked}
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={error} />
      <form onSubmit={onHandleSubmit}>
        <Stack tokens={{ childrenGap: 20 }}>
          {notEligibleUserMessage && (
            <MessageBar
              messageBarType={notEligibleUserMessage.type}
              isMultiline={true}
              onDismiss={() => setMessage(null)}
              dismissButtonAriaLabel="Close"
            >
              {notEligibleUserMessage.message}
            </MessageBar>
          )}
          {message && (
            <MessageBar
              messageBarType={message.type}
              isMultiline={true}
              onDismiss={() => setMessage(null)}
              dismissButtonAriaLabel="Close"
            >
              {message.message}
            </MessageBar>
          )}
          <span></span>
          <Label> Checklist {props.checklistName}</Label>
          <Label>Posting Period {props.checklistPeriod}</Label>

          {nileChecklistItems.length > 0 ? (
            <>
              <Text
                variant="xLarge"
                style={{
                  color: "var(--accent-font-color, black)"
                }}
              >
                Checklist System JE tasks to be converted to manual JE
              </Text>
              <FocusZone>
                <div>
                  <DetailsList
                    items={nileChecklistItems}
                    columns={_columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                  />
                </div>
              </FocusZone>
            </>
          ) : (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              No Items to show.
            </Text>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default JEMChecklistDetailsConvertNileToManualPanel;
