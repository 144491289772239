import { css } from "@emotion/css";
import {
  CheckboxVisibility,
  ConstrainMode,
  DetailsListLayoutMode,
  IColumn,
  IColumnDragDropDetails,
  IconButton,
  IDetailsList,
  IDetailsListStyles,
  MarqueeSelection,
  MessageBar,
  MessageBarType,
  ScrollablePane,
  ScrollbarVisibility,
  Selection,
  SelectionMode,
  ShimmeredDetailsList,
  TooltipHost,
  useTheme
} from "@fluentui/react";
import React, { useCallback, useEffect, useImperativeHandle, useReducer, useState } from "react";
import { v4 } from "uuid";
import { RequireAtLeastOne } from "../../utilities/TypeUtils";
import { debounce, LoadingStatus } from "../../utilities/Utilities";
import { onRenderDetailsHeader, onRenderItemColumn, onRenderRow } from "./DashboardGrid.cells";
import {
  assertNoDuplicates,
  columnReorderingHandling,
  extendColumns,
  rearrangeColumns,
  sliceData
} from "./DashboardGrid.columns";
import { DashboardGridColumnSelector } from "./DashboardGrid.columnSelector";
import { dangerZoneWidthCalculationNoTypeSafety } from "./DashboardGrid.dangerzone";
import { exportFunctions, getItemsFromSelection, rowsAndColumnsToExport } from "./DashboardGrid.export";
import { DashboardGridPaginate, IDashboardGridPaginateProps } from "./DashboardGrid.pagination";
import { copyAndSort } from "./DashboardGrid.sort";
import {
  IDashboardGridProps,
  IDetailsState,
  IDetailsStateReducer,
  IExtendedColumn,
  IInternalExtendedColumn,
  IRearrangeOptions,
  IUserConfigurationReducer,
  UserConfiguration
} from "./DashboardGrid.types";
import { LoadingSpinner } from "../LoadingSpinner";

// eslint-disable-next-line
const isEqual = require("lodash/isEqual");

const setData = (
  selectedPage: number,
  defaultPageSize: number,
  pageCount: React.MutableRefObject<number>,
  itemCount: React.MutableRefObject<number>,
  allItems: any[],
  setPreviewData: React.Dispatch<RequireAtLeastOne<IDetailsState, keyof IDetailsState>>,
  setDetailState: React.Dispatch<RequireAtLeastOne<IDetailsState, keyof IDetailsState>>,
  isAISearchEnabled: boolean
) => {
  let newData;
  if (!isAISearchEnabled) {
    newData = sliceData(allItems, selectedPage - 1, defaultPageSize);
    pageCount.current = Math.ceil(allItems.length / defaultPageSize);
    itemCount.current = allItems.length;
  } else {
    const totalPages = Math.ceil(allItems.length / defaultPageSize);
    const startIndex = (selectedPage - 1) % totalPages;
    newData = sliceData(allItems, startIndex, defaultPageSize);
    pageCount.current = Math.ceil(itemCount.current / defaultPageSize);
  }

  setPreviewData({ currentItems: newData, defaultPageSize });
  setDetailState({ gridItems: allItems, defaultPageSize });
};

function applyUserConfiguration(
  userConfiguration: UserConfiguration,
  columns: IInternalExtendedColumn[],
  options?: IRearrangeOptions
) {
  const theColumns = rearrangeColumns(userConfiguration, columns, options);
  if (userConfiguration.selectedColumns === null) {
    return theColumns;
  } else {
    const filteredColumns = theColumns.filter((col) => userConfiguration.selectedColumns?.indexOf(col.name) !== -1);
    return filteredColumns;
  }
}

const initialUserConfiguration = (
  isSortedIndex: number | null,
  isSortedDescending: boolean,
  isInitial = false
): UserConfiguration => ({
  columnPositions: null,
  columnWidths: null,
  selectedColumns: null,
  width: "100%",
  isSortedIndex,
  isSortedDescending,
  defaultPageSize: 40,
  version: "0.4.0",
  name: "",
  isInitial
});

const getUserConfiguration = (id: string, isSortedIndex: number | null, isSortedDescending: boolean) => {
  const initialUserConfig = initialUserConfiguration(isSortedIndex, isSortedDescending, false);
  try {
    const config = localStorage.getItem(id);
    if (config) {
      const userConfig = JSON.parse(config);
      if (!("isInitial" in userConfig)) {
        userConfig.isInitial = false;
      }
      if (userConfig.version !== initialUserConfig.version || !userConfig.name) {
        return Object.assign({}, initialUserConfig, { name: id });
      }
      return userConfig;
    }
  } catch (e) {
    console.error(e);
  }
  return initialUserConfig;
};

let DashboardGrid: React.FC<IDashboardGridProps> = (props) => {
  assertNoDuplicates(props.columnGenerator);
  const detailsListComponentRef = React.useRef<IDetailsList>(null);
  const selectedPage = React.useRef<number>(1);
  const pageCount = React.useRef<number>(0);
  const itemCount = React.useRef<number>(0);
  const theme = useTheme();
  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        if (props.onSelectionChanged) {
          props.onSelectionChanged(selection);
        }
      }
    })
  );

  const isAISearchEnabled = props.items[0]?.searchType === "AI";
  const [detailsState, setDetailsState] = useReducer<IDetailsStateReducer>(
    (previousState, changes) => ({
      ...previousState,
      ...changes
    }),
    {
      allColumns: [],
      gridItems: [],
      currentItems: [],
      disableSorting: false,
      isSortedIndex: undefined,
      isSortedDescending: false,
      defaultPageSize: 40
    }
  );

  // The Key is used for Refreshing the grid, otherwise the DetailsList will maintain
  // the state
  const [key, setKey] = useState(v4());
  const [userConfiguration, setUserConfiguration] = useReducer<IUserConfigurationReducer>(
    (previousState, changes) => ({
      ...previousState,
      ...changes
    }),
    initialUserConfiguration(props.isSortedIndex || null, !!props.isSortedDescending, true)
  );

  const [initialLoad, setInitialLoad] = useState(false);
  const [columnsForDropdown, setColumnsForDropdown] = useState<string[]>([]);
  const [columns, setColumns] = useState<IExtendedColumn[]>([]);
  const [exportMessageVisible, setExportMessageVisible] = useState(false); // State for export message
  useImperativeHandle(props.customRef, () => ({
    clearSelection() {
      selection.setItems(detailsState.currentItems, true);
    },
    getSelection() {
      return {
        items: selection.getSelection(),
        isAllSelected: selection.isAllSelected()
      };
    }
  }));

  useEffect(function onLoadGrid() {
    /*
      As soon as it mounts
      Move over the Users Configuration's into the local state
    */
    const userConfigChanges = getUserConfiguration(
      props.idForLocalStorage,
      props.isSortedIndex || null,
      !!props.isSortedDescending
    );
    selection.setItems(detailsState.currentItems, true);
    setInitialLoad(true);
    setUserConfiguration(userConfigChanges as RequireAtLeastOne<UserConfiguration>);
    //
  }, []);

  useEffect(
    function onDevUserPropsChanged() {
      // TAKE THE SORT INDEX FROM CONFIGURATION PROPS IF IT EXISTS
      // OTHERWISE TAKE IT FROM REGULAR PROPS
      if (userConfiguration.isInitial) {
        return;
      }
      if (props.idForLocalStorage !== userConfiguration.name) {
        const userConfigChanges = getUserConfiguration(
          props.idForLocalStorage,
          props.isSortedIndex || null,
          !!props.isSortedDescending
        );
        setInitialLoad(true);
        setUserConfiguration(userConfigChanges as RequireAtLeastOne<UserConfiguration>);
        return;
      }
      // items changed but previous defaultPageSize was all items
      let defaultPageSize = userConfiguration.defaultPageSize;
      if (![40, 60, 80, 100].includes(defaultPageSize)) {
        defaultPageSize = props.items.length;
      }
      pageCount.current = isAISearchEnabled
        ? Math.ceil(props.items[0]?.totalCount / defaultPageSize)
        : Math.ceil(props.items.length / defaultPageSize);

      itemCount.current = isAISearchEnabled ? props.items[0]?.totalCount : props.items.length;

      selectedPage.current = 1;
      const sortIdx =
        typeof userConfiguration.isSortedIndex === "number" ? userConfiguration.isSortedIndex : props.isSortedIndex;
      const isSortDescending = userConfiguration.isSortedDescending
        ? userConfiguration.isSortedDescending
        : !!props.isSortedDescending;
      const theColumns = extendColumns(
        props.columnGenerator,
        selectedPage.current,
        defaultPageSize,
        props.items,
        setDetailsState,
        !!props.disableSorting,
        sortIdx,
        isSortDescending
      );
      let currentItems;
      let gridItems;
      if (sortIdx !== null && sortIdx !== undefined) {
        let sortCol = theColumns[sortIdx];
        if (!sortCol) {
          console.warn("Sort Index is out of range");
          sortCol = theColumns[0];
        }
        if (sortCol.fieldName && sortCol.type !== "object") {
          gridItems = copyAndSort(props.items, sortCol.fieldName, sortCol.type, isSortDescending);
          currentItems = props.disablePagination
            ? gridItems
            : sliceData(gridItems, selectedPage.current - 1, defaultPageSize);
        }
      }
      if (!currentItems) {
        currentItems = props.disablePagination
          ? props.items
          : sliceData(props.items, selectedPage.current - 1, defaultPageSize);
      }
      if (!gridItems) {
        gridItems = props.items;
      }
      setDetailsState({
        allColumns: theColumns,
        disableSorting: !!props.disableSorting,
        isSortedIndex: sortIdx,
        isSortedDescending: isSortDescending,
        gridItems,
        currentItems,
        defaultPageSize
      });
    },
    [
      props.columnGenerator,
      props.items,
      props.isSortedIndex,
      props.isSortedDescending,
      props.disableSorting,
      props.idForLocalStorage,
      // careful with these ones
      // changes in DetailsState trigger UserConfiguration
      // changes which trigger changes in DetailsState again
      userConfiguration.name,
      userConfiguration.isInitial,
      userConfiguration.defaultPageSize
    ]
  );

  useEffect(
    function onGridStateChanged() {
      if (userConfiguration.isInitial) {
        return;
      }
      const newColumnsForDropdown = ["All"].concat(detailsState.allColumns.map((item) => item.name).sort());
      if (!isEqual(columnsForDropdown, newColumnsForDropdown)) {
        setColumnsForDropdown(newColumnsForDropdown);
      }

      setUserConfiguration({
        isSortedIndex: detailsState.isSortedIndex,
        isSortedDescending: detailsState.isSortedDescending,
        defaultPageSize: detailsState.defaultPageSize
      });
    },
    [detailsState]
  );

  useEffect(
    function onAppUserConfigurationChanged() {
      /** Any changes to the configuration mean we have to change
       * the columns and their listeners.
       * Things that trigger a configuration:
       *  - Columns reordered
       *  - Columns resized (debounced)
       *  - Columns Removed or Inserted
       *  - Column clicked (Data sorted)
       *  - Back to default
       *  - Page size changed
       */
      if (detailsState.allColumns.length === 0) {
        return;
      }
      let lInitialLoad = initialLoad;
      const isInitialConfiguration =
        userConfiguration.isInitial ||
        isEqual(userConfiguration, initialUserConfiguration(props.isSortedIndex || null, !!props.isSortedDescending));
      let defaultPageSize = detailsState.defaultPageSize;
      let isSortedIndex = detailsState.isSortedIndex;
      let isSortedDescending = detailsState.isSortedDescending;
      if (isInitialConfiguration && columns.length !== 0) {
        const width = dangerZoneWidthCalculationNoTypeSafety(detailsListComponentRef);
        if (width !== userConfiguration.width) {
          lInitialLoad = true;
          isSortedIndex = props.isSortedIndex;
          isSortedDescending = !!props.isSortedDescending;
          setKey(v4());
        }
      }

      if (defaultPageSize != userConfiguration.defaultPageSize) {
        defaultPageSize = userConfiguration.defaultPageSize;
      }

      pageCount.current = isAISearchEnabled
        ? Math.ceil(props.items[0]?.totalCount / defaultPageSize)
        : Math.ceil(props.items.length / defaultPageSize);

      itemCount.current = isAISearchEnabled ? props.items[0]?.totalCount : props.items.length;

      const newColumns = extendColumns(
        props.columnGenerator,
        selectedPage.current,
        defaultPageSize,
        props.items,
        setDetailsState,
        !!props.disableSorting,
        isSortedIndex,
        isSortedDescending
      );
      const finalColumns = applyUserConfiguration(userConfiguration, newColumns, {
        initialLoad: lInitialLoad
      });
      if (isEqual(columns, finalColumns) && lInitialLoad) {
        return;
      }
      setColumns(finalColumns);
      if (initialLoad) {
        setInitialLoad(false);
      }
      //
    },
    [userConfiguration]
  );

  useEffect(
    function saveAppUsersConfigurationToLocalStorage() {
      localStorage.setItem(props.idForLocalStorage, JSON.stringify(userConfiguration));
    },
    [userConfiguration]
  );

  const fetchDataAndSetState = async (pageSize: number, pageIndex: number, totalCount: number): Promise<any> => {
    let combinedData;
    try {
      if (props.refetchPaginatedGridData) {
        const newdata = await props.refetchPaginatedGridData(pageSize, pageIndex, totalCount, false);
        // Concatenate new data with existing data
        combinedData = pageIndex === 0 ? newdata : [...detailsState.gridItems, ...newdata];
        // Update detailsState.gridItems with new data
        //const uniqueDataMap = new Map(combinedData.map((item) => [item.idForUI, item]));
        //const updatedGridItems = Array.from(combinedData.values());
        setDetailsState({
          gridItems: combinedData,
          defaultPageSize: pageSize
        });
      }
    } catch (error) {
      console.error("Error in refetchGridData:", error);
    }

    return combinedData;
  };

  const onPageChange: IDashboardGridPaginateProps["onPageChange"] = async (
    _startItemIndex: number,
    _endItemIndex: number,
    _selectedPage: number
  ): Promise<void> => {
    selectedPage.current = _selectedPage;
    let refetchdata;

    if (selectedPage.current === detailsState.currentItems[0]?.nextPageIndex) {
      refetchdata = await fetchDataAndSetState(
        detailsState.defaultPageSize,
        detailsState.gridItems[detailsState.gridItems.length - 1]?.nextPageIndex,
        detailsState.gridItems[detailsState.gridItems.length - 1]?.totalCount
      );
    }

    setData(
      _selectedPage,
      detailsState.defaultPageSize,
      pageCount,
      itemCount,
      refetchdata ? refetchdata : detailsState.gridItems,
      setDetailsState,
      setDetailsState,
      isAISearchEnabled
    );
  };

  const onPageSizeChange = async (newPageSize: string | number): Promise<void> => {
    const newDefaultPageSize =
      newPageSize === 999 ? (isAISearchEnabled ? 1000 : detailsState.gridItems.length) : (newPageSize as number);
    if (newDefaultPageSize !== detailsState.defaultPageSize) {
      selectedPage.current = 1;
      const pageIndex = 0;
      const totalCount = 0;
      const newPageSizeData = await fetchDataAndSetState(newDefaultPageSize, pageIndex, totalCount);
      pageCount.current = isAISearchEnabled
        ? Math.ceil(props.items[0].totalCount / newDefaultPageSize)
        : Math.ceil(props.items.length / newDefaultPageSize);

      setData(
        selectedPage.current,
        newDefaultPageSize,
        pageCount,
        itemCount,
        newPageSizeData ? newPageSizeData : detailsState.gridItems,
        setDetailsState,
        setDetailsState,
        isAISearchEnabled
      );
    }
  };

  const onSelectionChange = useCallback((newSelection: string[] | null) => {
    setUserConfiguration({
      selectedColumns: newSelection
    });
  }, []);

  const onDefaultConfigClick = useCallback(() => {
    setUserConfiguration(initialUserConfiguration(props.isSortedIndex || null, !!props.isSortedDescending, true));
  }, [props.isSortedIndex, props.isSortedDescending]);

  const exportUserSelectionToExcel = async () => {
    detailsState.gridItems[detailsState.gridItems.length - 1]?.totalCount >
    detailsState.gridItems[detailsState.gridItems.length - 1]?.exportLimit
      ? setExportMessageVisible(true)
      : setExportMessageVisible(false); // Show the export message
    let items;
    if (
      detailsState.gridItems[detailsState.gridItems.length - 1]?.nextPageIndex &&
      detailsState.gridItems[detailsState.gridItems.length - 1]?.nextPageIndex != -1
    ) {
      const searchResult = props.refetchPaginatedGridData
        ? await props.refetchPaginatedGridData(detailsState.defaultPageSize, 0, 0, true)
        : [];
      items = getItemsFromSelection(selection, searchResult);
    } else {
      items = getItemsFromSelection(selection, detailsState.gridItems);
    }
    const headerAndRows = rowsAndColumnsToExport(columns, items);
    const excelRows: string[][] = exportFunctions.toExcelFile(headerAndRows);
    if (props.onExport) {
      props.onExport(excelRows);
    }
    detailsState.gridItems[detailsState.gridItems.length - 1]?.totalCount > 36000
      ? setExportMessageVisible(true)
      : setExportMessageVisible(false); // Show the export message
    setTimeout(() => setExportMessageVisible(false), 50000); // Hide the message after 5 seconds
  };
  return (
    <>
      <div
        className={css`
          display: flex;
          width: 100%;
          flex-direction: column;
          margin-top: 4px;
        `}
      >
        <div
          className={css`
            display: grid;
            grid-template-columns: minmax(0, 1fr) 120px;
            grid-gap: 16px;
            align-items: center;
            justify-content: space-between;
            position: relative;

            @media (max-width: 639px) {
              grid-template-columns: minmax(0, 1fr);
              grid-template-rows: auto auto;
              grid-gap: 8px;
              justify-content: space-between;
            }
          `}
        >
          <div
            className={css`
              grid-column: 1;
              position: relative;

              @media (max-width: 639px) {
                width: calc(100% - 8px);
              }
            `}
          >
            {props.children}
            {exportMessageVisible && (
              <MessageBar messageBarType={MessageBarType.info} isMultiline={false}>
                Current search exceed limit of {detailsState?.gridItems[0]?.exportLimit} , Please change the
                Searchcriteria to minimize the search results.
                {/* For additional records, visit the{" "}
                <a
                  href="https://msit.powerbi.com/groups/dcd92dba-f9ef-4947-aa5e-09cbe0f96a44/reports/8e4b2c38-1841-496a-837e-7cf504a49cc8/ReportSection8100c17a8952c9ede794?experience=power-bi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Power BI report
                </a>
                . */}
              </MessageBar>
            )}
          </div>
          <div
            className={css`
              grid-column: 2;
              width: 120px;
              display: block;
              @media (max-width: 639px) {
                grid-column: 1;
                width: 100%;
                display: flex;
                justify-content: center;
              }
            `}
          >
            {props.onExport ? (
              <TooltipHost content="Export to Excel">
                <IconButton
                  iconProps={{ iconName: "Download" }}
                  onClick={exportUserSelectionToExcel}
                  ariaLabel="Export to Excel button"
                />
              </TooltipHost>
            ) : null}

            <DashboardGridColumnSelector
              columnsForDropdown={columnsForDropdown}
              selectedColumns={userConfiguration.selectedColumns}
              onDefaultClick={onDefaultConfigClick}
              onSelectionChange={onSelectionChange}
            />
          </div>
        </div>
        <div
          className={css`
            min-width: 100%;
            overflow: auto;
            max-height: ${props.height};
            height: ${props.height};
            position: relative;
            margin-top: 10px;
          `}
          data-is-scrollable="true"
          onKeyDown={(e) => {
            const key = e.which || e.keyCode;
            const ctrlPressed = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false;
            const C_KEY_CODE = 67;
            if (key == C_KEY_CODE && ctrlPressed) {
              const items = getItemsFromSelection(selection, detailsState.gridItems);
              const headerAndRows = rowsAndColumnsToExport(columns, items);
              const toExport = exportFunctions.toClipboard(headerAndRows);
              navigator.clipboard.writeText(toExport);
            }
          }}
        >
          {(props.loadingStatus ? props.loadingStatus === LoadingStatus.Pending : false) ? (
            <LoadingSpinner label={"Loading details"} />
          ) : (
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <MarqueeSelection selection={selection}>
                <ShimmeredDetailsList
                  key={key}
                  componentRef={detailsListComponentRef}
                  columns={columns}
                  styles={
                    userConfiguration.width
                      ? ({
                          root: {
                            width: userConfiguration.width ? "99%" : "500px",
                            userSelect: "auto"
                          }
                        } as Partial<IDetailsListStyles>)
                      : undefined
                  }
                  items={detailsState.currentItems}
                  selection={selection}
                  selectionPreservedOnEmptyClick={true}
                  enableShimmer={props.loadingStatus ? props.loadingStatus === LoadingStatus.Pending : false}
                  selectionMode={props.selectionMode !== undefined ? props.selectionMode : SelectionMode.multiple}
                  checkboxVisibility={props.showCheckBox ?? CheckboxVisibility.always}
                  onRenderDetailsHeader={onRenderDetailsHeader(theme)}
                  onRenderRow={props.onRenderRow ? props.onRenderRow : onRenderRow(theme)}
                  onRenderItemColumn={onRenderItemColumn}
                  onColumnResize={debounce((column?: IColumn, newWidth?: number) => {
                    const width = dangerZoneWidthCalculationNoTypeSafety(detailsListComponentRef);
                    if (column && newWidth) {
                      setUserConfiguration({
                        columnWidths: {
                          ...userConfiguration.columnWidths,
                          [column.name]: newWidth
                        },
                        width
                      });
                    }
                  }, 300)}
                  getRowAriaLabel={(item) => {
                    return `Entry ${item.docNumber}`;
                  }}
                  constrainMode={ConstrainMode.unconstrained}
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                  ariaLabelForGrid={`AutomatedJe Entries`}
                  ariaLabelForSelectionColumn="Toggle Entries"
                  ariaLabelForSelectAllCheckbox="Toggle all Entries"
                  checkButtonAriaLabel="Select Row"
                  columnReorderOptions={{
                    frozenColumnCountFromStart: 0,
                    frozenColumnCountFromEnd: 0,
                    onColumnDrop: ({ draggedIndex, targetIndex }: IColumnDragDropDetails) => {
                      const columnPositions = columnReorderingHandling({ draggedIndex, targetIndex }, columns);
                      setUserConfiguration({
                        columnPositions
                      });
                    }
                  }}
                />
              </MarqueeSelection>
            </ScrollablePane>
          )}
        </div>
        {props.disablePagination ? null : (
          <DashboardGridPaginate
            defaultPageSize={
              [40, 60, 80, 100].includes(detailsState.defaultPageSize) ? detailsState.defaultPageSize : 999
            }
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            pageCount={pageCount.current}
            selectedPage={selectedPage.current}
            itemCount={itemCount.current}
          />
        )}
      </div>
    </>
  );
};
DashboardGrid = React.memo(DashboardGrid, (prevProps, nextProps) => {
  if (prevProps.loadingStatus !== nextProps.loadingStatus) {
    return false;
  }
  if (prevProps.selectionMode !== nextProps.selectionMode) {
    return false;
  }
  if (prevProps.height !== nextProps.height) {
    return false;
  }
  if (prevProps.disablePagination !== nextProps.disablePagination) {
    return false;
  }
  if (prevProps.isSortedIndex !== nextProps.isSortedIndex) {
    return false;
  }
  if (prevProps.isSortedDescending !== nextProps.isSortedDescending) {
    return false;
  }
  if (prevProps.onRenderRow !== nextProps.onRenderRow) {
    return false;
  }
  if (prevProps.idForLocalStorage !== nextProps.idForLocalStorage) {
    return false;
  }
  if (prevProps.columnGenerator?.toString() !== nextProps.columnGenerator?.toString()) {
    return false;
  }
  if (prevProps.disableSorting !== nextProps.disableSorting) {
    return false;
  }
  if (prevProps.maxHeight !== nextProps.maxHeight) {
    return false;
  }
  if (prevProps.onSelectionChanged?.toString() !== nextProps.onSelectionChanged?.toString()) {
    return false;
  }
  if (prevProps.onExport?.toString() !== nextProps.onExport?.toString()) {
    return false;
  }
  if (prevProps.children !== nextProps.children) {
    return false;
  }
  if (prevProps.customRef !== nextProps.customRef) {
    return false;
  }
  if (!isEqual(prevProps.items, nextProps.items)) {
    return false;
  }

  return true;
});
DashboardGrid.displayName = "DashboardGrid";
export { DashboardGrid };
