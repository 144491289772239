import {
  JemConfiguration,
  getValidUrl,
  getRequest,
  ApiError,
  IUserProviderState,
  ScheduleJEResponseFromAPI,
  TerminateRecurringJEPayload,
  postRequest,
  TerminateRecurringJEResponse,
  putRequest,
  GetRecurringJEResponse,
  TerminateApproveRecurringJEPayload,
  TerminateApproveRecurringJEResponse
} from "@jem/components";

export async function getRecurringSchedules(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<ScheduleJEResponseFromAPI[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getRecurringSchedules}`;

  const endpointUrl = getValidUrl(endpoint);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await getRequest<any>(endpointUrl, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response.map((res: ScheduleJEResponseFromAPI) => {
    const buildResp: ScheduleJEResponseFromAPI = res;
    return buildResp;
  });
}

export async function getRecurringJE(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  recurringJEScheduleId: number
): Promise<GetRecurringJEResponse> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getRecurringJE}`.replace(
    "{recurringJEScheduleId}",
    "" + recurringJEScheduleId
  );

  const endpointUrl = getValidUrl(endpoint);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await putRequest<any>(endpointUrl, undefined, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response;
}

export async function terminateRecurringSchedule(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  data: TerminateRecurringJEPayload
): Promise<TerminateRecurringJEResponse> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.terminateRecurringSchedule}`;

  const endpointUrl = getValidUrl(endpoint);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await postRequest<any>(endpointUrl, data, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response;
}

export async function viewRecurringSchedule(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  templateId: number
): Promise<{ redirectPath: string }> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.viewJE}`.replace(
    "{templateId}",
    "" + templateId
  );

  const endpointUrl = getValidUrl(endpoint);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await getRequest<any>(endpointUrl, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response;
}

export async function terminateApproveRejectRecurringSchedules(
  configuration: JemConfiguration["GeneralLedgerApi"],
  accessToken: IUserProviderState["accessToken"],
  data: TerminateApproveRecurringJEPayload,
  type: string
): Promise<TerminateApproveRecurringJEResponse> {
  const endpoint = `${configuration.baseApiUrl}${
    type === "approve"
      ? configuration.endpoints.terminateApproveRecurringJE
      : configuration.endpoints.terminateRejectRecurringJE
  }`;

  const payload = {
    jeIds: data.recurringJEScheduleIds,
    comments: data.comment
  };

  const endpointUrl = getValidUrl(endpoint);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await postRequest<TerminateApproveRecurringJEResponse>(endpointUrl, payload, accessToken);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response;
}
