import { css } from "@emotion/css";
import {
  CommandBar,
  DefaultButton,
  Dropdown,
  FocusTrapZone,
  ICommandBarItemProps,
  IDropdownOption,
  Layer,
  Overlay,
  Popup,
  PrimaryButton,
  Theme,
  useTheme
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { debounce, IUserProviderState } from "../../../Shared";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { buttonStyles } from "../../../Shared/utilities/CommonStyles";
import { EmptyRefGuid } from "../../utilities/EmptyRefGuid";
import { GLCreateState } from "./GLCreateForm.types";
import { noActivityReasonOptions, popupStyles } from "../../shared/noActivityAction";
import { AdditionalData } from "../../shared";
import { GLCreateActionsEnum, GLCreateIsButtonEnabled } from "./useWeightage";

interface GLCreateActionsProps {
  onAction: (actionName: GLCreateActionsEnum, options?: AdditionalData) => void;
  disabled: boolean;
  buttonsState: GLCreateIsButtonEnabled;
  domainData: Pick<
    DomainDataObjects,
    DomainDataEnum.StatusActions | DomainDataEnum.JeStatus | DomainDataEnum.JeParameters
  >;
  userContext: IUserProviderState;
  createState: GLCreateState;
  getNoActivityReasonOptions?: () => Promise<IDropdownOption[]>;
}

function CommandsForGLCreate(
  onActionCallback: GLCreateActionsProps["onAction"],
  setShowNoActivityModal: any,
  disabled: boolean,
  buttonsState: GLCreateIsButtonEnabled,
  theme: Theme,
  userContext: IUserProviderState,
  createState: GLCreateState
): ICommandBarItemProps[] {
  const noActivityNoChecklistThenEnable =
    createState.jeDetails.checklistRefGuid !== EmptyRefGuid() && createState.jeDetails.checklistRefGuid !== "";
  const noActivityDisabled = !buttonsState.Post || !noActivityNoChecklistThenEnable;

  return [
    {
      key: "save",
      name: "Save",
      iconProps: { iconName: "Save" },
      onClick: debounce(() => onActionCallback(GLCreateActionsEnum.Save), 200) as any,
      disabled: disabled || !buttonsState.Save,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "post",
      name: "Post",
      iconProps: { iconName: "PublishContent" },
      onClick: debounce(() => onActionCallback(GLCreateActionsEnum.Post), 200) as any,
      disabled: disabled || !buttonsState[GLCreateActionsEnum.Post],
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "sendToPoster",
      name: "Send To Poster",
      iconProps: { iconName: "Send" },
      onClick: debounce(() => onActionCallback(GLCreateActionsEnum.SendToPoster), 200) as any,
      disabled: disabled || !buttonsState[GLCreateActionsEnum.SendToPoster],
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "validate",
      name: "Validate",
      iconProps: { iconName: "CheckList" },
      onClick: debounce(() => onActionCallback(GLCreateActionsEnum.Validate), 200) as any,
      disabled: disabled || !buttonsState[GLCreateActionsEnum.Validate],
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "needsClarification",
      name: "Needs Clarification",
      iconProps: { iconName: "StatusCircleQuestionMark" },
      onClick: debounce(() => onActionCallback(GLCreateActionsEnum.NeedsClarification), 200) as any,
      disabled: disabled || !buttonsState[GLCreateActionsEnum.NeedsClarification],
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "attachFile",
      name: "Attach File",
      iconProps: { iconName: "Attach" },
      onClick: () => onActionCallback(GLCreateActionsEnum.AttachFile),
      disabled: disabled,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "noActivity",
      name: "No Activity",
      iconProps: { iconName: "UnsyncOccurence" },
      onClick: debounce(() => setShowNoActivityModal(true), 200) as any,
      disabled: disabled || noActivityDisabled,
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "preReview",
      name: "Pre-Review",
      iconProps: { iconName: "Preview" },
      onClick: debounce(() => onActionCallback(GLCreateActionsEnum.PreReview), 200) as any,
      disabled: disabled || !buttonsState[GLCreateActionsEnum.PreReview],
      buttonStyles: buttonStyles(theme)
    },
    {
      key: "clearForm",
      name: "Clear Form",
      iconProps: { iconName: "ClearFilter" },
      onClick: debounce(() => onActionCallback(GLCreateActionsEnum.Clear), 200) as any,
      disabled: disabled || !buttonsState[GLCreateActionsEnum.Save],
      buttonStyles: buttonStyles(theme)
    }
  ];
}

let GLCreateActions: React.FC<GLCreateActionsProps> = (props) => {
  const theme = useTheme();

  const [showNoActivityModal, setShowNoActivityModal] = useState<boolean>(false);
  const [selectedNoActivityReason, setselectedNoActivityReason] = useState<string>("");
  const [noActivityReasonOptionsFromAPI, setNoActivityReasonOptionsFromAPI] = useState<IDropdownOption[]>([]);
  const hidePopup = () => {
    setShowNoActivityModal(false);
  };

  const IsFCCWIntegrationLive =
    props.domainData?.JeParameters.filter((item) => item.name === "IsFCCWIntegrationLive").map(
      (item) => item.value.toLowerCase() == "true"
    )[0] ?? false;

  useEffect(() => {
    if (IsFCCWIntegrationLive && props.getNoActivityReasonOptions) {
      props.getNoActivityReasonOptions().then((options: any) => {
        setNoActivityReasonOptionsFromAPI(
          options.taskPropertyByName.values
            .filter((option: { isActive: boolean; id: number; name: string }) => option.isActive)
            .map((option: { id: number; name: string }) => {
              return { key: option.id, text: option.name };
            })
        );
      });
    }
  }, []);

  const onAction = (actionName: GLCreateActionsEnum) => {
    if (props.onAction) {
      props.onAction(actionName, undefined);
    }
  };

  const commandBarItems = CommandsForGLCreate(
    onAction,
    setShowNoActivityModal,
    props.disabled,
    props.buttonsState,
    theme,
    props.userContext,
    props.createState
  );
  return (
    <>
      <div
        ref={null}
        className={css`
          width: 100%;
          min-height: 1px;
          box-sizing: border-box;
          overflow: hidden;
        `}
      >
        <CommandBar
          items={commandBarItems}
          styles={{
            root: {
              paddingLeft: 0,
              backgroundColor: theme.palette.neutralLighterAlt
            }
          }}
          ariaLabel={"Actions for Journal Entry"}
          overflowButtonProps={{
            ariaLabel: "More Actions",
            styles: {
              root: {
                backgroundColor: theme.palette.neutralLighterAlt
              }
            }
          }}
        />
      </div>

      {showNoActivityModal && (
        <Layer>
          <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={hidePopup}>
            <Overlay onClick={hidePopup} />
            <FocusTrapZone>
              <div role="document" className={popupStyles.content}>
                <p>Select No Activity reason</p>
                <Dropdown
                  required
                  id="noActivityReason"
                  label="No Activity Reason"
                  placeholder="Select an option"
                  options={IsFCCWIntegrationLive ? noActivityReasonOptionsFromAPI : noActivityReasonOptions}
                  selectedKey={selectedNoActivityReason}
                  errorMessage={""}
                  onChange={(_e: any, newValue: IDropdownOption<any> | undefined): void => {
                    console.log("noActivityReason in glcreation actions" + newValue?.text);
                    setselectedNoActivityReason(newValue?.text || "");
                  }}
                />

                <PrimaryButton
                  onClick={() => {
                    if (props.onAction) {
                      props.onAction(GLCreateActionsEnum.NoActivity, { noActivityReason: selectedNoActivityReason });
                    }
                    hidePopup();
                  }}
                  disabled={!selectedNoActivityReason}
                >
                  Submit
                </PrimaryButton>
                <DefaultButton onClick={hidePopup}>Close</DefaultButton>
              </div>
            </FocusTrapZone>
          </Popup>
        </Layer>
      )}
    </>
  );
};

GLCreateActions.displayName = "GLCreateActions";
GLCreateActions = React.memo(GLCreateActions, (prevPros, nextProps) => {
  if (prevPros.disabled !== nextProps.disabled) return false;
  if (prevPros.buttonsState !== nextProps.buttonsState) return false;
  return true;
});
export { GLCreateActions };
