import { JemConfiguration } from "../../../JemConfiguration";
import {
  DomainDataObjects,
  ApiParsingUtilities,
  SignificanceTypeAndIcon,
  IUserProviderState,
  postRequest,
  ApiError,
  IconMappingForSignificance,
  DomainDataEnum,
  getRequest
} from "../../../Shared";
import { regStoreAttachmentsToAttachmentsRegion, regStoreAttachmentsToGLAttachments } from "../../shared";
import { AliasUtilities } from "../../utilities/AliasUtilities";

import {
  getReviewersFromGLDetailsObjectFromAPI,
  jeStatusToString,
  getClarificationCodes,
  reviewInformationArrayToGLComments,
  GLJeDetailsSettingsFromDocNumber
} from "./GLDetails.jeid.State";
import { GLDetailsObjectFromAPI, GLDetailsSapObjectFromSAPAndAPI, ReviewInformation } from "./GLDetails.types";
import { GLJeDetailsState } from "./GLDetailsForm.types";

interface GLNumberSearchStateForApi {
  SAPModule: string;
  CompanyCode: number;
  JENumber: number;
  FiscalYear: number;
}

export function ApiResponseToJEDetails(
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeClarificationCodes | DomainDataEnum.JeStatus>,
  responseObject: GLDetailsObjectFromAPI,
  significanceIcons: IconMappingForSignificance,
  sapModule: string
): GLJeDetailsState {
  const { reviewer, additionalReviewers } = getReviewersFromGLDetailsObjectFromAPI(responseObject);

  const additionalReviewersProcessed = AliasUtilities.getAdditionalReviewers(additionalReviewers, null);
  const backupPosters = AliasUtilities.getBackupPosters(responseObject.additionalPosters, []);

  const reviewInfoArray: ReviewInformation[] = [];
  const emptyReviewInformation: ReviewInformation = {
    posterComments: "",
    reviewerComments: "",
    adminComments: ""
  };

  reviewInfoArray.push(emptyReviewInformation);
  const reviewInformation = responseObject.reviewInformation ? responseObject.reviewInformation : reviewInfoArray;
  const attachments = regStoreAttachmentsToGLAttachments(responseObject.attachments);
  const parsed: GLJeDetailsState = {
    refGuid: responseObject.refGuid || "",
    jeId: responseObject.jeId || 0,
    rowWeightage: responseObject.rowWeightage,
    postingType: responseObject.postingType,
    templateId: ApiParsingUtilities.parseNumberToNumber(responseObject.templateId),
    sapModule: sapModule,
    header: {
      jeNumber: ApiParsingUtilities.addDefault(responseObject.docNumber),
      author: ApiParsingUtilities.addDefault(responseObject.author),
      poster: ApiParsingUtilities.addDefault(responseObject.poster),
      additionalPosters: backupPosters,
      reviewer: ApiParsingUtilities.addDefault(responseObject.reviewer),
      reviewerAction: ApiParsingUtilities.addDefault(responseObject.reviewerAction),
      dateModified: ApiParsingUtilities.parseDate(responseObject.dateModified),
      status: jeStatusToString(domainData, responseObject.jeStatus),
      isTenantOverridePosting: !!responseObject.isTenantOverridePosting,
      isAutoJe: !!responseObject.isAutoJe
    },
    detailsTab: {
      documentNumber: ApiParsingUtilities.addDefault(responseObject.docNumber),
      significance: !responseObject.siginificance
        ? ""
        : SignificanceTypeAndIcon(responseObject.siginificance, significanceIcons).icon,
      fiscalYear: ApiParsingUtilities.addDefault(responseObject.fiscalYear),
      fiscalPeriod: ApiParsingUtilities.addDefault(responseObject.fiscalPeriod),
      description: ApiParsingUtilities.addDefault(responseObject.description),
      refNumber: ApiParsingUtilities.addDefault(responseObject.refNumber),
      reasonCode: ApiParsingUtilities.addDefault(responseObject.reasonDescription),
      reversalJe: ApiParsingUtilities.addDefault(responseObject.reversalJE),
      jePurpose: ApiParsingUtilities.addDefault(responseObject.purpose, ""),
      companyCode: ApiParsingUtilities.addDefault(responseObject.companyCode),
      isJem: !!responseObject.isJEM,
      isBpo: !!responseObject.isBPO,
      planReversalDate: ApiParsingUtilities.parseDate(responseObject.reversalDate),
      recurringJe: ApiParsingUtilities.addDefault(responseObject.recurringJe),
      recurringJeFiscalYear: ApiParsingUtilities.addDefault(responseObject.recurringJeFiscalYear),
      dueDate: ApiParsingUtilities.parseDate(responseObject.dueDate),
      translationDate: ApiParsingUtilities.parseDate(responseObject.translationDate),
      postersOrg: ApiParsingUtilities.addDefault(responseObject.postersOrg),
      reviewersOrg: ApiParsingUtilities.addDefault(responseObject.reviewersOrg),
      jeName: ApiParsingUtilities.addDefault(responseObject.jeName),
      opsDetailName: ApiParsingUtilities.addDefault(responseObject.opsDetailName),
      fcwChecklistName: ApiParsingUtilities.addDefault(responseObject.fcwChecklistName),
      isAdhocOrStd: ApiParsingUtilities.addDefault(responseObject.isAdhocOrStd),
      jeType: ApiParsingUtilities.addDefault(responseObject.jeType),
      recurringJeScheduleName: ApiParsingUtilities.addDefault(responseObject.recurringJeScheduleName, "")
    },
    attachments: attachments,
    // THIS REGION IS FOR UPLOADING, NOT DOWNLOADING
    attachments_region: regStoreAttachmentsToAttachmentsRegion(responseObject.attachments),
    lock_region: attachments.length > 0,
    reviewersTab: {
      reviewer,
      reviewerAction: ApiParsingUtilities.addDefault(responseObject.reviewerAction),
      additionalReviewers: additionalReviewersProcessed,
      comments: ApiParsingUtilities.addDefault(responseObject.comments, ""),
      clarificationCodes: getClarificationCodes(domainData),
      commentItems: reviewInformationArrayToGLComments(reviewInformation),
      preReviewers: []
    },
    status: responseObject.jeStatus
  };
  return parsed;
}

export async function getJEDetailsFromSearch(
  config: JemConfiguration["GeneralLedgerApi"],
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeClarificationCodes | DomainDataEnum.JeStatus>,
  significanceIcons: IconMappingForSignificance,
  state: GLJeDetailsSettingsFromDocNumber,
  getTokenFn: IUserProviderState["accessToken"]
): Promise<GLJeDetailsState | null> {
  const data: GLNumberSearchStateForApi = {
    SAPModule: state.SAPModule,
    CompanyCode: Number(state.CompanyCode),
    JENumber: Number(state.DocNumber),
    FiscalYear: Number(state.FiscalYear)
  };

  try {
    const url = `${config.baseApiUrl}${config.endpoints.getJENumberSearchResults}`.replace("{refguid}", state.RefGuid);
    const searchReponse = await postRequest<GLDetailsObjectFromAPI[]>(url, data, getTokenFn);
    if (!searchReponse) {
      throw new ApiError("Details not found.", {
        DocNumber: state.DocNumber
      });
    }
    const theResponse = !Array.isArray(searchReponse) ? searchReponse : searchReponse[0];

    if (theResponse.jeId === 0) {
      const url = `${config.baseApiUrl}${config.endpoints.getJENumberSearchSAPResults}`
        .replace("{Fyear}", state.FiscalYear)
        .replace("{DocNum}", state.DocNumber)
        .replace("{Compcode}", state.CompanyCode)
        .replace("{Module}", state.SAPModule);
      const searchFromSAP = await getRequest<GLDetailsSapObjectFromSAPAndAPI>(url, getTokenFn);
      if (searchFromSAP) {
        const jeDetails = SapApiResponseToJEDetails(
          domainData,
          searchFromSAP,
          state.SAPModule,
          theResponse.rowWeightage
        );
        return jeDetails;
      }

      const jeDetails = ApiResponseToJEDetails(domainData, theResponse, significanceIcons, state.SAPModule);
      return jeDetails;
    }

    const jeDetails = ApiResponseToJEDetails(domainData, searchReponse[0], significanceIcons, state.SAPModule);
    return jeDetails;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function SapApiResponseToJEDetails(
  domainData: Pick<DomainDataObjects, DomainDataEnum.JeClarificationCodes | DomainDataEnum.JeStatus>,
  responseObject: GLDetailsSapObjectFromSAPAndAPI,
  sapModule: string,
  rowWeightage = 0
): GLJeDetailsState {
  const reviewer = "";
  const additionalReviewersProcessed = [];
  const backupPosters = AliasUtilities.getBackupPosters(null, []);

  const reviewInfoArray: ReviewInformation[] = [];
  const emptyReviewInformation: ReviewInformation = {
    posterComments: "",
    reviewerComments: "",
    adminComments: ""
  };

  reviewInfoArray.push(emptyReviewInformation);

  const attachments = [];
  const parsed: GLJeDetailsState = {
    refGuid: "",
    jeId: 0,
    rowWeightage: rowWeightage,
    postingType: 0,
    templateId: 0,
    sapModule: sapModule,
    header: {
      jeNumber: ApiParsingUtilities.addDefault(responseObject.documentNumber),
      author: ApiParsingUtilities.addDefault(responseObject.userid),
      poster: ApiParsingUtilities.addDefault(responseObject.userid),
      additionalPosters: backupPosters,
      reviewer: ApiParsingUtilities.addDefault(""),
      reviewerAction: ApiParsingUtilities.addDefault(""),
      dateModified: ApiParsingUtilities.parseDate(responseObject.lastUpdate),
      status: "",
      isTenantOverridePosting: false,
      isAutoJe: false
    },
    detailsTab: {
      documentNumber: ApiParsingUtilities.addDefault(responseObject.documentNumber),
      significance: "",
      fiscalYear: ApiParsingUtilities.addDefault(responseObject.fiscalYear),
      fiscalPeriod: ApiParsingUtilities.addDefault(responseObject.docPeriod),
      description: ApiParsingUtilities.addDefault(responseObject.docHeaderTxt),
      refNumber: ApiParsingUtilities.addDefault(responseObject.referenceDoc),
      reasonCode: "",
      reversalJe: ApiParsingUtilities.addDefault(responseObject.reversalJENumberStr),
      jePurpose: "",
      companyCode: ApiParsingUtilities.addDefault(responseObject.companyCode),
      isJem: false,
      isBpo: false,
      planReversalDate: "",
      recurringJe: ApiParsingUtilities.addDefault(responseObject.recurringJENumber),
      recurringJeFiscalYear: ApiParsingUtilities.addDefault(responseObject.recurringYear),
      dueDate: "",
      translationDate: ApiParsingUtilities.parseDate(responseObject.translationDate),
      postersOrg: "",
      reviewersOrg: "",
      jeName: "",
      opsDetailName: "",
      fcwChecklistName: "",
      isAdhocOrStd: "",
      jeType: "",
      recurringJeScheduleName: ApiParsingUtilities.addDefault(responseObject.recurringJeScheduleName)
    },
    attachments: attachments,
    // THIS REGION IS FOR UPLOADING, NOT DOWNLOADING
    attachments_region: 2,
    lock_region: true,
    reviewersTab: {
      reviewer,
      reviewerAction: "",
      additionalReviewers: additionalReviewersProcessed,
      comments: "",
      clarificationCodes: getClarificationCodes(domainData),
      commentItems: [],
      preReviewers: []
    },
    status: 0
  };
  return parsed;
}
