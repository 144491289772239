import {
  Checkbox,
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  useTheme
} from "@fluentui/react";
import {
  AutomatedJeDashboard,
  DomainDataEnum,
  DomainDataObjects,
  EUARoles,
  FatalError,
  ForceMaxWidthAndHeightOnDropdownStyle,
  GeneralLedgerAction,
  IIndexedTile,
  ITenantProp,
  JemConfiguration,
  JEMContext,
  LoadingSpinner,
  LoadingStatus,
  LoggingContext,
  MockDataFn,
  pageHeaderStyles,
  PageStyles,
  PivotOption,
  TenantRowDetails,
  useQuery,
  UserContext
} from "@jem/components";

import { isValid } from "date-fns";
import { OrderedMap } from "immutable";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { augmentWithProcessingStatus } from "../../../Shared/utilities/augmentWithProcessingStatus";
import GLDashboard from "../../components/GLDashboard/GLDashboard";
import { dashboardButtonStates } from "../../components/GLDashboard/GLDashboard.Actions";
import {
  getDraftColumns,
  getGLColumns,
  getPreReviewColumns,
  notPostedPostingRequestedColumns
} from "../../components/GLDashboard/GLDashboard.Columns";
import GLDashboardHeader from "../../components/GLDashboard/GLDashboard.Header";
import {
  fetchDashboardCounts,
  getGlDashboardData,
  glStubToTileName,
  GLTileNames,
  GLTileNamesStub,
  glTileNameToStub,
  fetchApprovedPreReviewDrafts,
  postPreReview
} from "../../components/GLDashboard/GLDashboard.Requests";
import TenantDetailsPanel from "../../../Operations/components/TenantDashboard/TenantDetailsPanel";
import {
  getTenantDetails,
  updateTenantDetails
} from "../../../Operations/components/TenantDashboard/TenantDetails.Requests";
import { defaultTenantDetails } from "../../../Operations/pages/TenantDashboard/TenantDashboardPage";

export interface GLDashboardProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  mockDashboardDataFn?: MockDataFn<any>;
}

const periodOptions: IDropdownOption[] = [
  { key: "60", text: "60 Days" },
  { key: "120", text: "120 Days" },
  { key: "180", text: "180 Days" },
  { key: "365", text: "1 Year" }
];

function getUserTenants(
  userAlias: string,
  isAdmin: boolean,
  tenantConfigs: DomainDataObjects[DomainDataEnum.JemTenantConfigs],
  userroleTenants: { tenant: string; appId: string }[]
) {
  const userTenants: ITenantProp[] = [];
  if (tenantConfigs) {
    tenantConfigs.forEach((tenant) => {
      const tenantOwners = tenant.tenantOwner ? tenant.tenantOwner.split(";") : [];
      const tenantAdditionalPosters = tenant.additionalPosters ? tenant.additionalPosters.split(";") : [];
      if (
        tenantOwners.map((owner) => owner.toLowerCase()).includes(userAlias.toLowerCase()) ||
        isAdmin ||
        tenantAdditionalPosters.map((owner) => owner.toLowerCase()).includes(userAlias.toLowerCase()) ||
        tenant.primaryPoster === userAlias
      ) {
        userTenants.push({ tenant: tenant.name, appId: tenant.appId });
      }
    });
  }
  userroleTenants.forEach((tenant) => {
    if (!userTenants.map((t) => t.tenant).includes(tenant.tenant)) {
      userTenants.push({ tenant: tenant.tenant, appId: tenant.appId });
    }
  });

  return userTenants;
}

const GLDashboardPage: React.FC<GLDashboardProps> = (props) => {
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  const jemContext = useContext(JEMContext);
  const logger = useContext(LoggingContext);

  const [searchParams] = useSearchParams();
  const [processingStatus, setProcessingStatus] = useState<Partial<{
    [key in GLTileNamesStub]: GeneralLedgerAction;
  }> | null>(null);

  const tileName = Object.values(GLTileNamesStub).includes(searchParams.get("tilename") as GLTileNamesStub)
    ? (searchParams.get("tilename") as GLTileNamesStub)
    : GLTileNamesStub.MyDraftJEs;

  const [currentDashboardType, setCurrentDashboardType] = useState<PivotOption>("manualJe");
  const [updatedDate, setUpdatedDate] = useState<string>(new Date().toLocaleString("en-US"));
  const [approvedPreReviewDrafts, setApprovedPreReviewDrafts] = useState<boolean>(false);
  const [postPreReviewDrafts, setPostPreReviewDrafts] = useState<boolean>(false);
  const [openTenantPanel, setOpenTenantPanel] = useState<boolean>(false);
  const [tenantApiErrors, setTenantApiErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tenantDetails, setTenantDetails] = useState<TenantRowDetails>(defaultTenantDetails);
  const [hasTenantDetailsEdit, setHasTenantDetailsEdit] = useState<boolean>(false);
  const [tenants, setTenants] = useState<ITenantProp[]>([]);
  const [period, setPeriod] = useState<string>("");
  const [makeDefaultPeriod, setMakeDefaultPeriod] = useState<boolean>(false);

  useEffect(() => {
    localStorage.removeItem("glDashboardTimeframe");
    const defaultPeriod = localStorage.getItem("glDashboardDefaultTimeframe");
    if (defaultPeriod?.length) {
      setPeriod(defaultPeriod);
      setMakeDefaultPeriod(true);
      localStorage.setItem("glDashboardTimeframe", defaultPeriod);
    } else {
      setPeriod("180");
      localStorage.setItem("glDashboardTimeframe", "180");
    }
  }, []);

  useEffect(() => {
    const tileNameFromParams = searchParams.get("tilename") as GLTileNamesStub;
    const tilesAreDifferent = tileNameFromParams !== tileName;
    if (tilesAreDifferent && !Object.values(GLTileNamesStub).includes(tileNameFromParams)) {
      navigate(
        {
          search: `?tilename=${GLTileNamesStub.MyDraftJEs}`
        },
        { replace: true }
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentDashboardType == "automatedJe" && jemContext.initInfo.values) {
      setTenants(
        getUserTenants(
          userContext.user.alias,
          userContext.jemUser.roles.includes(EUARoles.ToolAdministrator),
          jemContext.initInfo.values.JemTenantConfigs as DomainDataObjects[DomainDataEnum.JemTenantConfigs],
          userContext.jemUser.tenants
        )
      );
    }
  }, [currentDashboardType]);

  const {
    data: dashboardPayload,
    refetch: refetchTileData,
    dataUpdatedAt,
    error,
    isFetching,
    isLoading
  } = useQuery({
    queryKey: ["tiles", tileName],
    queryFn: (opts) => {
      const theTileName = (opts.queryKey as [string, string])[1] as GLTileNamesStub;
      const fetchTileName = glStubToTileName(theTileName);
      return getGlDashboardData(props.configuration, userContext.accessToken, fetchTileName);
    },
    select: (data) => {
      if (!processingStatus) return data;
      if (!(tileName in processingStatus)) return data;
      if (processingStatus[tileName] === undefined) return data;

      const processingStatusOfTile = processingStatus[tileName] as GeneralLedgerAction;
      const gridDataCopy = augmentWithProcessingStatus(data.items, processingStatusOfTile);

      data.items = gridDataCopy;
      return data;
    },
    staleTime: 1000 * 60 * 60
  });

  const dashboardStatus =
    isLoading || isFetching
      ? LoadingStatus.Pending
      : error !== undefined && error !== null
      ? LoadingStatus.Rejected
      : LoadingStatus.Resolved;
  useEffect(() => {
    const updatedAt = isValid(dataUpdatedAt) ? new Date(dataUpdatedAt).toLocaleString("en-US") : "";
    setUpdatedDate(updatedAt);
  }, [dataUpdatedAt]);

  const {
    data: tiles,
    refetch: refetchTileCounts,
    isFetching: areTilesFetching,
    isLoading: areTilesLoading,
    error: tilesError
  } = useQuery({
    queryKey: ["tiles", "counts"],
    queryFn: async () => {
      return fetchDashboardCounts(props.configuration, userContext.accessToken);
    },
    staleTime: 0
  });

  useEffect(() => {
    const fetchData = async () => {
      const prereviewRequestCount = sessionStorage.getItem("prereviewcounter");
      if (prereviewRequestCount && Number(prereviewRequestCount) > 0) {
        const resultDrafts = await fetchApprovedPreReviewDrafts(props.configuration, userContext.accessToken);
        setApprovedPreReviewDrafts(resultDrafts.approvedDraftCount > 0);
        sessionStorage.setItem("prereviewcounter", "0");
      }
      if (dataUpdatedAt) {
        const resultDrafts = await fetchApprovedPreReviewDrafts(props.configuration, userContext.accessToken);
        setApprovedPreReviewDrafts(resultDrafts.approvedDraftCount > 0);
      }
    };
    fetchData();
  }, [dataUpdatedAt]);

  const onPostPreReview = async () => {
    const result = await postPreReview(props.configuration, userContext.accessToken);
    if (result && result.every((item) => item.postingStatus === true)) {
      setApprovedPreReviewDrafts(false);
      setPostPreReviewDrafts(false);
    } else {
      setPostPreReviewDrafts(true);
    }
  };

  const tilesStatus =
    areTilesLoading || areTilesFetching
      ? LoadingStatus.Pending
      : tilesError
      ? LoadingStatus.Rejected
      : LoadingStatus.Resolved;

  const currentTile = glStubToTileName(tileName);
  const refreshDate = (newUpdatedDate: string) => {
    setUpdatedDate(newUpdatedDate);
  };

  const editTenantCallback = async (name: string) => {
    setLoading(true);
    const editItem = (await getTenantDetails(props.configuration, userContext.accessToken)).find(
      (tenant) => tenant.name === name
    );

    if (editItem !== undefined) {
      if (editItem.miscConfig.jemServiceBusTopicName) {
        editItem.miscConfig.allowTopicName = true;
      }
      setTenantDetails({
        name: editItem.name,
        appId: editItem.appId,
        primaryPoster: editItem.primaryPoster,
        additionalPosters: editItem.additionalPosters,
        defaultReviewers: editItem.defaultReviewers,
        emailContacts: editItem.emailContacts,
        tenantOwner: editItem.tenantOwner,
        allowAutoReview: editItem.allowAutoReview,
        overrideTenantSetting: editItem.overrideTenantSetting,
        allowedPostingInJEM: editItem.allowedPostingInJEM,
        description: editItem.description ? editItem.description : "",
        batchNamePrefix: editItem.miscConfig.batchNamePrefix,
        batchDescription: editItem.miscConfig.batchDescription,
        batchPurposeSuffix: editItem.miscConfig.batchPurposeSuffix,
        reasonCode: editItem.miscConfig.reasonCode,
        jeReferenceNo: editItem.miscConfig.jeReferenceNo,
        docType: editItem.miscConfig.docType,
        serviceBus: editItem.miscConfig.serviceBus,
        sbRetryQueueName: editItem.miscConfig.sbRetryQueueName,
        allowedMaxValidationsErrorCount: editItem.miscConfig.allowedMaxValidationsErrorCount,
        sapUserId: editItem.miscConfig.sapUserId,
        emailNeedsToBeBlocked: editItem.miscConfig.emailNeedsToBeBlocked,
        allowTopicName: editItem.miscConfig.allowTopicName,
        topicName: editItem.miscConfig.jemServiceBusTopicName
      });

      setHasTenantDetailsEdit(editItem.tenantOwner.toLowerCase().includes(userContext.user.alias));
    }
    setOpenTenantPanel(true);
    setLoading(false);
  };

  const saveOrUpdateTenantDetails = (details: TenantRowDetails) => {
    setLoading(true);

    updateTenantDetails(props.configuration, userContext.accessToken, details).then((res) => {
      if (!res.success) setTenantApiErrors(res.errorMessage);
      setLoading(false);
    });
  };

  const onChangePeriod = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    setMakeDefaultPeriod(false);
    if (option) {
      setPeriod(option.key);
      localStorage.setItem("glDashboardTimeframe", option.key);
      refetchTileCounts();
      refetchTileData();
    }
  };

  return (
    <>
      <div className={PageStyles.rootDiv}>
        <div className={pageHeaderStyles(theme)}>
          <GLDashboardHeader
            currentDashboardType={setCurrentDashboardType}
            lastRefreshed={updatedDate}
            onRefresh={() => {
              if (processingStatus && tileName in processingStatus) {
                delete processingStatus[tileName];
                setProcessingStatus({ ...processingStatus });
              }
              refetchTileCounts();
              refetchTileData();
            }}
            disabled={dashboardStatus === LoadingStatus.Pending}
            hidden={dashboardPayload === undefined}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {dashboardStatus === LoadingStatus.Resolved && approvedPreReviewDrafts ? (
              <div className="centered-message-bar">
                <MessageBar
                  actions={
                    <div>
                      <MessageBarButton onClick={onPostPreReview}>Yes</MessageBarButton>
                    </div>
                  }
                  messageBarType={MessageBarType.success}
                  isMultiline={false}
                  styles={{ root: { width: "fit-content" } }}
                >
                  Approved pre-reviewed JE's, Click 'Yes' to POST to SAP.
                </MessageBar>
              </div>
            ) : postPreReviewDrafts ? (
              <div className="centered-message-bar">
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                  styles={{ root: { width: "fit-content" } }}
                >
                  Pre-review JE's are not posted to SAP, Try Again
                </MessageBar>
              </div>
            ) : null}
          </div>
          {currentDashboardType !== "automatedJe" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                gap: "8px",
                fontSize: "12px",
                alignItems: "baseline"
              }}
            >
              <Checkbox
                label="Make it default choice"
                checked={makeDefaultPeriod}
                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                  setMakeDefaultPeriod(!!checked);
                  if (checked) localStorage.setItem("glDashboardDefaultTimeframe", period);
                  else localStorage.removeItem("glDashboardDefaultTimeframe");
                }}
                styles={{
                  text: {
                    fontSize: "12px"
                  }
                }}
              ></Checkbox>
              <Dropdown
                label=""
                selectedKey={period}
                onChange={onChangePeriod}
                options={periodOptions}
                styles={
                  (ForceMaxWidthAndHeightOnDropdownStyle({
                    width: "80px",
                    minWidth: "100px",
                    height: "100%",
                    maxWidth: "100px"
                  }),
                  {
                    dropdown: {
                      fontSize: "12px"
                    },
                    dropdownItem: {
                      fontSize: "12px"
                    }
                  })
                }
              />
              <p style={{ fontSize: "12px", fontWeight: "600", margin: "3px" }}>My View</p>
            </div>
          ) : (
            <></>
          )}
        </div>
        {jemContext.initInfo.values === null ? (
          <LoadingSpinner label="Loading JEM Domain Data" />
        ) : currentDashboardType === "automatedJe" ? (
          <>
            <AutomatedJeDashboard
              tenants={tenants}
              configuration={props.configuration}
              getTokenFn={userContext.accessToken}
              updatedDate={refreshDate}
              editTenant={editTenantCallback}
            ></AutomatedJeDashboard>
          </>
        ) : (
          <>
            <GLDashboard
              configuration={props.configuration}
              attachmentsConfiguration={props.attachmentsConfiguration}
              domainData={jemContext.initInfo.values}
              items={dashboardPayload !== undefined ? dashboardPayload.items : []}
              tilesAreLoading={tilesStatus}
              dataIsLoading={dashboardStatus}
              {...(tileName === GLTileNamesStub.MyDraftJEs
                ? {
                    uniqueIdForDashboardsColumnGenerator: "glDraftsDashboardConfig",
                    columnGenerator: getDraftColumns(location, theme),
                    theIndexFromTheColumnsThatIsInitallySortedWith: 11
                  }
                : tileName === GLTileNamesStub.NotPostedPostingRequested
                ? {
                    uniqueIdForDashboardsColumnGenerator: "glNotPostedPostingReqDashboardConfig",
                    columnGenerator: notPostedPostingRequestedColumns(location),
                    theIndexFromTheColumnsThatIsInitallySortedWith: 10
                  }
                : tileName === GLTileNamesStub.PosterPreReviewRequested ||
                  tileName === GLTileNamesStub.PreReviewerActionRequired
                ? {
                    uniqueIdForDashboardsColumnGenerator: "glPreReviewDashboardConfig",
                    columnGenerator: getPreReviewColumns(location, theme),
                    theIndexFromTheColumnsThatIsInitallySortedWith: 12
                  }
                : {
                    uniqueIdForDashboardsColumnGenerator: "glNonDraftsDashboardConfig",
                    columnGenerator: getGLColumns(location, theme),
                    theIndexFromTheColumnsThatIsInitallySortedWith: 14
                  })}
              tiles={tiles || OrderedMap<string, IIndexedTile>({})}
              currentTile={currentTile}
              onTileChange={(tileName: GLTileNames) => {
                navigate({
                  search: `?tilename=${glTileNameToStub(tileName)}`
                });
              }}
              buttonCalculator={dashboardButtonStates(currentTile)}
              onAction={async (actionResult, tileName) => {
                logger.addNotification(actionResult.notification);
                setProcessingStatus((pProcessingStatus) => {
                  if (tileName) {
                    const newStatus = { ...pProcessingStatus };
                    const stub = glTileNameToStub(tileName as GLTileNames);
                    if (actionResult.response) {
                      newStatus[stub] = actionResult.response;
                      return newStatus;
                    } else {
                      delete newStatus[stub];
                      return newStatus;
                    }
                  }
                  return pProcessingStatus;
                });
                refetchTileCounts();
                refetchTileData();
              }}
            />
          </>
        )}
        {openTenantPanel && (
          <TenantDetailsPanel
            configuration={props.configuration}
            loading={loading}
            error={tenantApiErrors}
            tenantDetails={tenantDetails}
            onClosePanel={() => {
              setTenantDetails(JSON.parse(JSON.stringify(defaultTenantDetails)));
              setOpenTenantPanel(false);
              setTenantApiErrors([]);
            }}
            onSaveItem={(details) => {
              setTenantDetails(details);
              saveOrUpdateTenantDetails(details);
              setTenantApiErrors([]);
            }}
            isReadOnly={!hasTenantDetailsEdit}
            isAutomatedJEDashboard={true}
          />
        )}
      </div>
    </>
  );
};

GLDashboardPage.displayName = "GLDashboardPage";

export default GLDashboardPage;
