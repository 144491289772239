import { Dropdown, IDropdownOption } from "@fluentui/react";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MaxWidthHeightOptions } from "../../utilities/FluentUIHacks";

export interface IFrequencyRef {
  getFrequency: () => IDropdownOption<string> | null;
  setInitialFrequency: (initialFrequency: string) => void;
  reset: () => void;
}

export interface IFrequencyProps {
  loading: boolean;
  onChange?: (Frequency: IDropdownOption<string>) => void;
  styles?: MaxWidthHeightOptions;
  frequency: string;
  hideLabel?: boolean;
  disabled?: boolean;
}

const FrequencyDropdownOptions = [
  { key: 0, text: "Half-Yearly" },
  { key: 1, text: "Quarterly" },
  { key: 2, text: "Monthly" },
  { key: 3, text: "Yearly" }
];

const FrequencyDropdown = forwardRef((props: IFrequencyProps, ref) => {
  const [frequency, setFrequency] = useState<IDropdownOption<string>>();

  useEffect(() => {
    const ifreq = FrequencyDropdownOptions.find((freq) => freq.text === props.frequency);
    if (!ifreq) return;

    setFrequency(ifreq);
  }, [props.frequency]);

  const onChangeFrequency = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    if (!option) {
      return;
    }

    setFrequency(option);
    if (props.onChange) {
      props.onChange(option);
    }
  };

  useImperativeHandle(
    ref,
    (): IFrequencyRef => ({
      getFrequency() {
        if (!frequency) {
          return null;
        }
        return frequency;
      },
      setInitialFrequency(initialFrequency: string) {
        // get from options values
        const iFreq = FrequencyDropdownOptions.find((freq) => freq.text === initialFrequency);
        if (!iFreq) return;

        setFrequency(iFreq);
      },
      reset() {
        setFrequency(FrequencyDropdownOptions[0]);
      }
    })
  );

  return (
    <React.Fragment>
      <Dropdown
        label={props.hideLabel ? "" : "Frequency code:"}
        selectedKey={frequency?.key}
        onChange={onChangeFrequency}
        options={FrequencyDropdownOptions}
        disabled={props.loading || props.disabled}
        styles={{
          root: {
            width: "100%",
            marginTop: "4px"
          }
        }}
        required={!props.disabled}
        errorMessage={frequency?.key === undefined && !props.disabled ? "Frequency is required" : undefined}
      />
    </React.Fragment>
  );
});

FrequencyDropdown.displayName = "FrequencyDropdown";

export { FrequencyDropdown, FrequencyDropdownOptions };
