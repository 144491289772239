/* eslint-disable @typescript-eslint/no-explicit-any */
import { CoherenceModal } from "@coherence-design-system/controls";
import { Stack, Label, ActionButton, PrimaryButton, DefaultButton, MessageBarType, MessageBar } from "@fluentui/react";
import React, { useState } from "react";
import { DeepPartial, FieldValues, UnpackNestedValue, useForm } from "react-hook-form";
import BulkTransferRow, { IFormTransferInputRow } from "./BulkTransferRow";
import { ITaskBulkTransferInputChoice } from "./TaskBulkTransfer.config";
import { TaskBulkTransferInputDto, TaskBulkTransferInputData } from "./TaskBulkTransferInput";
import {
  ApiError,
  BulkTransferResponse,
  getValidUrl,
  IUserProviderState,
  JemConfiguration,
  postRequest
} from "@jem/components";

interface IBulkTransferProps<T> {
  selectedItems?: string;
  selectedItemsCount: number;
  hideBulkEditModal: () => void;
  loading?: boolean;
  getFormInputs: (
    setValue: (name: string, value: UnpackNestedValue<DeepPartial<T>>) => void
  ) => ITaskBulkTransferInputChoice[];
  onSaveItem: (bulkEditInput: TaskBulkTransferInputDto) => void;
  showMessage?: boolean;
  message?: string;
}

const BulkTransfer = <T extends FieldValues>(props: IBulkTransferProps<T>): JSX.Element => {
  const { selectedItems, hideBulkEditModal, selectedItemsCount, loading, getFormInputs, onSaveItem } = props;

  const form = useForm<T>();
  const { control, handleSubmit, setValue, getValues, errors } = form;
  const formInputs = getFormInputs(setValue);
  const [bulkEditState, setBulkEditState] = useState<IFormTransferInputRow[]>([]);
  const [showMessage, setShowMessage] = useState(false);
  const resetComponent = () => {
    setBulkEditState([]);
    hideBulkEditModal();
  };

  const renderFormFields = () => {
    const activeFormInputs = bulkEditState.map((item) => {
      return item.selectedInputKey;
    });
    const unusedFormInputs = formInputs.filter((input) => {
      const isUnused = !activeFormInputs.includes(input.key.toString());
      return isUnused;
    });
    const messageType = selectedItemsCount == 0 ? MessageBarType.error : MessageBarType.warning;
    return (
      <Stack styles={{ root: { padding: "0 20px 20px 20px ", minHeight: 150 } }}>
        {props.showMessage ? (
          <MessageBar
            delayedRender={false}
            messageBarType={messageType}
            dismissButtonAriaLabel="Close"
            onDismiss={() => {
              setShowMessage(false);
            }}
          >
            {props.message}
          </MessageBar>
        ) : null}
        <Stack horizontal>
          <Label styles={{ root: { flex: "0 0 50%" } }}>Field</Label>
          <Label styles={{ root: { flex: "0 0 50%" } }}>Value</Label>
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
          {bulkEditState?.map((bulkEditRow) => {
            return (
              <BulkTransferRow
                inputRowProps={bulkEditRow}
                formInputs={formInputs}
                activeFormInputs={activeFormInputs}
                control={control}
                bulkEditState={bulkEditState}
                setBulkEditState={setBulkEditState}
                getValues={getValues}
                errors={errors}
              />
            );
          })}
        </Stack>
        {unusedFormInputs.length > 0 && (
          <Stack.Item>
            <ActionButton
              text="Add more"
              iconProps={{ iconName: "Add" }}
              onClick={() => {
                const currentState = bulkEditState;
                currentState.push({
                  selectedInputKey: unusedFormInputs[0]?.key.toString()
                });
                setBulkEditState([...currentState]);
              }}
            />
          </Stack.Item>
        )}
      </Stack>
    );
  };

  const handleOnSaveClick = (formData: UnpackNestedValue<T> | any): void => {
    const bulkTransferInput: TaskBulkTransferInputDto = {
      SelectedItems: selectedItems || "",
      backupPosters: formData.backupPosters?.join(";") || "",
      newPoster: formData.newPoster || "",
      additionalReviewer: formData.additionalReviewer || ""
    };
    console.log(bulkTransferInput);
    onSaveItem(bulkTransferInput);
  };

  return (
    <CoherenceModal
      isOpen={true}
      modalWidth="medium"
      height="responsive"
      title={`Bulk Transfer`}
      onDismiss={() => {
        setBulkEditState([]);
        hideBulkEditModal();
      }}
    >
      <Stack verticalFill>
        <Stack grow>{renderFormFields()}</Stack>
        <Stack horizontal styles={{ root: { padding: "20px 0" } }} tokens={{ childrenGap: 30 }}>
          <PrimaryButton
            text="Save"
            onClick={handleSubmit(handleOnSaveClick)}
            ariaLabel="Save"
            disabled={selectedItemsCount == 0}
          />
          <DefaultButton
            disabled={false}
            text="Cancel"
            onClick={() => {
              resetComponent();
            }}
            ariaLabel="Cancel"
          />
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};

export async function postBulkTransfer(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  bulkTransferInput: TaskBulkTransferInputDto
): Promise<BulkTransferResponse> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.recurringScheduleBulkTransfer}`;
  const endpointUrl = getValidUrl(endpoint);
  let bulkTransferInputData: TaskBulkTransferInputData = {
    jeIds: bulkTransferInput.SelectedItems.split(","),
    recurringJeScheduleIds: bulkTransferInput.SelectedItems.split(","),
    poster: bulkTransferInput.newPoster,
    backupPoster: bulkTransferInput.backupPosters,
    additionalReviewer: bulkTransferInput.additionalReviewer
  };
  const payload = bulkTransferInputData;
  const response = await postRequest<any>(endpointUrl, payload, getTokenFn);

  if (!response) {
    throw new ApiError("No response from server");
  }

  return response;
}
export default BulkTransfer;
