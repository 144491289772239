import { OrderedMap } from "immutable";
import {
  ApiError,
  ApiParsingUtilities,
  GetClassification,
  GetDraftStatusString,
  getRequest,
  putRequest,
  getValidUrl,
  GLDashboardRow,
  IIndexedTile,
  IUserProviderState,
  JemConfiguration,
  sanitizeDashboardRow,
  SanitizedDashboardRow,
  SanitizedDraftDashboardRow,
  SanitizedPreReviewDashboardRow
} from "@jem/components";
import { convertToTilesInSpecificOrder, initialTiles } from "./GLDashboard.Tiles";

export enum GLTileNamesStub {
  MyDraftJEs = "my-draft-jes",
  NotPostedPostingRequested = "not-posted-posting-requested",
  PostedSignOffRequested = "posted-sign-off-requested",
  PostedActionRequired = "posted-action-required",
  PostedReviewerActionRequired = "posted-reviewer-action-required",
  PostedPosterActionRequired = "posted-poster-action-required",
  PreReviewerActionRequired = "pre-review-je-prereviewer-action-required",
  PosterPreReviewRequested = "poster-prereview-requested"
}

export const GLTileNamesMap = Object.freeze({
  MyDraftJEs: "My Draft JEs",
  NotPostedPostingRequested: "Not Posted - Posting Requested",
  PostedSignOffRequested: "Posted - Sign-Off Requested",
  PostedActionRequired: "Posted - Action Required",
  PostedReviewerActionRequired: "Posted - Reviewer Action Required",
  PostedPosterActionRequired: "Posted - Poster Action Required",
  PreReviewerActionRequired: "Pre-Review JE - Pre-Reviewer Action Required",
  PosterPreReviewRequested: "Poster - PreReview Requested"
});

export enum GLTileNames {
  MyDraftJEs = "My Draft JEs",
  NotPostedPostingRequested = "Not Posted - Posting Requested",
  PostedSignOffRequested = "Posted - Sign-Off Requested",
  PostedActionRequired = "Posted - Action Required",
  PostedReviewerActionRequired = "Posted - Reviewer Action Required",
  PostedPosterActionRequired = "Posted - Poster Action Required",
  PreReviewerActionRequired = "Pre-Review JE - Pre-Reviewer Action Required",
  PosterPreReviewRequested = "Poster - PreReview Requested"
}

export function glStubToTileName(tileStub: GLTileNamesStub): GLTileNames {
  switch (tileStub) {
    case GLTileNamesStub.MyDraftJEs:
      return GLTileNames.MyDraftJEs;
    case GLTileNamesStub.NotPostedPostingRequested:
      return GLTileNames.NotPostedPostingRequested;
    case GLTileNamesStub.PostedSignOffRequested:
      return GLTileNames.PostedSignOffRequested;
    case GLTileNamesStub.PostedActionRequired:
      return GLTileNames.PostedActionRequired;
    case GLTileNamesStub.PostedReviewerActionRequired:
      return GLTileNames.PostedReviewerActionRequired;
    case GLTileNamesStub.PostedPosterActionRequired:
      return GLTileNames.PostedPosterActionRequired;
    case GLTileNamesStub.PreReviewerActionRequired:
      return GLTileNames.PreReviewerActionRequired;
    case GLTileNamesStub.PosterPreReviewRequested:
      return GLTileNames.PosterPreReviewRequested;
    default:
      return GLTileNames.MyDraftJEs;
  }
}

export function glTileNameToStub(tileName: GLTileNames): GLTileNamesStub {
  switch (tileName) {
    case GLTileNames.MyDraftJEs:
      return GLTileNamesStub.MyDraftJEs;
    case GLTileNames.NotPostedPostingRequested:
      return GLTileNamesStub.NotPostedPostingRequested;
    case GLTileNames.PostedSignOffRequested:
      return GLTileNamesStub.PostedSignOffRequested;
    case GLTileNames.PostedActionRequired:
      return GLTileNamesStub.PostedActionRequired;
    case GLTileNames.PostedReviewerActionRequired:
      return GLTileNamesStub.PostedReviewerActionRequired;
    case GLTileNames.PostedPosterActionRequired:
      return GLTileNamesStub.PostedPosterActionRequired;
    case GLTileNames.PreReviewerActionRequired:
      return GLTileNamesStub.PreReviewerActionRequired;
    case GLTileNames.PosterPreReviewRequested:
      return GLTileNamesStub.PosterPreReviewRequested;
    default:
      return GLTileNamesStub.MyDraftJEs;
  }
}

export const TileNames = Object.freeze(GLTileNames);

export interface ITile {
  tileName: string;
  count: number;
}

export type GLTilesAndCount = {
  [key in GLTileNames]: number;
};

export type ApprovedPreReviewResponse = {
  approvedDraftCount: number;
  userAlias: string;
};
export type PostPreReviewResponse = {
  refGuid: string;
  type: string;
  postingStatus: boolean;
  errorMessage: string;
};

export function getDashboardDisplayNames(tilName: string): GLTileNames {
  switch (tilName) {
    case "PendingDraft":
      return GLTileNames.MyDraftJEs;
    case "PendingReview":
      return GLTileNames.NotPostedPostingRequested;
    case "PosterSignOffRequested":
      return GLTileNames.PostedSignOffRequested;
    case "PosterActionRequired":
      return GLTileNames.PostedActionRequired;
    case "PostedReviewerActionRequired":
      return GLTileNames.PostedReviewerActionRequired;
    case "PostedPosterActionRequired":
      return GLTileNames.PostedPosterActionRequired;
    case "PreReviewerActionRequired":
      return GLTileNames.PreReviewerActionRequired;
    case "PosterPreReviewRequested":
      return GLTileNames.PosterPreReviewRequested;
    default:
      return GLTileNames.MyDraftJEs;
  }
}

export function getGridNamesForAPI(tileName: GLTileNames) {
  switch (tileName) {
    case GLTileNames.MyDraftJEs:
      return "PendingDraft";
    case GLTileNames.NotPostedPostingRequested:
      return "PendingReview";
    case GLTileNames.PostedSignOffRequested:
      return "PosterSignOffRequested";
    case GLTileNames.PostedActionRequired:
      return "PosterActionRequired";
    case GLTileNames.PostedReviewerActionRequired:
      return "PostedReviewerActionRequired";
    case GLTileNames.PostedPosterActionRequired:
      return "PostedPosterActionRequired";
    case GLTileNames.PreReviewerActionRequired:
      return "PreReviewerActionRequired";
    case GLTileNames.PosterPreReviewRequested:
      return "PosterPreReviewRequested";
    default:
      return "PendingDraft";
  }
}

export async function getDashboardCounts(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<ITile[]> {
  let endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getGLDashboardCounts}`;
  const period = localStorage.getItem("glDashboardTimeframe");
  if (period && period.length) {
    endpoint = `${endpoint}/${period}`;
  } else {
    endpoint = `${endpoint}/180`;
  }
  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<ITile[]>(endpointUrl, getTokenFn);
  return response as ITile[];
}

export async function fetchDashboardCounts(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<OrderedMap<string, IIndexedTile>> {
  const tileCounts = await getDashboardCounts(configuration, getTokenFn);
  if (!tileCounts) {
    throw new ApiError("Journal Entries not found");
  }
  if (tileCounts.length === 0) {
    return initialTiles;
  }
  const tiles = tileCounts.reduce(
    (ctr, tile) => Object.assign(ctr, { [getDashboardDisplayNames(tile.tileName)]: tile.count }),
    {} as GLTilesAndCount
  );

  return convertToTilesInSpecificOrder(tiles);
}

export function sanitizeDraftDashboardRow(row: any): SanitizedDraftDashboardRow {
  let poster = "";
  let additionalPosters = "";
  if (row.additionalPosters.length !== 0 || row.additionalPosters !== "") {
    poster = row.additionalPosters.includes(";")
      ? (row.additionalPosters.split(";").shift() as string)
      : row.additionalPosters;
    additionalPosters =
      row.additionalPosters.split(";").length > 1 ? row.additionalPosters.split(";").slice(1).join(";") : "";
  }

  const draftName = ApiParsingUtilities.addDefault(row.draftName, "");
  const newSanitizedDraftDashboardRow: SanitizedDraftDashboardRow = {
    idForUI: draftName,
    jeId: ApiParsingUtilities.addDefault(row.id, ""),
    poster: ApiParsingUtilities.addDefault(poster, ""),
    additionalPosters: ApiParsingUtilities.addDefault(additionalPosters, "")
      .split(";")
      .filter((x) => x),
    attachmentStatus: ApiParsingUtilities.addDefault(row.attachmentStatus, ""),
    attachments: ApiParsingUtilities.addDefault(row.attachments, ""),
    checklistRefguid: ApiParsingUtilities.addDefault(row.checklistRefguid, ""),
    companyCode: ApiParsingUtilities.addDefault(row.multipleCompanyCodes, "")
      .split(",")
      .filter((x) => x),
    description: ApiParsingUtilities.addDefault(row.description, ""),
    draftName: draftName,
    fiscalPeriod: ApiParsingUtilities.addDefault(row.fiscalPeriod, ""),
    format: GetClassification(row.postingType),
    isBPOEntry: ApiParsingUtilities.addDefault(row.isBPOEntry, ""),
    jeRefNo: ApiParsingUtilities.addDefault(row.jeRefNo, ""),
    lastComments: ApiParsingUtilities.addDefault(row.lastComments, ""),
    modifiedOn: ApiParsingUtilities.parseDate(row.modifiedOn),
    postingType: ApiParsingUtilities.addDefault(row.postingType, ""),
    refGuid: ApiParsingUtilities.addDefault(row.refGuid, ""),
    reoccuring: ApiParsingUtilities.addDefault(row.reoccuring, ""),
    reviewer: ApiParsingUtilities.addDefault(row.reviewer, "")
      .split(";")
      .filter((x) => x),
    status: GetDraftStatusString(row.status),
    statusCode: row.status as number,
    templateid: ApiParsingUtilities.addDefault(row.templateid, ""),
    id: ApiParsingUtilities.addDefault(row.id, ""),
    postingPeriod: ApiParsingUtilities.addDefault(row.postingPeriod, ""),
    fiscalYear: ApiParsingUtilities.addDefault(row.fiscalYear, ""),
    rowWeightage: row.rowWeightage,
    isDraft: true,
    isPosted: false,
    processingStatus: "",
    procStatType: "Information"
  };

  return newSanitizedDraftDashboardRow;
}
export function sanitizePreReviewDashboardRow(row: any): SanitizedPreReviewDashboardRow {
  const draftName = ApiParsingUtilities.addDefault(row.draftName, "");
  const newSanitizedPreReviewDashboardRow: SanitizedPreReviewDashboardRow = {
    idForUI: draftName,
    jeId: ApiParsingUtilities.addDefault(row.id, ""),
    poster: ApiParsingUtilities.addDefault(row.poster, ""),
    additionalPosters: ApiParsingUtilities.addDefault(row.additionalPosters, "")
      .split(";")
      .filter((x) => x),
    attachmentStatus: ApiParsingUtilities.addDefault(row.attachmentStatus, ""),
    attachments: ApiParsingUtilities.addDefault(row.attachments, ""),
    checklistRefguid: ApiParsingUtilities.addDefault(row.checklistRefguid, ""),
    companyCode: ApiParsingUtilities.addDefault(row.multipleCompanyCodes, "")
      .split(",")
      .filter((x) => x),
    description: ApiParsingUtilities.addDefault(row.description, ""),
    draftName: draftName,
    fiscalPeriod: ApiParsingUtilities.addDefault(row.fiscalPeriod, ""),
    format: GetClassification(row.postingType),
    isBPOEntry: ApiParsingUtilities.addDefault(row.isBPOEntry, ""),
    jeRefNo: ApiParsingUtilities.addDefault(row.jeRefNo, ""),
    lastComments: ApiParsingUtilities.addDefault(row.lastComments, ""),
    modifiedOn: ApiParsingUtilities.parseDate(row.modifiedOn),
    postingType: ApiParsingUtilities.addDefault(row.postingType, ""),
    refGuid: ApiParsingUtilities.addDefault(row.refGuid, ""),
    reoccuring: ApiParsingUtilities.addDefault(row.reoccuring, ""),
    reviewer: ApiParsingUtilities.addDefault(row.reviewer, "")
      .split(";")
      .filter((x) => x),
    status: GetDraftStatusString(row.status),
    statusCode: row.status as number,
    templateid: ApiParsingUtilities.addDefault(row.templateid, ""),
    id: ApiParsingUtilities.addDefault(row.id, ""),
    postingPeriod: ApiParsingUtilities.addDefault(row.postingPeriod, ""),
    fiscalYear: ApiParsingUtilities.addDefault(row.fiscalYear, ""),
    rowWeightage: row.rowWeightage,
    isDraft: true,
    isPosted: false,
    processingStatus: "",
    procStatType: "Information",
    preReviewer: ApiParsingUtilities.addDefault(row.preReviewer, "")
      .split(";")
      .filter((x) => x),
    statusName: ApiParsingUtilities.addDefault(row.statusName, "")
  };
  return newSanitizedPreReviewDashboardRow;
}
export async function getGlDashboardData(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"],
  gridName: GLTileNames
): Promise<{
  gridName: GLTileNames;
  items: GLDashboardRow[];
}> {
  let endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getGLDashboard}`.replace(
    "{tileName}",
    getGridNamesForAPI(gridName)
  );

  const period = localStorage.getItem("glDashboardTimeframe");
  if (period && period.length) {
    endpoint = `${endpoint}/${period}`;
  } else {
    endpoint = `${endpoint}/180`;
  }

  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<any[]>(endpointUrl, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }

  if (gridName === "My Draft JEs") {
    const sanitizedDraftGLResult = await sanitizeDashboardDraftResultSet(response);
    return { gridName, items: sanitizedDraftGLResult as GLDashboardRow[] };
  } else if (
    gridName === "Pre-Review JE - Pre-Reviewer Action Required" ||
    gridName === "Poster - PreReview Requested"
  ) {
    const sanitizedDraftGLResult = await sanitizePreReviewDashboardResultSet(response);
    return { gridName, items: sanitizedDraftGLResult as GLDashboardRow[] };
  } else {
    const sanitizedDraftGLResult = await sanitizeDashboardResultSet(response);
    return {
      gridName,
      items: sanitizedDraftGLResult as GLDashboardRow[]
    };
  }
}

export function sanitizeDashboardResultSet(results: any[]): Promise<SanitizedDashboardRow[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) => sanitizeDashboardRow(row));
        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}

export function sanitizeDashboardDraftResultSet(results: any[]): Promise<SanitizedDraftDashboardRow[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) => sanitizeDraftDashboardRow(row));

        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}
export function sanitizePreReviewDashboardResultSet(results: any[]): Promise<SanitizedPreReviewDashboardRow[]> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        const sanitizedResults = results.map((row) => sanitizePreReviewDashboardRow(row));

        resolve(sanitizedResults);
      } catch (error) {
        reject(error);
      }
    }, 1);
  });
}

export async function fetchApprovedPreReviewDrafts(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<ApprovedPreReviewResponse> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.approvedPreReviewDrafts}`;
  const endpointUrl = getValidUrl(endpoint);
  const response = await getRequest<ApprovedPreReviewResponse>(endpointUrl, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  return response;
}

export async function postPreReview(
  configuration: JemConfiguration["GeneralLedgerApi"],
  getTokenFn: IUserProviderState["accessToken"]
): Promise<PostPreReviewResponse[]> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.postPreReview}`;
  const endpointUrl = getValidUrl(endpoint);
  const response = await putRequest<PostPreReviewResponse[]>(endpointUrl, undefined, getTokenFn);
  if (!response) {
    throw new ApiError("No response from server");
  }
  return response;
}
