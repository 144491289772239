import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import { TextField, Checkbox, Dropdown } from "@fluentui/react";
import {
  TenantRowDetails,
  JemConfiguration,
  JEMAliasPicker,
  convertToReasonCodeDropdownOptions,
  JEMContext,
  DomainDataObjects
} from "@jem/components";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";
import ActionButtons from "../../../JEMChecklist/copiedItems/ActionButtons";

interface ITenantDetailsPanelProps {
  tenantDetails: TenantRowDetails;
  configuration: JemConfiguration["GeneralLedgerApi"];
  onClosePanel: () => void;
  onSaveItem: (editedItem: TenantRowDetails) => void;
  loading?: boolean;
  error: string[];
  onboardNewTenantFlag?: boolean;
  isReadOnly?: boolean;
  isAutomatedJEDashboard?: boolean;
}

const TenantDetailsPanel = (props: ITenantDetailsPanelProps): JSX.Element => {
  const {
    tenantDetails,
    onClosePanel,
    onSaveItem,
    error,
    loading,
    onboardNewTenantFlag,
    isReadOnly = false,
    isAutomatedJEDashboard = false
  } = props;
  const form = useForm<TenantRowDetails>();
  const { control, errors, getValues, setValue, handleSubmit } = form;
  const domainContext = useContext(JEMContext);

  const splitDefaultReviewers = tenantDetails.defaultReviewers.length ? tenantDetails.defaultReviewers.split(";") : [];
  const splitTenantOwner = tenantDetails.tenantOwner.length ? tenantDetails.tenantOwner.split(";") : [];
  const splitAdditionalPosters = tenantDetails.additionalPosters.length
    ? tenantDetails.additionalPosters.split(";")
    : [];

  const [blockOnCompanyCodeFlag] = useState<boolean>(false);
  const [emailNeedsToBeBlocked, setEmailNeedsToBeBlocked] = useState<string>(tenantDetails?.emailNeedsToBeBlocked);
  const [allowTopicName, setAllowTopicName] = useState<boolean>(tenantDetails?.allowTopicName);
  const [allowAutoReview, setAllowAutoReview] = useState<boolean>(tenantDetails?.allowAutoReview);
  const [overrideTenantSetting, setOverrideTenantSetting] = useState<boolean>(tenantDetails?.overrideTenantSetting);
  const [allowedPostingInJEM, setAllowedPostingInJEM] = useState<boolean>(tenantDetails?.allowedPostingInJEM);
  const [additionalPosters, setAdditionalPosters] = useState<string[]>(splitAdditionalPosters);
  const [tenantOwner, setTenantOwner] = useState<string[]>(splitTenantOwner);
  const [defaultReviewers, setDefaultReviewers] = useState<string[]>(splitDefaultReviewers);

  const reasonCodeOptions = convertToReasonCodeDropdownOptions(
    domainContext.initInfo.values?.JeReasonCodes as DomainDataObjects["JeReasonCodes"]
  );

  const validateCoCodeThenSubmit = () => {
    if (!blockOnCompanyCodeFlag) {
      onHandleSubmit();
    }
  };

  const onHandleSubmit = () => {
    const {
      appId,
      name,
      sapUserId,
      primaryPoster,
      emailContacts,
      batchNamePrefix,
      batchDescription,
      batchPurposeSuffix,
      reasonCode,
      jeReferenceNo,
      docType,
      serviceBus,
      sbRetryQueueName,
      allowedMaxValidationsErrorCount,
      topicName
    } = getValues();

    tenantDetails.appId = appId;
    tenantDetails.name = name;
    tenantDetails.sapUserId = sapUserId;
    tenantDetails.tenantOwner = tenantOwner.join(";");
    tenantDetails.primaryPoster = primaryPoster;
    tenantDetails.additionalPosters = additionalPosters.join(";");
    tenantDetails.defaultReviewers = defaultReviewers.join(";");
    tenantDetails.emailContacts = emailContacts;
    tenantDetails.allowAutoReview = allowAutoReview;
    tenantDetails.overrideTenantSetting = overrideTenantSetting;
    tenantDetails.allowedPostingInJEM = allowedPostingInJEM;
    tenantDetails.batchNamePrefix = batchNamePrefix;
    tenantDetails.batchDescription = batchDescription;
    tenantDetails.batchPurposeSuffix = batchPurposeSuffix;

    tenantDetails.reasonCode = reasonCode;
    tenantDetails.jeReferenceNo = jeReferenceNo;
    tenantDetails.docType = docType;
    tenantDetails.serviceBus = serviceBus;
    tenantDetails.sbRetryQueueName = sbRetryQueueName;
    tenantDetails.allowedMaxValidationsErrorCount = allowedMaxValidationsErrorCount;
    tenantDetails.emailNeedsToBeBlocked = emailNeedsToBeBlocked;
    tenantDetails.allowTopicName = allowTopicName;
    tenantDetails.topicName = topicName;
    tenantDetails.isAutomatedJEDashboard = isAutomatedJEDashboard;
    onSaveItem(tenantDetails);
  };

  const titleText = !onboardNewTenantFlag ? "Edit Tenant" : "Onboard New Tenant";
  const defaultTopicname = !isAutomatedJEDashboard ? (!onboardNewTenantFlag ? `${tenantDetails.name}_Topic` : "{TenantName}_Topic") : "";

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.large}
      titleText={titleText}
      isOpen
      onDismiss={onClosePanel}
      hasCloseButton
      closeButtonAriaLabel="Close Tenant Panel"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          mutationLoading={false}
          closePanel={onClosePanel}
          handleSubmit={handleSubmit(validateCoCodeThenSubmit)}
          saveLabel="Save"
          saveTitle="Save"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
          disabled={loading || isReadOnly}
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={error} />
      <form onSubmit={validateCoCodeThenSubmit}>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="App Id (Azure Id/Client Id)"
                id="appId"
                required
                name="appId"
                control={control}
                resizable={false}
                defaultValue={tenantDetails.appId}
                value={tenantDetails.appId}
                errorMessage={errors?.appId?.message}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter App Id"
                  }
                }}
                disabled={tenantDetails?.appId !== ""}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Tenant Name"
                id="name"
                required
                name="name"
                control={control}
                resizable={false}
                defaultValue={tenantDetails.name}
                value={tenantDetails.name}
                errorMessage={errors?.name?.message}
                rules={{
                  required: {
                    value: true,
                    message: "Please enter tenant name"
                  },
                  maxLength: {
                    value: 75,
                    message: "Name cannot have more than 75 characters"
                  }
                }}
                disabled={isAutomatedJEDashboard}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="S2S System User"
                id="sapUserId"
                name="sapUserId"
                control={control}
                resizable={false}
                placeholder="Z-JEMSRV"
                defaultValue={tenantDetails.sapUserId}
                value={tenantDetails.sapUserId}
                errorMessage={errors?.sapUserId?.message}
                disabled={tenantDetails?.appId !== ""}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Primary Poster"
                id="primaryPoster"
                name="primaryPoster"
                control={control}
                resizable={false}
                defaultValue={tenantDetails.primaryPoster}
                value={tenantDetails.primaryPoster}
                errorMessage={errors?.primaryPoster?.message}
                disabled={isAutomatedJEDashboard && isReadOnly}
                required
                rules={{
                  required: {
                    value: true,
                    message: "Please enter Primary poster"
                  }
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg8">
              <JEMAliasPicker
                label="Tenant Owners"
                initialAliases={tenantOwner}
                disabled={isAutomatedJEDashboard && isReadOnly}
                onChange={(newTenantOwner) => {
                  setTenantOwner(newTenantOwner);
                }}
                itemLimit={16}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <JEMAliasPicker
                label="Additional Posters"
                initialAliases={additionalPosters}
                disabled={isAutomatedJEDashboard && isReadOnly}
                onChange={(newAdditionalPosters) => {
                  setAdditionalPosters(newAdditionalPosters);
                }}
                itemLimit={16}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
              <JEMAliasPicker
                label="Default Reviewers"
                initialAliases={defaultReviewers}
                disabled={isAutomatedJEDashboard && isReadOnly}
                onChange={(newDefaultReviewers) => {
                  setDefaultReviewers(newDefaultReviewers);
                }}
                itemLimit={16}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Email Contact"
                id="emailContacts"
                name="emailContacts"
                control={control}
                resizable={false}
                defaultValue={tenantDetails.emailContacts}
                value={tenantDetails.emailContacts}
                errorMessage={errors?.emailContacts?.message}
                disabled={isAutomatedJEDashboard}
              />
            </div>
          </div>
          <div className="ms-Grid-row" style={{ marginTop: "10px" }}>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Checkbox
                label="Allow Auto Review"
                checked={allowAutoReview}
                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                  setAllowAutoReview(!!checked);
                }}
                disabled={overrideTenantSetting || isAutomatedJEDashboard}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Checkbox
                label="Allow Override Settings"
                checked={overrideTenantSetting}
                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                  setOverrideTenantSetting(!!checked);
                }}
                disabled={allowAutoReview || isAutomatedJEDashboard}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Checkbox
                label="Allow Re-posting in JEM"
                checked={allowedPostingInJEM}
                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                  setAllowedPostingInJEM(!!checked);
                }}
                disabled={isAutomatedJEDashboard}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Posting Name"
                id="batchNamePrefix"
                name="batchNamePrefix"
                required={allowAutoReview}
                control={control}
                resizable={false}
                defaultValue={tenantDetails.batchNamePrefix}
                value={tenantDetails.batchNamePrefix}
                errorMessage={errors?.batchNamePrefix?.message}
                disabled={isAutomatedJEDashboard}
                rules={{
                  required: {
                    value: allowAutoReview,
                    message: "Please enter Posting name"
                  }
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Posting Description"
                id="batchDescription"
                name="batchDescription"
                required={allowAutoReview}
                control={control}
                resizable={false}
                defaultValue={tenantDetails.batchDescription}
                value={tenantDetails.batchDescription}
                errorMessage={errors?.batchDescription?.message}
                disabled={isAutomatedJEDashboard}
                rules={{
                  required: {
                    value: allowAutoReview,
                    message: "Please enter Posting Description"
                  }
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Posting Purpose"
                id="batchPurposeSuffix"
                name="batchPurposeSuffix"
                required={allowAutoReview}
                control={control}
                resizable={false}
                defaultValue={tenantDetails.batchPurposeSuffix}
                value={tenantDetails.batchPurposeSuffix}
                errorMessage={errors?.batchPurposeSuffix?.message}
                disabled={isAutomatedJEDashboard}
                rules={{
                  required: {
                    value: allowAutoReview,
                    message: "Please enter Posting Purpose"
                  }
                }}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                label="Posting Reason Code"
                id="reasonCode"
                name="reasonCode"
                control={control}
                resizable={false}
                defaultValue={tenantDetails.reasonCode + "  "}
                value={tenantDetails.reasonCode + "  "}
                errorMessage={errors?.reasonCode?.message}
                required={allowAutoReview}
                rules={{
                  required: {
                    value: allowAutoReview,
                    message: "Please select Posting Reason code"
                  }
                }}
                render={({ value }) => {
                  return (
                    <Dropdown
                      id="reasonCode"
                      label="Reason Code"
                      placeholder="Select One"
                      selectedKey={value}
                      options={reasonCodeOptions}
                      onChange={(e, newValue): void => {
                        setValue("reasonCode", newValue?.key);
                      }}
                      required={allowAutoReview}
                      disabled={isAutomatedJEDashboard}
                    />
                  );
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Posting Reference"
                id="jeReferenceNo"
                name="jeReferenceNo"
                required={allowAutoReview}
                control={control}
                resizable={false}
                defaultValue={tenantDetails.jeReferenceNo}
                value={tenantDetails.jeReferenceNo}
                errorMessage={errors?.jeReferenceNo?.message}
                disabled={isAutomatedJEDashboard}
                rules={{
                  required: {
                    value: allowAutoReview,
                    message: "Please enter Posting Reference"
                  }
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Posting Doc Type"
                id="docType"
                name="docType"
                control={control}
                resizable={false}
                defaultValue={tenantDetails.docType}
                value={tenantDetails.docType}
                errorMessage={errors?.docType?.message}
                disabled={isAutomatedJEDashboard}
              />
            </div>
          </div>
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Service Bus"
                id="serviceBus"
                name="serviceBus"
                control={control}
                resizable={false}
                defaultValue={tenantDetails.serviceBus}
                value={tenantDetails.serviceBus}
                errorMessage={errors?.serviceBus?.message}
                disabled={isAutomatedJEDashboard}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Queue Name"
                id="sbRetryQueueName"
                name="sbRetryQueueName"
                control={control}
                resizable={false}
                defaultValue={tenantDetails.sbRetryQueueName}
                value={tenantDetails.sbRetryQueueName}
                errorMessage={errors?.sbRetryQueueName?.message}
                required={!isAutomatedJEDashboard && tenantDetails.serviceBus!=""}
                disabled={isAutomatedJEDashboard}
                rules={{
                  required: {
                    value: !isAutomatedJEDashboard && tenantDetails.serviceBus!="",
                    message: "Please enter Queue Name"
                  }
                }}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Max Error Threshold"
                id="allowedMaxValidationsErrorCount"
                name="allowedMaxValidationsErrorCount"
                control={control}
                resizable={false}
                disabled={isAutomatedJEDashboard}
                defaultValue={tenantDetails.allowedMaxValidationsErrorCount}
                value={tenantDetails.allowedMaxValidationsErrorCount}
                errorMessage={errors?.allowedMaxValidationsErrorCount?.message}
              />
            </div>
          </div>
          <div className="ms-Grid-row" style={{ marginTop: "10px" }}>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Checkbox
                label="Send Notification"
                checked={emailNeedsToBeBlocked === "0" ? true : false}
                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                  setEmailNeedsToBeBlocked(checked ? "0" : "1");
                }}
                disabled={isAutomatedJEDashboard}
              />
            </div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Checkbox
                label="Allow Topic Name"
                checked={allowTopicName}
                onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                  setAllowTopicName(!!checked);
                }}
                disabled={isAutomatedJEDashboard}
              />
            </div>
          </div>
          <div className="ms-Grid-row" style={{ marginTop: "10px" }}>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
              <Controller
                as={TextField}
                label="Topic Name"
                id="topicName"
                name="topicName"
                placeholder={defaultTopicname}
                control={control}
                resizable={false}
                disabled={isAutomatedJEDashboard}
                defaultValue={tenantDetails.topicName}
                value={tenantDetails.topicName}
                errorMessage={errors?.topicName?.message}
              />
            </div>
          </div>
        </div>
      </form>
    </CoherencePanel>
  );
};
export default TenantDetailsPanel;
