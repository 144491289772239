import { css } from "@emotion/css";
import {
  IDropdownOption,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  PivotItem,
  Separator,
  useTheme
} from "@fluentui/react";
import { Form, Formik, FormikProps } from "formik";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { JemConfiguration } from "../../../JemConfiguration";
import { ApiError, LoadingStatus, MockDataFn, UserContext } from "../../../Shared";
import { PageHeading } from "../../../Shared/components/PageHeading/PageHeading";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { LoggingContext } from "../../../Shared/contexts/LoggingContext/LoggingContext";
import { LocalMessage } from "../../../Shared/utilities/SharedModels";
import { AdditionalData } from "../../shared";
import { useGLAttachmentActionsRequests } from "../../shared/GL.AttachmentActionsRequests";
import { GLAttachmentsRef } from "../../shared/GL.Attachments";
import { batchDetailsOfflineValidation } from "../../validation/CheckForErrorsInTabs";
import { GLBatchActionResult, UIBatchActionDelegation } from "./Actions/GLBatch.UIActionDelegation";
import { useGLBatchActions } from "./Actions/GLBatchActions";
import { GLBatchActions, GLBatchActionsEnum } from "./GLBatch.Actions";
import { BatchTitle } from "./GLBatch.BatchTitle";
import { fetchBatchFromEndpoint } from "./GLBatch.requests";
import { batchStateToFormikState, createBlankBatchState, defaultPageStateForBatch } from "./GLBatch.State";
import { GLBatchFormikState, GLBatchTabNames } from "./GLBatch.types";
import GLBatchPivotItems from "./GLBatchPivotItems";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useQuery } from "../../../Shared/hooks/useQuery";

const isEqual = require("lodash/isEqual");

export interface GLBatchFormProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
  mockBatchDataFn?: MockDataFn<any>;
  // string - a valid refguid
  // null - no refguid in search params (new batch)
  // undefined - still loading either null or string from search params (initial load)
  refGuid: string | null | undefined;
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.JeBatchFields
    | DomainDataEnum.JeParameters
  > | null;
  onDownloadTemplate: (template: GLBatchActionsEnum.DownloadExcel | GLBatchActionsEnum.DownloadXML) => void;
  onNavigate: (refguid: string, navigateBack: boolean) => void;
  getNoActivityReasonOptions: () => Promise<IDropdownOption[]>;
}

let GLBatchForm: React.FC<GLBatchFormProps> = (props) => {
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const logger = useContext(LoggingContext);

  const domainData = props.domainData || {
    JeReasonCodes: [],
    FiscalPeriods: [],
    JeBatchFields: [],
    JeParameters: []
  };
  const [message, setMessage] = useState<LocalMessage | null>(null);
  const [previousActionResult, setPreviousActionResult] = useState<GLBatchActionResult | null>(null);

  const initialData = useMemo(() => {
    if (props.domainData) {
      return defaultPageStateForBatch(userContext, props.domainData);
    } else {
      return createBlankBatchState();
    }
  }, [props.domainData]);

  const formikRef = useRef<FormikProps<GLBatchFormikState>>(null);

  const cacheKey = ["batch"];
  if (props.refGuid) {
    cacheKey.push(props.refGuid);
  }

  const { isLoading, data, error, isFetching, refetch } = useQuery({
    queryKey: cacheKey,
    queryFn: (queryOptions) => {
      if (!queryOptions.queryKey) return Promise.resolve(initialData);

      const id = queryOptions.queryKey[1] as string;
      return fetchBatchFromEndpoint(props.configuration, props.domainData, userContext, id, logger.appInsights);
    },
    enabled: props.refGuid !== undefined && props.refGuid !== null,
    staleTime: 0
  });
  const theState = data || initialData;

  const [actionSettings, setActionSettings] = useState<null | {
    userAction: GLBatchActionsEnum;
    options: AdditionalData;
  }>(null);
  const [currentTab, setCurrentTab] = useState<GLBatchTabNames>(GLBatchTabNames.batchDetails);

  const attachmentActions = useGLAttachmentActionsRequests(
    props.attachmentsConfiguration,
    `${props.configuration.baseApiUrl}${props.configuration.endpoints.attachmentDelete}`
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Pending);
  // also set to disabled if status is posted or closed
  const [disabled, setDisabled] = useState<boolean>(userContext.jemUser.hasSapAccess === false);
  const [counter, setCounter] = useState(0);
  const actions = useGLBatchActions(props.configuration, props.attachmentsConfiguration, domainData);

  const IsFCCWIntegrationLive =
    domainData.JeParameters.filter((item) => item.name === "IsFCCWIntegrationLive").map(
      (item) => item.value.toLowerCase() == "true"
    )[0] ?? false;

  const [formikState, setFormikState] = useState<GLBatchFormikState>(
    batchStateToFormikState(theState.batchState, previousActionResult)
  );

  const attachmentsRef = useRef<GLAttachmentsRef>(null);

  const handleTabClick = (item?: PivotItem) => {
    if (item !== undefined) {
      const tileName = item.props.itemKey as GLBatchTabNames;
      setCurrentTab(tileName);
    }
  };

  useEffect(
    function handleRefGuidChangesAndNavigation() {
      if (isLoading || isFetching || props.refGuid === undefined) {
        if (loadingStatus !== LoadingStatus.Pending) setLoadingStatus(LoadingStatus.Pending);
        return;
      }
      console.log("useEffect: handleRefGuidChangesAndNavigation");

      if (props.refGuid === null) {
        setPreviousActionResult(null);
        setMessage(null);
        const newFormikState = batchStateToFormikState(initialData.batchState, null);
        setFormikState(newFormikState);
      } else if (data) {
        const newFormikState = batchStateToFormikState(data.batchState, previousActionResult);
        setFormikState(newFormikState);
      }

      if (error) {
        setMessage({
          type: MessageBarType.error,
          message: (error as any).message || "An error occurred while loading the data."
        });
      }

      const timer = setTimeout(() => {
        setLoadingStatus(LoadingStatus.Resolved);
      }, 150);

      return () => {
        if (timer) clearTimeout(timer);
      };
    },
    [props.refGuid, isFetching, isLoading, data]
  );

  useEffect(
    function processAction() {
      // The effect has to depend on setFormikState. If you're here for a change of value rather than a real action, just leave.
      if (actionSettings === null) return;

      // Each action must reset the action to NONE to avoid repeating the action if the values change again.
      setActionSettings(null);

      if (!formikRef.current) {
        // This should never happen.
        logger.appInsights?.trackException({ exception: new Error("formikRef.current is null") });
        return;
      }
      if (!attachmentsRef.current) {
        // This should never happen
        logger.appInsights?.trackException({ exception: new Error("attachmentsRef.current is null") });
        return;
      }

      const action = actionSettings.userAction as
        | GLBatchActionsEnum.Clear
        | GLBatchActionsEnum.Post
        | GLBatchActionsEnum.Save
        | GLBatchActionsEnum.Validate
        | GLBatchActionsEnum.PreReview;

      setLoadingStatus(LoadingStatus.Pending);
      setMessage(null);

      UIBatchActionDelegation({
        userAction: action,
        actionOptions: actionSettings.options,
        userChanges: formikRef.current.values,
        attachmentsRef: attachmentsRef.current,
        initialState: theState,
        configuration: props.configuration,
        userContext,
        domainData,
        actions
      })
        .then((result) => {
          let hasError = false;
          if (formikRef.current) {
            if (result.batchErrors && result.batchErrors.length > 0) {
              formikRef.current.setFieldValue("errorTabErrorTable", result.batchErrors);
              hasError = true;
            } else {
              formikRef.current.setFieldValue("errorTabErrorTable", []);
            }
          }

          setPreviousActionResult(result);
          setMessage(result.message);

          if (hasError) {
            setCurrentTab(GLBatchTabNames.errorDetails);
          }

          if (result.options && result.options.preventReloadOfData) {
            return;
          }

          if (props.refGuid) {
            if (result.blockPage) {
              setDisabled(true);
            } else {
              refetch();
            }
          } else {
            props.onNavigate(theState.batchState.refGuid, false);
          }
          if (result.action === GLBatchActionsEnum.PreReview && result.message.type === MessageBarType.success) {
            setCounter(counter + 1);
          }
        })
        .catch((e) => {
          logger.appInsights?.trackException({
            exception: e,
            severityLevel: SeverityLevel.Error,
            properties: {
              userAction: action,
              refGuid: props.refGuid
            }
          });
          let message = `An error occurred while processing your request. Please try again later.`;
          if (e instanceof ApiError) {
            message = e.message;
          }
          const errorActionResult: GLBatchActionResult = {
            action: action,
            RefGuid: theState.batchState.refGuid,
            message: {
              message: message,
              type: MessageBarType.error
            },
            blockPage: false
          };
          setPreviousActionResult(errorActionResult);
          setMessage(errorActionResult.message);
          if (action === GLBatchActionsEnum.Validate || action === GLBatchActionsEnum.Post) {
            refetch();
          }
        })
        .finally(() => {
          setLoadingStatus(LoadingStatus.Resolved);
        });
    },
    [actionSettings, setFormikState]
  );
  useEffect(() => {
    sessionStorage.setItem("prereviewcounter", counter.toString());
  }, [counter]);
  return (
    <>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={formikState}
          onSubmit={setFormikState}
          validateOnChange={false}
          validateOnBlur={true}
          validateOnMount={props.refGuid ? true : false}
          innerRef={formikRef}
          validationSchema={batchDetailsOfflineValidation(GLBatchActionsEnum.Save, theState.batchState, domainData)}
        >
          {(formik) => (
            <Form>
              <PageHeading
                overrideRootStyles={css`
                  margin: 0;
                  background-color: ${theme.palette.neutralLighterAlt};
                `}
              >
                <>
                  <GLBatchActions
                    onAction={async function (actionName: GLBatchActionsEnum, options: any): Promise<void> {
                      if (
                        actionName === GLBatchActionsEnum.DownloadExcel ||
                        actionName === GLBatchActionsEnum.DownloadXML
                      ) {
                        if (props.onDownloadTemplate) props.onDownloadTemplate(actionName);
                      } else {
                        try {
                          await formik.submitForm();
                          setActionSettings({ userAction: actionName, options: options });
                        } catch (e) {
                          console.log(e);
                        }
                      }
                    }}
                    disabled={disabled || loadingStatus === LoadingStatus.Pending}
                    jemUser={userContext.jemUser}
                    batchState={theState.batchState}
                    getNoActivityReasonOptions={props.getNoActivityReasonOptions}
                    IsFCCWIntegrationLive={IsFCCWIntegrationLive}
                  />
                </>
              </PageHeading>
              <div
                ref={null}
                className={css`
                  width: 100%;
                  min-height: 1px;
                  box-sizing: border-box;
                  overflow: hidden;
                `}
              >
                <Separator
                  styles={{
                    root: {
                      margin: "0px",
                      padding: "0px",
                      height: "1px",
                      backgroundColor: theme.palette.neutralLighterAlt,
                      ":before": {
                        backgroundColor: theme.palette.neutralTertiaryAlt
                      }
                    }
                  }}
                />
              </div>
              <div
                className={css`
                  padding: 0 16px 0 16px;
                  padding-top: 0px;
                  background-color: ${theme.palette.neutralLighterAlt};
                `}
              >
                <BatchTitle
                  theme={theme}
                  state={theState}
                  disabled={disabled}
                  userPermissions={userContext.jemUser}
                  loadingStatus={loadingStatus}
                  onNavigate={function (): void {
                    props.onNavigate("", true);
                  }}
                />
              </div>
              {!message ? null : (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <MessageBar
                      messageBarType={message.type}
                      isMultiline={false}
                      dismissButtonAriaLabel="Close"
                      onDismiss={() => setMessage(null)}
                      actions={
                        disabled ? (
                          <div>
                            <MessageBarButton
                              onClick={() => {
                                props.onNavigate("", true);
                              }}
                            >
                              Back to Dashboard
                            </MessageBarButton>
                          </div>
                        ) : undefined
                      }
                    >
                      {message.message}
                    </MessageBar>
                  </div>
                </div>
              )}
              <GLBatchPivotItems
                configuration={props.configuration}
                disabled={disabled}
                loadingStatus={loadingStatus}
                currentTab={currentTab}
                onTabChange={handleTabClick}
                initialState={theState}
                domainData={domainData}
                attachmentsRef={attachmentsRef}
                onUploadFile={attachmentActions.uploadAttachment}
                onDeleteFile={attachmentActions.deleteAttachment}
                onDownloadFile={attachmentActions.downloadAttachment}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

GLBatchForm = React.memo(GLBatchForm, (prevProps, nextProps) => {
  if (prevProps.refGuid !== nextProps.refGuid) return false;
  if (!isEqual(prevProps.configuration, nextProps.configuration)) return false;
  if (!isEqual(prevProps.attachmentsConfiguration, nextProps.attachmentsConfiguration)) return false;
  if (!isEqual(prevProps.domainData, nextProps.domainData)) return false;
  return true;
});

GLBatchForm.displayName = "GLBatchForm";
export { GLBatchForm };
