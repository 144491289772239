/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
  Selection
} from "@fluentui/react";
import {
  DashboardGrid,
  IDashboardGridProps,
  IDashboardGridRef,
  JemConfiguration,
  PageHeading,
  PageStyles,
  LoadingStatus,
  LoadingSpinner,
  ScheduleJEResponseFromAPI,
  UserContext,
  TerminateRecurringJEResponse,
  exportToExcel,
  TerminateRecurringJEPayload,
  DashboardFilter,
  FilterSchema,
  FilterState,
  IIndexedTile,
  DashboardListActions,
  DashboardListActionsRef,
  GridCommandToggle,
  IDashboardListActionsState,
  TerminateApproveRecurringJEPayload,
  TerminateApproveRecurringJEResponse
} from "@jem/components";

import React, { useContext, useEffect, useRef, useState } from "react";
import { getScheduleDetailsColumns } from "../../components/GLScheduleJE/ScheduleJE.Columns";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getRecurringJE,
  getRecurringSchedules,
  terminateApproveRejectRecurringSchedules,
  terminateRecurringSchedule,
  viewRecurringSchedule
} from "../../components/GLScheduleJE/ScheduleRecurringJE.Requests";
import { CoherenceModal } from "@coherence-design-system/controls";
import { OrderedMap } from "immutable";
import { RecurringScheduleFilterRows } from "../../components/GLScheduleJE/ScheduleRecurringJE.filterRows";
import ScheduleJEHeader from "../../components/GLScheduleJE/ScheduleJE.Header";
import {
  ScheduleJETerminateApprovePopup,
  ScheduleJETerminatePopup
} from "../../components/GLScheduleJE/ScheduleJEPopups";
import { ScheduleJECommandBar } from "../../components/GLScheduleJE/ScheduleJECommandBar";
import BulkTransfer, { postBulkTransfer } from "../../components/GLScheduleJE/BulkTransfer";
import { getBulkTranferFormInputConfig } from "../../components/GLScheduleJE/TaskBulkTransfer.config";
import { TaskBulkTransferInputDto } from "../../components/GLScheduleJE/TaskBulkTransferInput";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require("lodash/isEqual");

export interface RecurringScheduleJEProps {
  configuration: JemConfiguration["GeneralLedgerApi"];
  attachmentsConfiguration: JemConfiguration["DocumentsApi"];
}

const dashboardFilters: FilterSchema<ScheduleJEResponseFromAPI>[] = [
  {
    filterName: "Next posting date",
    columnName: "nextPostingDate"
  },
  {
    filterName: "Frequency",
    columnName: "recurringFrequency"
  }
];

const actionItems: IDashboardListActionsState = {
  retryDisabled: GridCommandToggle.Removed,
  saveDisabled: GridCommandToggle.Removed,
  sendToPosterDisabled: GridCommandToggle.Removed,
  approveDisabled: GridCommandToggle.Disabled,
  releaseForSignoffDisabled: GridCommandToggle.Removed,
  needsClarificationDisabled: GridCommandToggle.Disabled,
  addPosterDisabled: GridCommandToggle.Removed,
  addReviewerDisabled: GridCommandToggle.Removed,
  recallDisabled: GridCommandToggle.Removed,
  rescindDisabled: GridCommandToggle.Removed,
  addAttachmentDisabled: GridCommandToggle.Removed,
  deleteActionDisabled: GridCommandToggle.Removed,
  releaseToSAPDisabled: GridCommandToggle.Removed,
  sendBackFromTreasuryDisabled: GridCommandToggle.Removed,
  approvePreReviewDisabled: GridCommandToggle.Removed,
  needsClarificationPreReviewDisabled: GridCommandToggle.Removed,
  recallPreReviewDisabled: GridCommandToggle.Removed,
  reverseDisabled: GridCommandToggle.Removed
};

export interface IPageState {
  openBulkTransferPanel: boolean;
  loadingBulkTransder: boolean;
  selectedRows: ScheduleJEResponseFromAPI[];
}

const RecurringScheduleJEPage: React.FC<RecurringScheduleJEProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userContext = useContext(UserContext);
  const dashboardActionsRef = useRef<DashboardListActionsRef>(null);

  const [gridItems, setGridItems] = useState([]);
  const [recurringSchedules, setRecurringSchedules] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Pending);
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const [openTerminatePopup, setOpenTerminatePopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [jeDetails, setJeDetails] = useState<ScheduleJEResponseFromAPI | null>();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [messageDetails, setMessageDetails] = useState<TerminateRecurringJEResponse | null | any>(null);
  const [showMessageBar, setShowMessageBar] = useState(false);
  const [panelMessageDetails, setPanelMessageDetails] = useState<string>("");

  const [filteredItems, setFilteredItems] = useState<ScheduleJEResponseFromAPI[]>([]);
  const [filterState, setFilterState] = useState<FilterState | null>(null);
  const [currentTile, setCurrentTile] = useState<string>("all");
  const [openTerminateAssignedPopup, setOpenTerminateAssignedPopup] = useState(false);
  const [terminateAssignedItems, setTerminateAssignedItems] = useState<any>([]);
  const [actionType, setActionType] = useState<string>("");
  const [pageState, setPageState] = useState<IPageState>({
    openBulkTransferPanel: false,
    loadingBulkTransder: false,
    selectedRows: []
  });

  useEffect(() => {
    if (filterState) {
      location.state = {
        filterState
      };
    }
  }, [filterState]);

  useEffect(() => {
    if (gridItems) {
      setFilteredItems(
        filterState?.filterBar ? RecurringScheduleFilterRows(filterState, dashboardFilters, gridItems) : gridItems
      );
    }
  }, [gridItems]);

  const filterItems = (newFilterState?: FilterState) => {
    if (!gridItems) {
      return;
    }
    if (newFilterState) {
      if (!isEqual(newFilterState, filterState)) {
        setFilterState(newFilterState);
        const newItems = RecurringScheduleFilterRows(newFilterState, dashboardFilters, gridItems);
        setFilteredItems(newItems);
      }
    } else {
      setFilteredItems(gridItems);
    }
  };
  const getGrid = async (mounted = true) => {
    try {
      const items: any = await getRecurringSchedules(props.configuration, userContext.accessToken);
      if (mounted) {
        setRecurringSchedules(items);
        if (currentTile !== "all") {
          setGridItems(
            items.filter(
              (item: ScheduleJEResponseFromAPI) =>
                item.recurringScheduleStatus === "PendingTermination" &&
                item?.preReviewer
                  ?.split(";")
                  .map((reviewer) => reviewer.toLowerCase())
                  .includes(userContext.user?.alias?.toLowerCase())
            )
          );
        } else {
          setGridItems(items);
        }
        setLoadingStatus(LoadingStatus.Resolved);
      }
    } catch (err) {
      setLoadingStatus(LoadingStatus.Rejected);
    }
  };

  useEffect(() => {
    if (currentTile.length) {
      if (currentTile !== "all") {
        setGridItems(
          gridItems.filter(
            (item: ScheduleJEResponseFromAPI) =>
              item.recurringScheduleStatus === "PendingTermination" &&
              item?.preReviewer
                ?.split(";")
                .map((reviewer) => reviewer.toLowerCase())
                .includes(userContext.user?.alias?.toLowerCase())
          )
        );
      } else {
        setGridItems(recurringSchedules);
      }
    }
  }, [currentTile]);

  useEffect(() => {
    let mounted = true;
    getGrid(mounted);
    return () => {
      mounted = false;
    };
  }, []);

  const onViewClick = async (item: ScheduleJEResponseFromAPI) => {
    const response: { redirectPath: string } = await viewRecurringSchedule(
      props.configuration,
      userContext.accessToken,
      item.templateJeId
    );
    if (response.redirectPath) {
      navigate(`${response.redirectPath}${response.redirectPath.includes("create") ? "&isReadOnly=true" : ""}`, {
        state: {
          origin: location.pathname,
          state: location.state
        }
      });
    }
  };

  const onTerminateClick = (item: ScheduleJEResponseFromAPI) => {
    if (item.recurringScheduleStatus === "Terminated") {
      setMessageDetails({
        status: "Fail",
        message: "This schedule cannot be terminated as it has already been terminated"
      });
      setShowMessage(true);
      return;
    }
    if (item.recurringScheduleStatus === "PendingTermination") {
      setMessageDetails({
        status: "Fail",
        message: "This schedule cannot be terminated as it is already in pending termination status"
      });
      setShowMessage(true);
      return;
    }
    if (item.recurringScheduleStatus === "Expired") {
      setMessageDetails({
        status: "Fail",
        message: "This schedule cannot be terminated as it has already expired"
      });
      setShowMessage(true);
      return;
    }
    setJeDetails(item);
    setOpenTerminatePopup(true);
  };

  const onCloseTerminatePopup = () => {
    setJeDetails(null);
    setOpenTerminatePopup(false);
  };

  const onCloseTerminateAssignedPopup = () => {
    setJeDetails(null);
    setOpenTerminateAssignedPopup(false);
  };

  const onTerminateConfirm = async (comments: string) => {
    if (jeDetails) {
      setLoadingStatus(LoadingStatus.Pending);
      setOpenTerminatePopup(false);
      const payload: TerminateRecurringJEPayload = {
        recurringJEScheduleId: jeDetails.recurringJEScheduleId,
        comment: comments,
        nextPostingDate: jeDetails.nextPostingDate,
        status: jeDetails.recurringScheduleStatus
      };
      const response: TerminateRecurringJEResponse = await terminateRecurringSchedule(
        props.configuration,
        userContext.accessToken,
        payload
      );
      setMessageDetails(response);
      setShowMessage(true);
      if (response.status === "Success") {
        getGrid();
      } else {
        setLoadingStatus(LoadingStatus.Resolved);
      }
    }
  };

  const onEditClick = (item: ScheduleJEResponseFromAPI) => {
    if (item.recurringScheduleStatus === "Terminated") {
      setMessageDetails({
        status: "Fail",
        message: "This schedule cannot be edited as it has already been terminated"
      });
      setShowMessage(true);
      return;
    }
    if (item.recurringScheduleStatus === "Expired") {
      setMessageDetails({
        status: "Fail",
        message: "This schedule cannot be edited as it has already expired"
      });
      setShowMessage(true);
      return;
    }
    setJeDetails(item);
    setOpenEditPopup(true);
  };

  const onCloseEditPopup = () => {
    setJeDetails(null);
    setOpenEditPopup(false);
  };

  const onEditConfirm = async () => {
    if (jeDetails) {
      setLoadingStatus(LoadingStatus.Pending);
      setOpenEditPopup(false);
      const response = await getRecurringJE(
        props.configuration,
        userContext.accessToken,
        jeDetails.recurringJEScheduleId
      );
      if (response.refGuid) {
        navigate(`/gl/create?RefGuid=${response.refGuid}&isRecurringTab=true`, {
          state: {
            origin: location.pathname,
            state: location.state
          }
        });
      } else if (response) {
        setMessageDetails({
          status: "Fail",
          message: response
        });
        setShowMessage(true);
        setLoadingStatus(LoadingStatus.Idle);
      }
    }
  };

  const columnGenerator: IDashboardGridProps["columnGenerator"] = getScheduleDetailsColumns({
    onViewClick,
    onEditClick,
    onTerminateClick,
    currentTile
  });

  const currentDashboardType = (currentTile: string) => {
    setCurrentTile(currentTile);
  };

  const openTerminateAssignedConfirmationPopup = (type: string) => {
    setActionType(type);
    setTerminateAssignedItems(dashboardGridRef.current?.getSelection().items);
    setOpenTerminateAssignedPopup(true);
  };

  const terminateApproveRejectCall = async (type: string, comments: string) => {
    if (terminateAssignedItems?.length) {
      setLoadingStatus(LoadingStatus.Pending);
      setOpenTerminateAssignedPopup(false);
      try {
        const payload: TerminateApproveRecurringJEPayload = {
          recurringJEScheduleIds: terminateAssignedItems.map((items: any) => items.templateJeId),
          comment: comments
        };
        const response: TerminateApproveRecurringJEResponse = await terminateApproveRejectRecurringSchedules(
          props.configuration,
          userContext.accessToken,
          payload,
          type === "approve" ? "approve" : "reject"
        );
        setMessageDetails(response);
        setShowMessage(true);
        if (response.status === "Success") {
          getGrid();
        } else {
          setLoadingStatus(LoadingStatus.Resolved);
        }
      } catch (err) {
        setLoadingStatus(LoadingStatus.Rejected);
      }
    }
  };

  const approveSelectionedJE = async (comments = "") => {
    terminateApproveRejectCall("approve", comments);
  };
  const rejectSelectionedJE = async (comments = "") => {
    terminateApproveRejectCall("reject", comments);
  };

  const buttonCalculator = () => {
    if (terminateAssignedItems) {
      return {
        ...actionItems,
        approveDisabled: GridCommandToggle.Enabled,
        needsClarificationDisabled: GridCommandToggle.Enabled
      };
    }
    return actionItems;
  };

  const onBulkTransferClick = () => {
    setPanelMessageDetails("");
    setShowMessageBar(false);
    if (dashboardGridRef.current?.getSelection().items.length) {
      const items = dashboardGridRef.current?.getSelection().items as ScheduleJEResponseFromAPI[];
      var totalitems = items.length;
      var status = items.filter(
        (x) =>
          x?.recurringScheduleStatus?.toLowerCase() !== "draft" &&
          x?.recurringScheduleStatus?.toLowerCase() !== "active" &&
          (x?.additionalPosters?.toLowerCase()?.includes(userContext.user?.alias.toLowerCase()) ||
            x?.poster?.toLowerCase() == userContext.user?.alias.toLowerCase())
      );
      var user = items.filter(
        (x) =>
          !(
            x?.additionalPosters?.toLowerCase()?.includes(userContext.user?.alias.toLowerCase()) ||
            x?.poster?.toLowerCase() === userContext.user?.alias.toLowerCase()
          ) &&
          (x?.recurringScheduleStatus?.toLowerCase() === "draft" ||
            x?.recurringScheduleStatus?.toLowerCase() === "active")
      );
      const filteredItems = items.filter(
        (x) =>
          (x?.recurringScheduleStatus?.toLowerCase() === "draft" ||
            x?.recurringScheduleStatus?.toLowerCase() === "active") &&
          (x?.additionalPosters?.toLowerCase()?.includes(userContext.user?.alias.toLowerCase()) ||
            x?.poster?.toLowerCase() == userContext.user?.alias.toLowerCase())
      );
      if (user?.length > 0 || status?.length > 0) {
        let invaildStatusSchedule = status?.map((i) => i.recurringScheduleName).join(",") || "";
        let invaildUserSchedule = user?.map((i) => i.recurringScheduleName).join(",") || "";
        if (totalitems === user?.length + status?.length) {
          if (user?.length > 0 && status?.length > 0) {
            setPanelMessageDetails(
              "Bulk Transfer cannot be performed as you are not poster/additional poster or status is neither active nor draft for " +
                invaildStatusSchedule +
                "," +
                invaildUserSchedule
            );
            setShowMessageBar(true);
          } else if (user?.length > 0) {
            setPanelMessageDetails(
              "Bulk Transfer cannot be performed as you are not poster/additional poster for " + invaildUserSchedule
            );
            setShowMessageBar(true);
          } else if (status?.length > 0) {
            setPanelMessageDetails(
              "Bulk Transfer cannot be performed as status is neither active nor draft for " + invaildStatusSchedule
            );
            setShowMessageBar(true);
          }
          setPageState({
            ...pageState,
            openBulkTransferPanel: true,
            selectedRows: []
          });
        } else {
          if (user?.length > 0 && status?.length > 0) {
            setPanelMessageDetails(
              "Bulk Transfer cannot be performed as you are not poster/additional poster or status is neither active nor draft for " +
                invaildStatusSchedule +
                "," +
                invaildUserSchedule
            );
            setShowMessageBar(true);
          } else if (user?.length > 0) {
            setPanelMessageDetails(
              "Bulk Transfer cannot be performed as you are not poster/additional poster for " + invaildUserSchedule
            );
            setShowMessageBar(true);
          } else if (status?.length > 0) {
            setPanelMessageDetails(
              "Bulk Transfer cannot be performed as status is neither active nor draft for " + invaildStatusSchedule
            );
            setShowMessageBar(true);
          }
          setPageState({
            ...pageState,
            openBulkTransferPanel: true,
            selectedRows: filteredItems as ScheduleJEResponseFromAPI[]
          });
        }
      } else {
        setPageState({
          ...pageState,
          openBulkTransferPanel: true,
          selectedRows: filteredItems as ScheduleJEResponseFromAPI[]
        });
      }
    } else {
      setPageState({
        ...pageState,
        openBulkTransferPanel: false,
        selectedRows: []
      });
    }
  };

  return (
    <>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <h2>Recurring Schedule JE Dashboard</h2>
        </PageHeading>
        <ScheduleJEHeader currentDashboardType={currentDashboardType}></ScheduleJEHeader>
        {showMessage ? (
          <MessageBar
            delayedRender={false}
            messageBarType={messageDetails?.status === "Success" ? MessageBarType.success : MessageBarType.error}
            dismissButtonAriaLabel="Close"
            onDismiss={() => {
              setMessageDetails(null);
              setShowMessage(false);
            }}
          >
            {messageDetails?.message}
          </MessageBar>
        ) : null}
        {loadingStatus === LoadingStatus.Pending ? (
          <LoadingSpinner label={`Loading Recurring Schedule Details`} />
        ) : loadingStatus === LoadingStatus.Rejected ? (
          <Text
            variant="xLarge"
            style={{
              color: "var(--accent-font-color, black)"
            }}
          >
            Error Loading Recurring Schedule JE Details
          </Text>
        ) : (
          <>
            <DashboardFilter<ScheduleJEResponseFromAPI>
              filters={dashboardFilters}
              singleSelectionFilters={[]}
              searchEnabledFilters={["recurringScheduleName"]}
              onFilterChanged={filterItems}
              loadingStatus={LoadingStatus.Resolved}
              tilesAndFilters={{
                gridItems: gridItems,
                tiles: OrderedMap<string, IIndexedTile>({}),
                currentTile: ""
              }}
              location={location}
            />
            <DashboardGrid
              columnGenerator={columnGenerator}
              isSortedIndex={13}
              items={filteredItems}
              isSortedDescending={true}
              customRef={dashboardGridRef}
              height={`500px`}
              onRenderRow={(props, defaultRender) => {
                if (!defaultRender || !props) return null;
                return (
                  <div>
                    {defaultRender({
                      ...props
                    })}
                  </div>
                );
              }}
              selectionMode={SelectionMode.multiple}
              onSelectionChanged={(selection: Selection) => {
                if (dashboardActionsRef.current) {
                  const userSelectedItems = selection.getSelection();
                  dashboardActionsRef.current.refreshSelection(userSelectedItems as any[]);
                }
              }}
              onExport={(rows) => {
                exportToExcel({
                  sheetName: "recurringJESchedules",
                  rowsWithHeader: rows,
                  fileName: "recurringJESchedules.xlsx"
                });
              }}
              idForLocalStorage={"scheduleJEDetails"}
            >
              <DashboardListActions
                customRef={dashboardActionsRef}
                hide={currentTile === "all"}
                buttonCalculator={buttonCalculator}
                preventCollapse={true}
                initialState={actionItems}
                approve={() => openTerminateAssignedConfirmationPopup("approve")}
                needsClarification={() => openTerminateAssignedConfirmationPopup("reject")}
              ></DashboardListActions>
              {currentTile === "all" && (
                <ScheduleJECommandBar onBulkTransferClicked={onBulkTransferClick}></ScheduleJECommandBar>
              )}
            </DashboardGrid>
          </>
        )}
      </div>
      <CoherenceModal
        isOpen={openEditPopup}
        modalWidth="small"
        height="responsive"
        onDismiss={onCloseEditPopup}
        styles={{ root: { minHeight: "auto", maxHeight: "auto" } }}
      >
        <Stack>
          <div>
            Editing the schedule will set the status to Draft, only proceed if you wish to reset the schedule and pause
            scheduled posting. If you want to view details, click View.
          </div>
          <div>If underlying JE is in prereview submitted state - please use JE dashboard to recall and edit.</div>
        </Stack>
        <Stack>
          <Stack horizontal styles={{ root: { padding: "20px 0" } }} tokens={{ childrenGap: 30 }}>
            <PrimaryButton text="Yes" onClick={onEditConfirm} ariaLabel="Yes" />
            <DefaultButton text="No" onClick={onCloseEditPopup} ariaLabel="No" />
          </Stack>
        </Stack>
      </CoherenceModal>
      <ScheduleJETerminatePopup
        openTerminatePopup={openTerminatePopup}
        onCloseTerminatePopup={onCloseTerminatePopup}
        onTerminateConfirm={onTerminateConfirm}
      ></ScheduleJETerminatePopup>
      <ScheduleJETerminateApprovePopup
        openTerminatePopup={openTerminateAssignedPopup}
        onCloseTerminatePopup={onCloseTerminateAssignedPopup}
        onTerminateConfirm={(comments: string) => {
          actionType === "approve" ? approveSelectionedJE(comments) : rejectSelectionedJE(comments);
        }}
        actionType={actionType}
      ></ScheduleJETerminateApprovePopup>
      {pageState.openBulkTransferPanel && (
        <BulkTransfer
          selectedItems={pageState.selectedRows?.map((i) => i.recurringJEScheduleId).join(",") || ""}
          selectedItemsCount={pageState.selectedRows?.length || 0}
          loading={pageState.loadingBulkTransder}
          hideBulkEditModal={() =>
            setPageState({
              ...pageState,
              openBulkTransferPanel: false
            })
          }
          getFormInputs={getBulkTranferFormInputConfig}
          onSaveItem={async (bulkTransferInput: TaskBulkTransferInputDto) => {
            setPageState({
              ...pageState,
              loadingBulkTransder: true
            });
            console.log(bulkTransferInput);
            try {
              setMessageDetails(null);
              setShowMessage(false);
              false;
              const response = await postBulkTransfer(props.configuration, userContext.accessToken, bulkTransferInput);
              setPageState({
                ...pageState,
                loadingBulkTransder: false
              });
              setMessageDetails(response);
              setShowMessage(true);
              if (response.status === "Success") {
                setPageState({
                  ...pageState,
                  openBulkTransferPanel: false,
                  selectedRows: []
                });
              } else {
                setPageState({
                  ...pageState,
                  openBulkTransferPanel: false
                });
              }
            } catch (error) {
              setMessageDetails({
                status: "Fail",
                message: "One or more error occured while performing Bulk edit activity. Please try again."
              });
            }
          }}
          showMessage={showMessageBar}
          message={panelMessageDetails}
        />
      )}
    </>
  );
};

RecurringScheduleJEPage.displayName = "RecurringScheduleJEPage";

export default RecurringScheduleJEPage;
