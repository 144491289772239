import React from "react";
import { IColumn, IconButton } from "@fluentui/react";
import { GetLinkToDetailsFromChecklistItem, IExtendedColumn, onRenderArray } from "@jem/components";
import { Link, Location } from "react-router-dom";
import { JEMChecklistDetailsRow } from "@jem/components";
import isValid from "date-fns/isValid";

interface JEMChecklistDetailsColumn extends IExtendedColumn {
  fieldName: keyof Omit<JEMChecklistDetailsRow, "isDraft">;
}
interface IJemChecklistDetailsColumns {
  onEditClick?: (editItem: JEMChecklistDetailsRow) => void;
  onDeleteClick?: (editItem: JEMChecklistDetailsRow) => void;
  location: Location;
  disableAllButSignOff: () => boolean;
  IsFCCWIntegrationLive: boolean;
}

export const getJEMChecklistDetailsColumns =
  (props: IJemChecklistDetailsColumns) => (): JEMChecklistDetailsColumn[] => {
    const { onEditClick, onDeleteClick, location, IsFCCWIntegrationLive } = props;

    const cols: JEMChecklistDetailsColumn[] = [
      {
        key: "jeTaskName",
        name: "JE Task Name",
        fieldName: "jeTaskName",
        type: "string",
        minWidth: 115,
        onRender: (
          item?: JEMChecklistDetailsRow,
          _index?: number | undefined,
          _column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!item) return null;
          if (
            item.publishedState.indexOf("Published") === -1 ||
            !(item.publishedState.toLowerCase() === "Published".toLowerCase())
          )
            return item.jeTaskName;

          let url = null;
          if (item.href && item.href !== "") {
            const div = document.createElement("div");
            // replace #// with #/ to make it a valid url
            item.href = item.href.replace("#//", "#/");
            div.innerHTML = item.href;
            const a = div.querySelector("a");
            if (a) {
              const href = a.href;
              const jemHyperLinkUrl = new URL(href);
              const hash = jemHyperLinkUrl.hash;
              const jeIdParam = "jeId=";

              let jeId = null;
              if (hash && hash.indexOf(jeIdParam) !== -1) {
                const startIndex = hash.indexOf(jeIdParam) + jeIdParam.length;
                const endIndex = hash.indexOf("&", startIndex) !== -1 ? hash.indexOf("&", startIndex) : hash.length;
                jeId = hash.substring(startIndex, endIndex);
              }
              if (jeId) {
                url = `/gl/details?jeid=${jeId}`;
              }
            }
          }
          if (url === null) {
            if (item.jeType.indexOf("Batch") !== -1 || item.jeType.indexOf("Bulk") !== -1) {
              url = `/gl/batch?RefGuid=${item.refGuid}`;
            } else {
              url = `/gl/create?RefGuid=${item.refGuid}`;
            }
          }
          return (
            <Link
              to={url}
              state={{
                from: location
              }}
              replace
            >
              {item.jeTaskName}
            </Link>
          );
        }
      }
    ];

    if (onEditClick && onDeleteClick) {
      cols.push({
        key: "Actions",
        name: "Actions",
        fieldName: "refGuid",
        type: "string",
        minWidth: 30,
        onRender: (
          item: JEMChecklistDetailsRow,
          _index?: number | undefined,
          _column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          return (
            <>
              <IconButton
                iconProps={{ iconName: "Edit" }}
                title="Edit"
                ariaLabel="Edit Checklist"
                disabled={props.disableAllButSignOff() || !item.isTaskEditable}
                styles={{
                  root: {
                    padding: 0
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEditClick(item);
                }}
              />
              <IconButton
                iconProps={{ iconName: "Delete" }}
                title="Delete"
                ariaLabel="Delete Checklist"
                disabled={props.disableAllButSignOff() || !item.isTaskEditable}
                styles={{
                  root: {
                    padding: 0
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onDeleteClick(item);
                }}
              />
            </>
          );
        }
      });
    }
    // add the following cols too
    cols.push(
      {
        key: "taskId",
        name: "Task Id",
        fieldName: "taskId",
        type: "number",
        minWidth: 80,
        sortDescendingAriaLabel: "Sort by id"
      },
      {
        key: "jeType",
        name: "JE Type",
        fieldName: "jeType",
        type: "string",
        minWidth: 80,
        sortDescendingAriaLabel: "Sort by id"
      },
      {
        key: "taskType",
        name: "Task Type",
        fieldName: "taskType",
        type: "string",
        minWidth: 60,
        sortDescendingAriaLabel: "Sort by task type"
      }
    );
    if (!IsFCCWIntegrationLive) {
      cols.push(
        {
          key: "draftDateCode",
          name: "Draft Date Code",
          fieldName: "draftDateCode",
          type: "string",
          minWidth: 90
        },
        {
          key: "frequency",
          name: "Frequency",
          fieldName: "frequency",
          type: "string",
          minWidth: 80
        },
        {
          key: "estimatedTime",
          name: "Estimated Time (mins)",
          fieldName: "estimatedTimeMins",
          type: "number",
          minWidth: 130
        },
        {
          key: "preReviewers",
          name: "Pre Reviewers",
          fieldName: "preReviewers",
          type: "string",
          minWidth: 30
        },
        {
          key: "attachment",
          name: "Attachments",
          fieldName: "attachment",
          type: "string",
          minWidth: 80,
          sortDescendingAriaLabel: "Sort by attachment"
        },
        {
          key: "author",
          name: "Author",
          fieldName: "author",
          type: "string",
          minWidth: 80,
          sortDescendingAriaLabel: "Sort by author"
        },
        {
          key: "purpose",
          name: "Purpose",
          fieldName: "purpose",
          type: "string",
          minWidth: 30
        },
        {
          key: "description",
          name: "Description",
          fieldName: "description",
          type: "string",
          minWidth: 90
        }
      );
    }

    if (IsFCCWIntegrationLive) {
      cols.push(
        {
          key: "stagedTaskId",
          name: "Staged Task Id",
          fieldName: "stagedTaskId",
          type: "string",
          minWidth: 90
        },
        {
          key: "publishedTaskId",
          name: "Published Task ID",
          fieldName: "publishedTaskId",
          type: "string",
          minWidth: 90
        },
        {
          key: "dtpIdentifier",
          name: "DTP identifier",
          fieldName: "dtpIdentifier",
          type: "string",
          minWidth: 90
        }
      );
    }

    cols.push(
      {
        key: "actualTimeTaken",
        name: "Actual Time Taken (mins)",
        fieldName: "actualTimeTakenMins",
        type: "number",
        minWidth: 130
      },
      {
        key: "postingDueDate",
        name: "Posting Due Date",
        fieldName: "postingDueDate",
        type: "string",
        minWidth: 70,
        sortDescendingAriaLabel: "Sorted by posting due date",
        onRender: (item?: JEMChecklistDetailsRow) => {
          if (!item) return null;

          return item.postingDueDate && isValid(new Date(item.postingDueDate))
            ? new Date(item.postingDueDate).toLocaleDateString("en-US", {
                day: "numeric",
                month: "numeric",
                year: "numeric"
              })
            : "";
        }
      },
      {
        key: "significance",
        name: "Significance",
        fieldName: "significance",
        type: "string",
        minWidth: 60,
        sortDescendingAriaLabel: "Sorted by significance"
      },
      {
        key: "publishedState",
        name: "Published State",
        fieldName: "publishedState",
        type: "string",
        minWidth: 30
      },
      {
        key: "postingNotRequired",
        name: "Posting Not Required",
        fieldName: "postingNotRequired",
        type: "string",
        minWidth: 80,
        sortDescendingAriaLabel: "Sort by no activity reason"
      },
      {
        key: "assignedPoster",
        name: "Assigned Poster",
        fieldName: "assignedPoster",
        type: "string",
        minWidth: 30
      },
      {
        key: "backupPosters",
        name: "Backup Posters",
        fieldName: "backupPoster",
        type: "object",
        minWidth: 140,
        onRender: (item?: JEMChecklistDetailsRow) => {
          if (!item) return null;

          return onRenderArray(item.backupPoster);
        }
      },
      {
        key: "assignedReviewer",
        name: "Backup Reviewers",
        fieldName: "assignedReviewer",
        type: "object",
        minWidth: 140,
        onRender: (item?: JEMChecklistDetailsRow) => {
          if (!item) return null;

          return onRenderArray(item.assignedReviewer);
        }
      },
      {
        key: "status",
        name: "Status",
        fieldName: "status",
        type: "string",
        minWidth: 90
      },
      {
        key: "docNo",
        name: "Doc Num",
        fieldName: "docNo",
        type: "string",
        minWidth: 80,
        onRender: (
          item?: JEMChecklistDetailsRow,
          _index?: number | undefined,
          _column?: IColumn | undefined
        ): JSX.Element | React.ReactText | null => {
          if (!item) return null;
          const urlParts = GetLinkToDetailsFromChecklistItem(item);

          if (!urlParts) return null;
          const { url, stringContent } = urlParts;
          if (!url) return stringContent;

          return <Link to={url}>{stringContent}</Link>;
        }
      },
      {
        key: "reasonCode",
        name: "Reason Code",
        fieldName: "reasonCodeText",
        type: "string",
        minWidth: 70,
        sortDescendingAriaLabel: "Sort by reason code"
      },
      {
        key: "datemodified",
        name: "Date Modified",
        fieldName: "datemodified",
        type: "string",
        minWidth: 60,
        sortDescendingAriaLabel: "Sorted by date modified"
      },
      {
        key: "period",
        name: "Period",
        fieldName: "postingPeriod",
        type: "string",
        minWidth: 90
      },
      {
        key: "postingdate",
        name: "Posting Date",
        fieldName: "postingdate",
        type: "number",
        minWidth: 30
      },
      {
        key: "translationDate",
        name: "Translation Date",
        fieldName: "translationDate",
        type: "string",
        minWidth: 90
      },
      {
        key: "documentType",
        name: "Document Type",
        fieldName: "documentType",
        type: "string",
        minWidth: 30
      },
      {
        key: "companycode",
        name: "Company Code",
        fieldName: "companycode",
        type: "object",
        minWidth: 60,
        onRender: (item?: JEMChecklistDetailsRow) => {
          if (!item) return null;

          return onRenderArray(item.companycode.map(String));
        }
      },
      {
        key: "referenceNumber",
        name: "Reference Number",
        fieldName: "referenceNumber",
        type: "number",
        minWidth: 90
      },
      {
        key: "currencycode",
        name: "Currency Code",
        fieldName: "currencycode",
        type: "number",
        minWidth: 30
      },
      {
        key: "noActivityComments",
        name: "No Activity Comments",
        fieldName: "noActivityComments",
        type: "string",
        minWidth: 90
      },
      {
        key: "isF05PostingString",
        name: "Is F05 Posting",
        fieldName: "isF05PostingString",
        type: "string",
        minWidth: 90
      }
    );
    return cols;
  };
