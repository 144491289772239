import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import React, { useEffect } from "react";
import ActionButtons from "../../copiedItems/ActionButtons";
import { JEMChecklistDetailsRow, LocalMessage } from "@jem/components";
import { Controller, useForm } from "react-hook-form";
import {
  Stack,
  Label,
  FocusZone,
  DetailsList,
  Text,
  DetailsListLayoutMode,
  Dropdown,
  IDropdownOption,
  TextField,
  MessageBar,
  MessageBarType
} from "@fluentui/react";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";

interface IJEMChecklistDetailsNoActivityPanelProps {
  checklistItems: JEMChecklistDetailsRow[];
  checklistName: string;
  checklistPeriod: string;
  checklistRefGUID: string;
  onClosePanel: () => void;
  onSaveItem: (
    checklistItems: JEMChecklistDetailsRow[] | [],
    noActivityReason: IDropdownOption,
    comments: string
  ) => void;
  loading?: boolean;
  error: string[];
  noActivityReasonOptionsFromAPI: IDropdownOption[];
  getNoActivityReasonOptions: () => void;
  IsFCCWIntegrationLive: boolean;
}

const JEMChecklistDetailsNoActivityPanel = (props: IJEMChecklistDetailsNoActivityPanelProps): JSX.Element => {
  const options: string[] = [
    "JE not needed this period as per agreed frequency",
    "No activity this period",
    "Late entry request not approved by FC",
    "Late entry request not approved by Corp",
    "Other",
    "Posted as batch JE"
  ];

  const noActivityReasonOptions: IDropdownOption[] = options.map((item) => {
    return {
      key: item,
      text: item
    };
  });

  const {
    checklistItems,
    onClosePanel,
    onSaveItem,
    error,
    loading,
    getNoActivityReasonOptions,
    noActivityReasonOptionsFromAPI,
    IsFCCWIntegrationLive
  } = props;
  const form = useForm();
  const { control, errors, handleSubmit, getValues, setValue } = form;

  const [message, setMessage] = React.useState<LocalMessage | null>(null);
  const [manualChecklistItems, setManualChecklistItems] = React.useState<JEMChecklistDetailsRow[]>(checklistItems);
  const [noActivityReasonObj, setNoActivityReasonObj] = React.useState<IDropdownOption<any> | undefined>(undefined);
  const _columns = [
    { key: "column1", name: "JETaskName", fieldName: "jeTaskName", minWidth: 100, maxWidth: 200, isResizable: true },
    {
      key: "column2",
      name: "PublishedState",
      fieldName: "publishedState",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  useEffect(() => {
    if (IsFCCWIntegrationLive) {
      const nileJes = checklistItems.filter((item) => !item.isTaskEditable);
      const manualJes = checklistItems.filter((item) => item.isTaskEditable);
      if (nileJes.length > 0) {
        setMessage({
          type: manualJes.length ? MessageBarType.warning : MessageBarType.error,
          message: `This action can be performed only on ${manualJes.length} records. ${nileJes.length} records are ineligible.`
        });
      }
      setManualChecklistItems(manualJes);
    }
  }, [checklistItems]);

  const onHandleSubmit = () => {
    const { comments } = getValues();
    if (noActivityReasonObj) {
      onSaveItem(manualChecklistItems, noActivityReasonObj, comments);
    }
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText="Mark as No Activity for JE Tasks"
      isOpen
      onDismiss={onClosePanel}
      hasCloseButton
      closeButtonAriaLabel="Close No Activity JE Tasks"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          disabled={manualChecklistItems.length == 0 || loading}
          mutationLoading={false}
          closePanel={onClosePanel}
          handleSubmit={handleSubmit(onHandleSubmit)}
          saveLabel="No Activity"
          saveTitle="No Activity"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={error} />
      <form onSubmit={onHandleSubmit}>
        <Stack tokens={{ childrenGap: 20 }}>
          {message && (
            <MessageBar
              messageBarType={message.type}
              isMultiline={true}
              onDismiss={() => setMessage(null)}
              dismissButtonAriaLabel="Close"
            >
              {message.message}
            </MessageBar>
          )}
          <span></span>
          <Label> Checklist {props.checklistName}</Label>
          <Label>Posting Period {props.checklistPeriod}</Label>

          <Controller
            label="No Activity Reason"
            id="noActivityReason"
            name="noActivityReason"
            defaultValue={undefined}
            value={undefined}
            control={control}
            resizable={true}
            rules={{
              required: "No Activity reason is required"
            }}
            render={({ value }) => {
              return (
                <Dropdown
                  required
                  id="noActivityReason"
                  label="No Activity Reason"
                  placeholder="Select an option"
                  options={IsFCCWIntegrationLive ? noActivityReasonOptionsFromAPI : noActivityReasonOptions}
                  selectedKey={value}
                  onFocus={IsFCCWIntegrationLive ? getNoActivityReasonOptions : undefined}
                  errorMessage={errors?.noActivityReason?.message}
                  onChange={(_e: any, newValue: IDropdownOption<any> | undefined): void => {
                    setValue("noActivityReason", newValue?.key);
                    setNoActivityReasonObj(newValue);
                  }}
                />
              );
            }}
          />
          <Controller
            as={TextField}
            label="Comments:"
            id="comments"
            name="comments"
            control={control}
            resizable={false}
            defaultValue={""}
            value={""}
            onChange={(e: any, newValue: string | undefined): void => {
              setValue("comments", newValue);
            }}
            placeholder="If selected Other as no activiy reason, please specify the reason in comments here"
            errorMessage={errors?.noActivityReason?.message}
            multiline
            rows={2}
          />

          {manualChecklistItems.length > 0 ? (
            <>
              <Text
                variant="xLarge"
                style={{
                  color: "var(--accent-font-color, black)"
                }}
              >
                Checklist JE Tasks to be marked as No Activity {/*{checklistItemsJeTasks}*/}
              </Text>
              <FocusZone>
                <div>
                  <DetailsList
                    items={manualChecklistItems}
                    columns={_columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                  />
                </div>
              </FocusZone>
            </>
          ) : (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              No Items to show.
            </Text>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default JEMChecklistDetailsNoActivityPanel;
