import {
  JemConfiguration,
  getValidUrl,
  TraceResponseFromAPI,
  IUserProviderState,
  postRequest,
  IExtendedColumn,
  PostRequestModel,
  FileDetails
} from "@jem/components";
import React from "react";
import { IColumn, Link, IconButton } from "@fluentui/react";


export async function getTraceDetails(

  configuration: JemConfiguration["OpsDashboardApi"],
  getTokenFn: IUserProviderState["accessToken"],
  
  data: PostRequestModel
): Promise<TraceResponseFromAPI | null> {
  const endpoint = `${configuration.baseApiUrl}${configuration.endpoints.getSapDetails}`;
  const endpointUrl = getValidUrl(endpoint);
  let gridData: TraceResponseFromAPI | null =null;
  const payload: PostRequestModel = data;
  gridData = await postRequest<TraceResponseFromAPI>(endpointUrl, payload, getTokenFn);
  if(!gridData)
  {
    return null;
  }
  return gridData;
}
const downloadRequestFile = (data: FileDetails) => {
  const blobUrl = data.requestFilePath; 
  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute("download", "Request.json");
  document.body.appendChild(link);
  link.dispatchEvent(new MouseEvent("click"));
  URL.revokeObjectURL(blobUrl);
  if (link.parentNode) link.parentNode.removeChild(link);
};
const downloadResponseFile = (data: FileDetails) => {
  const blobUrl = data.responseFilePath; 
  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute("download", "Response.json");
  document.body.appendChild(link);
  link.dispatchEvent(new MouseEvent("click"));
  URL.revokeObjectURL(blobUrl);
  if (link.parentNode) link.parentNode.removeChild(link);
};
export const getTraceDetailsColumns = () => (): IExtendedColumn[] => {
  const cols: IExtendedColumn[] = [
    {
      key: "originalGuid",
      name: "Request Id",
      fieldName: "originalGuid",
      type: "string",
      minWidth: 220
    },
    {
      key: "sapCorrelationId",
      name: "SAP Correlation Id",
      fieldName: "sapCorrelationId",
      type: "string",
      minWidth: 225
    },
    {
      key: "action",
      name: "Action",
      fieldName: "action",
      type: "string",
      minWidth: 60
    },
    {
      key: "sequenceNumber",
      name: "Seq Number",
      fieldName: "sequenceNumber",
      type: "string",
      minWidth: 60
    },
    {
      key: "requestdateTime",
      name: "Request DateTime",
      fieldName: "requestdateTime",
      type: "string",
      minWidth: 160
    },
    {
      key: "responsedateTime",
      name: "Response DateTime",
      fieldName: "responsedateTime",
      type: "string",
      minWidth: 160
    },
    {
      key: "duration",
      name: "Duration",
      fieldName: "duration",
      type: "string",
      minWidth: 150
    },
    {
      key: "requestFilePath",
      name: "Payload",
      fieldName: "requestFilePath",
      type: "string",
      minWidth: 202,
      onRender: (
                data?: FileDetails,
                index?: number | undefined,
                column?: IColumn | undefined
              ): JSX.Element | React.ReactText | null => {
                if (!column || !data) {
                  return null;
                }
                return (
                  <><Link
                    onClick={() => {
                      if (data.requestFilePath) {
                        downloadRequestFile(data);
                      }
                    } }
                  >
                   Request
                  </Link>
                  <IconButton iconProps={{ iconName: "Download" }} /> <span>, </span>
                  <Link
                    onClick={() => {
                      if (data.responseFilePath) {
                        downloadResponseFile(data);
                      }
                    } }
                  >
                      Response
                    </Link>
                    <IconButton iconProps={{ iconName: "Download" }} />
                    </>
                );
              }
            }
  ];
  return cols;
};

export const getActionTraceDetailsColumns = () => (): IExtendedColumn[] => {
  const cols: IExtendedColumn[] = [
    {
      key: "guid",
      name: "GUID",
      fieldName: "guid",
      type: "string",
      minWidth: 220
    },
    {
      key: "userName",
      name: "User Name",
      fieldName: "userName",
      type: "string",
      minWidth: 100,
    },
    {
      key: "timestamp",
      name: "Action Date",
      fieldName: "timestamp",
      type: "string",
      minWidth: 160
    },
    {
      key: "action",
      name: "Action Name",
      fieldName: "action",
      type: "string",
      minWidth: 100
    },
    {
      key: "message",
      name: "Message",
      fieldName: "message",
      type: "string",
      minWidth: 450
    }
  ];
  return cols;
};