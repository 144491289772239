import { PrimaryButton, TextField, Text, Label, CheckboxVisibility } from "@fluentui/react";
import {
  DashboardGrid,
  FatalError,
  IDashboardGridRef,
  JemConfiguration,
  LoggingContext,
  MockDataFn,
  PageHeading,
  PageStyles,
  PostRequestModel,
  UserContext,
  LoadingStatus,
  LoadingSpinner,
  FileDetails,
  ActivityLogDetails
} from "@jem/components";
import React, { useContext, useRef, useState } from "react";
import { getTraceDetails, getTraceDetailsColumns, getActionTraceDetailsColumns } from "../../components/TraceDashboard/TraceDashboard";

export interface TraceDashboardProps {
  configuration: JemConfiguration["OpsDashboardApi"];
  mockDashboardDataFn?: MockDataFn<unknown>;
}

const TracePage: React.FC<TraceDashboardProps> = (props) => {
  const { appInsights } = useContext(LoggingContext);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }
  const [inputSearchValue, setInputSearchValue] = useState("");
  const userContext = useContext(UserContext);
  const [gridItems, setGridItems] = useState<FileDetails[]>([]);
  const [activityGridItems, setActivityGridItems] = useState<ActivityLogDetails[]>([]);
  const [dashboardGridStatus, setDashboardGridStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
  const dashboardGridRef = useRef<IDashboardGridRef>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [gridErrorMessage, setGridErroMessage] = useState("");
  const [sapgridErroressage, setsapgridErroressage] = useState("");
  
  const getTraceDetailsAPI = async (requestGUID: string) => {
    setDashboardGridStatus(LoadingStatus.Pending);
    setErrorMessage("");
    setGridErroMessage("");
    setsapgridErroressage("");
    const requestpayload: PostRequestModel={
      guid: requestGUID,
      folderNames: [
        "Post",
        "Validate"
      ]
    };

    const items = await getTraceDetails(props.configuration, userContext.accessToken, requestpayload);
    if(!items)
    {
      let errorMessage = "Error while getting Details";
      setErrorMessage(errorMessage);
      setGridErroMessage("");
    }
    if(items!=null && items.payload!=null && items.payload.length >0)
    {
      if(items.activityLogs==null || (items.activityLogs!=null && items.activityLogs.length <= 0))
        {
          setsapgridErroressage("No Action Details found");
        }
      setGridItems(items.payload);
      setErrorMessage(items.errorMessage);
      setGridErroMessage("");
    }
    if(items!=null && items.activityLogs!=null && items.activityLogs.length >0)
      {
        if(items.payload==null)
        {
          setGridErroMessage("No payload found");
        }
        setActivityGridItems(items.activityLogs);
        setErrorMessage(items.errorMessage);
      }
    if(items!=null && items.errorMessage)
    {
      setErrorMessage(items.errorMessage);
    }
    setDashboardGridStatus(LoadingStatus.Resolved);  
  };

  return (
    <>
      <div className={PageStyles.rootDiv}>
        <PageHeading>
          <h2>Trace Dashboard</h2>
        </PageHeading>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <div className="ms-Grid-row" style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  className="ms-Grid-col ms-sm12 ms-md6 ms-lg3 ms-xl3 ms-xxl2"
                  label="GUID"
                  value={inputSearchValue}
                  onChange={(e: any) => {
                    setInputSearchValue(e.target.value);
                  }}
                  description="Enter correct GUID"
                />
                <PrimaryButton
                  text="Search"
                  allowDisabledFocus
                  onClick={() => {
                    getTraceDetailsAPI(inputSearchValue);
                  }}
                  style={{ top: "5px" }}
                  type="submit"
                  disabled={false}
                />
              </div>
            </div>
          </div>
          {errorMessage ? ( 
                  <Text 
                    variant="xLarge"
                    style={{
                      color: "var(--accent-font-color, black)"
                    }}
                  >
                    {errorMessage}
                  </Text>
                ) : dashboardGridStatus === LoadingStatus.Resolved ? gridErrorMessage ? ( 
                  <><Text
                variant="xLarge"
                style={{
                  color: "var(--accent-font-color, black)"
                }}
              >
                <Label styles={{ root: { marginTop: 10 } }}>PayLoad Details</Label>
                {gridErrorMessage}
              </Text>
              <Label styles={{ root: { marginTop: 10 } }}>Action Details</Label>
              <DashboardGrid
                idForLocalStorage={"actionTraceDetails"}
                columnGenerator={getActionTraceDetailsColumns()}
                isSortedIndex={2}
                items={activityGridItems}
                isSortedDescending={false}
                customRef={dashboardGridRef}
                height={`250px`}
                showCheckBox={CheckboxVisibility.hidden}
              ></DashboardGrid></>  
                ) : sapgridErroressage ? ( 
                  <><><Label styles={{ root: { marginTop: 10 } }}>PayLoad Details</Label>
                  <DashboardGrid
                    idForLocalStorage={"traceDetails"}
                    columnGenerator={getTraceDetailsColumns()}
                    isSortedIndex={4}
                    items={gridItems}
                    isSortedDescending={false}
                    customRef={dashboardGridRef}
                    height={`250px`}
                    showCheckBox={CheckboxVisibility.hidden}
                  ></DashboardGrid></><Label styles={{ root: { marginTop: 10 } }}>Action Details</Label>
                  <Text 
                  variant="xLarge"
                  style={{
                    color: "var(--accent-font-color, black)"
                  }}
                >
                  {sapgridErroressage}
                </Text></> 
                ) : (
                  <><>
                  <Label styles={{ root: { marginTop: 10 } }}>PayLoad Details</Label>
                  <DashboardGrid
                    idForLocalStorage={"traceDetails"}
                    columnGenerator={getTraceDetailsColumns()}
                    isSortedIndex={4}
                    items={gridItems}
                    isSortedDescending={false}
                    customRef={dashboardGridRef}
                    height={`250px`}
                    showCheckBox={CheckboxVisibility.hidden}
                  ></DashboardGrid>
                </><>
                    <Label styles={{ root: { marginTop: 10 } }}>Action Details</Label>
                    <DashboardGrid
                      idForLocalStorage={"actionTraceDetails"}
                      columnGenerator={getActionTraceDetailsColumns()}
                      isSortedIndex={2}
                      items={activityGridItems}
                      isSortedDescending={false}
                      customRef={dashboardGridRef}
                      height={`250px`}
                      showCheckBox={CheckboxVisibility.hidden}
                    ></DashboardGrid>
                  </></>
                ) : dashboardGridStatus === LoadingStatus.Pending ? <LoadingSpinner label="Loading Requests" /> :(
                  null
                )}
        </div>
      </div>
    </>
  );
};

TracePage.displayName = "TraceDashboardPage";

export default TracePage;