import React from "react";
import { useLocation } from "react-router-dom";

import { EUARoles, JemUserRoles } from "../../contexts/UserContext/UserContext.roles.main";
import { ILinks } from "./AppNav";
import { AppNavProps } from "./AppNav.types";

export const OpsDashboardLinks = (
  location: ReturnType<typeof useLocation>,
  onClickHandler: (route: string) => (event: React.MouseEvent | any) => void,
  props: AppNavProps,
  jemUserRoles: JemUserRoles
) => {
  const links: Omit<ILinks, "icon">[] = [];
  if ("OpsDashboardApi" in props.configuration && jemUserRoles.roles.includes(EUARoles.ToolAdministrator)) {
    links.push({
      name: "Dashboard",
      key: "opsDashboard",
      ariaLabel: "Operations Dashboard",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/ops/dashboard"),
      isSelected: location.pathname === "/ops/dashboard"
    });
    links.push({
      name: "Tenant Dashboard",
      key: "tenantDashboard",
      ariaLabel: "Tenant Dashboard",
      target: "_blank",
      onClick: onClickHandler("/ops/tenantdashboard"),
      isSelected: location.pathname === "/ops/tenantdashboard"
    });
    links.push({
      name: "Trace",
      key: "trace",
      ariaLabel: "Trace",
      target: "_blank",
      onClick: onClickHandler("/ops/tracedashboard"),
      isSelected: location.pathname === "/ops/tracedashboard"
    });
    links.push({
      name: "User Dashboard",
      key: "userDashboard",
      ariaLabel: "User Dashboard",
      target: "_blank",
      onClick: onClickHandler("/ops/userdashboard"),
      isSelected: location.pathname === "/ops/userdashboard"
    });
    links.push({
      name: "Recon",
      key: "reconProcess",
      ariaLabel: "Recon Process",
      target: "_blank",
      onClick: onClickHandler("/ops/recon"),
      isSelected: location.pathname === "/ops/recon"
    });
  }
  if ("ApiTesterApi" in props.configuration) {
    links.push({
      name: "ApiTester",
      key: "apiTester",
      ariaLabel: "ApiTester",
      // "data-id": "automation_id_22235",
      target: "_blank",
      onClick: onClickHandler("/ops/apitester"),
      isSelected: location.pathname === "/ops/apitester"
    });
  }
  return {
    name: "Operations",
    key: "ops",
    ariaLabel: "Operations",
    icon: "BuildDefinition",
    // "data-id": "automation_id_22235",
    target: "_blank",
    links
  };
};
