import React, { useEffect, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";

interface IScheduleJEHeaderProps {
  currentDashboardType: (currentTile: string) => void;
}

const ScheduleJEHeader: React.FC<IScheduleJEHeaderProps> = (props) => {
  const [headerType, setHeaderType] = useState<string>("all");

  useEffect(() => {
    props.currentDashboardType(headerType);
  }, [headerType]);

  return (
    <React.Fragment>
      <Pivot
        onLinkClick={(item?: PivotItem | undefined) => {
          if (item && item.props.itemKey) {
            setHeaderType(item.props.itemKey);
          }
        }}
        selectedKey={headerType}
      >
        <PivotItem itemKey={"all"} headerText="All" ariaLabel="All">
          <div style={{ display: "none" }}></div>
        </PivotItem>
        <PivotItem
          itemKey={"terminationPending"}
          headerText="Termination Pending Approval"
          ariaLabel="Termination Pending"
        >
          <div style={{ display: "none" }}></div>
        </PivotItem>
      </Pivot>
    </React.Fragment>
  );
};

ScheduleJEHeader.displayName = "ScheduleJEHeader";
export default ScheduleJEHeader;
