import { CoherenceModal } from "@coherence-design-system/controls";
import { DefaultButton, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { TextFieldCheckboxSubmission } from "@jem/components";
import React, { useState } from "react";

interface IScheduleJETerminatePopupProps {
  openTerminatePopup: boolean;
  onCloseTerminatePopup: () => void;
  onTerminateConfirm: (comment: string) => void;
  actionType?: string;
}

export const ScheduleJETerminatePopup = (props: IScheduleJETerminatePopupProps) => {
  const { openTerminatePopup, onCloseTerminatePopup, onTerminateConfirm } = props;
  const [terminationComment, setTerminationComment] = useState<string>("");

  return (
    <CoherenceModal
      isOpen={openTerminatePopup}
      modalWidth="small"
      height="responsive"
      onDismiss={onCloseTerminatePopup}
      styles={{ root: { minHeight: "auto", maxHeight: "auto" } }}
    >
      <Stack>
        <div>
          Are you sure you want to terminate this schedule? Ensure prereviewers are active on this schedule before
          requesting Termination approval
        </div>
        <TextField
          name="terminationComment"
          label="Comments"
          value={terminationComment}
          onChange={(_, newValue) => setTerminationComment(newValue || "")}
          multiline
          autoAdjustHeight
          rows={3}
          styles={{
            root: {
              marginTop: "10px",
              marginBottom: "10px"
            }
          }}
          required
        />
      </Stack>
      <Stack>
        <Stack horizontal styles={{ root: { padding: "20px 0" } }} tokens={{ childrenGap: 30 }}>
          <PrimaryButton
            text="Yes"
            onClick={() => {
              onTerminateConfirm(terminationComment);
              setTerminationComment("");
            }}
            ariaLabel="Yes"
            disabled={terminationComment.length === 0}
          />
          <DefaultButton
            text="No"
            onClick={() => {
              onCloseTerminatePopup();
              setTerminationComment("");
            }}
            ariaLabel="No"
            disabled={terminationComment.length === 0}
          />
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};

export const ScheduleJETerminateApprovePopup = (props: IScheduleJETerminatePopupProps) => {
  const { openTerminatePopup, onCloseTerminatePopup, onTerminateConfirm, actionType } = props;

  return (
    <CoherenceModal
      isOpen={openTerminatePopup}
      modalWidth="small"
      height="responsive"
      title={`${actionType === "approve" ? "Approve" : "Reject"}`}
      onDismiss={onCloseTerminatePopup}
      styles={{ root: { minHeight: "auto", maxHeight: "auto" } }}
    >
      <TextFieldCheckboxSubmission
        onSubmit={async function (type: "submit" | "cancel", comment: string): Promise<void> {
          if (type === "cancel") {
            onCloseTerminatePopup();
          } else {
            onTerminateConfirm(comment);
          }
        }}
        submitButtonText={`${actionType === "approve" ? "Approve" : "Reject"}`}
        textFieldLabel={"Comments"}
        checkboxText={"I am accepting the responsibility for these entries."}
      ></TextFieldCheckboxSubmission>
    </CoherenceModal>
  );
};
