import { ICommandBarItemProps } from "@fluentui/react";
import { JEMChecklistDetailsRow } from "@jem/components";
export interface IJemChecklistDetailsCommandBarItemProps {
  onEditClick: (editItem: JEMChecklistDetailsRow) => void;
  onChecklistCloneClicked: () => void;
  onPublishChecklistClicked: () => void;
  onImportTasksClicked: () => void;
  onNoActivityChecklistClicked: () => void;
  onRefreshChecklistDetailsClicked: () => void;
  onBulkDeleteChecklistClicked: () => void;
  onBulkEditClicked: () => void;
  onSignOffClicked: () => void;
  disableSignOff: () => boolean;
  onReopenClicked: () => void;
  onConvertNiletoManualClicked: () => void;
  disableReopen: () => boolean;
  disableAllButSignOff: () => boolean;
  checkListGUID: string;
  backupReviewers: string[];
  backupPosters: string[];
  companyCodes: number[];
  IsFCCWIntegrationLive: boolean;
}

const JemChecklistDetailsCommandBarItems = (props: IJemChecklistDetailsCommandBarItemProps): ICommandBarItemProps[] => {
  const cols: ICommandBarItemProps[] = [];
  if (!props.IsFCCWIntegrationLive) {
    cols.push({
      key: "newJETask",
      text: "New JE Task",
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Add" },
      disabled: props.disableAllButSignOff(),
      onClick: () =>
        props.onEditClick(
          emptyJemChecklistDetailsRowObject(props.checkListGUID, props.backupReviewers, props.backupPosters)
        )
    });
  }
  cols.push(
    {
      key: "editBulk",
      text: "Bulk edit",
      cacheKey: "editBulk",
      iconProps: { iconName: "Edit" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onBulkEditClicked()
    },
    {
      key: "refresh",
      text: "Refresh Data",
      iconProps: { iconName: "Refresh" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onRefreshChecklistDetailsClicked()
    }
  );
  if (!props.IsFCCWIntegrationLive) {
    cols.push({
      key: "cloneJEMChecklist",
      text: "Clone JEM Checklist",
      iconProps: { iconName: "Copy" },
      //disabled: props.disableAllButSignOff(),
      onClick: () => props.onChecklistCloneClicked()
    });
  }
  cols.push({
    key: "publishJEMChecklist",
    text: "Publish JEM Checklist",
    iconProps: { iconName: "PublishContent" },
    disabled: props.disableAllButSignOff(),
    onClick: () => props.onPublishChecklistClicked()
  });
  if (!props.IsFCCWIntegrationLive) {
    cols.push({
      key: "importChecklistTasks",
      text: "Import Tasks",
      iconProps: { iconName: "ExcelLogo" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onImportTasksClicked()
    });
  }
  cols.push({
    key: "NoActivityJEMChecklist",
    text: "Mark No Activity",
    iconProps: { iconName: "DeactivateOrders" },
    disabled: props.disableAllButSignOff(),
    onClick: () => props.onNoActivityChecklistClicked()
  });
  if (!props.IsFCCWIntegrationLive) {
    cols.push({
      key: "bulkDeleteJEMChecklist",
      text: "Bulk Delete Tasks",
      iconProps: { iconName: "Delete" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onBulkDeleteChecklistClicked()
    });
  }
  cols.push(
    {
      key: "signOffJEMChecklist",
      text: "Sign Off Checklist",
      iconProps: { iconName: "CheckMark" },
      onClick: () => props.onSignOffClicked(),
      disabled: props.disableSignOff()
    },
    {
      key: "reopenJEMChecklist",
      text: "Re-open Checklist",
      iconProps: { iconName: "SkypeCircleArrow" },
      onClick: () => props.onReopenClicked(),
      disabled: props.disableReopen()
    }
  );
  if (props.IsFCCWIntegrationLive) {
    cols.push({
      key: "convertNiletoManual",
      text: "Convert to manual",
      iconProps: { iconName: "PublishContent" },
      disabled: props.disableAllButSignOff(),
      onClick: () => props.onConvertNiletoManualClicked()
    });
  }
  return cols;
};

const emptyJemChecklistDetailsRowObject = (
  GUID: string,
  backupReviewers: string[],
  backupPosters: string[]
): JEMChecklistDetailsRow => {
  const c: JEMChecklistDetailsRow = {
    taskId: "",
    jeTaskName: "",
    jeType: "",
    backupPoster: backupPosters,
    checklist: "",
    postingDueDate: "",
    significance: "",
    publishedState: "",
    status: "",
    assignedPoster: "",
    docNo: "",
    assignedReviewer: backupReviewers,
    attachment: "",
    fcwChecklistName: "",
    author: "",
    reasonCode: "",
    datemodified: "",
    purpose: "",
    postingPeriod: "",
    postingdate: "",
    translationDate: "",
    documentType: "",
    companycode: [],
    referenceNumber: 0,
    currencycode: "",
    description: "",
    refGuid: "",
    checklistRefGuid: GUID,
    href: "",
    reasonCodeText: "",
    isCloneable: true,
    isAdHocDraft: false,
    postingNotRequired: "",
    taskType: "",
    opsDetailId: -1,
    postedBy: "",
    frequency: "",
    draftDateCode: "",
    estimatedTimeMins: 0,
    actualTimeTakenMins: 0,
    draftDateCodeObject: undefined,
    signoffStatus: "",
    preReviewers: "",
    noActivityComments: "",
    isF05Posting: false,
    isF05PostingString: "false",
    stagedTaskId: "",
    publishedTaskId: "",
    dtpIdentifier: ""
  };
  return c;
};
export default JemChecklistDetailsCommandBarItems;
