/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDropdownOption, ITextFieldProps, TextField } from "@fluentui/react";
import { FormEvent } from "react";
import { FieldValue, FieldValues } from "react-hook-form";
import {
  JEMAliasPicker,
  JEMAliasPickerProps,
  CompanyCodeMultiSelectorProps,
  IFrequencyProps,
  DraftDateCodeDropdownProps
} from "@jem/components";

export interface ITaskBulkTransferInputChoice extends IDropdownOption {
  component: any; //(props: any) => React.FC; //Fc
  props?: () =>
    | JEMAliasPickerProps
    | Omit<CompanyCodeMultiSelectorProps, "defaultValue">
    | ITextFieldProps
    | IFrequencyProps
    | DraftDateCodeDropdownProps;
  isMultiValue?: boolean;
}

export const getBulkTranferFormInputConfig = (
  setValue: <TFieldName extends string, TFieldValues extends FieldValues>(
    name: TFieldName,
    value: FieldValue<TFieldValues>
  ) => void
): ITaskBulkTransferInputChoice[] => {
  return [
    {
      key: "newPoster",
      text: "New Poster (user)",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("newPoster", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    },
    {
      key: "backupPosters",
      text: "Backup Posters",
      component: JEMAliasPicker,
      props: () => {
        return {
          hideLabel: true,
          onChange: (newBackupPosters) => {
            setValue("backupPosters", newBackupPosters);
          }
        } as JEMAliasPickerProps;
      },
      isMultiValue: true
    },

    {
      key: "additionalReviewer",
      text: "Additional Reviewer (active users)",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("additionalReviewer", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    }
  ];
};
