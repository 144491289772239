/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FatalError,
  FCCWTaskProperty,
  GLCreateForm,
  GLCreateQueryStringParameters,
  JemConfiguration,
  JEMContext,
  LoadingStatus,
  LoggingContext,
  MockDataFn,
  ObjectKeys,
  UserContext
} from "@jem/components";

import React, { useContext, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import GLCreateRecurringJeHistoryPanel from "../../components/GLCreate/GLCreateRecurringJeHistoryPanel";
import { getTaskPropertyFromFCCW } from "../../../JEMChecklist/components/JEMChecklistDetails/JEMChecklistDetails.Requests";

export interface GLCreateProps {
  configuration: JemConfiguration;
  mockDashboardDataFn?: MockDataFn<any>;
  templateId?: string;
}

const initialQueryStringValues: Readonly<GLCreateQueryStringParameters> = Object.freeze({
  RefGuid: "",
  Edit: "1",
  Review: "1",
  Templateid: "1"
});

function getQueryStringValues(searchParams: URLSearchParams): GLCreateQueryStringParameters {
  const initialQueryString = { ...initialQueryStringValues };
  for (const keyName of ObjectKeys(initialQueryString)) {
    let value = searchParams.get(keyName);
    value = !value || value.length === 0 ? null : value;
    if (keyName.toLowerCase() === "refguid" && initialQueryString.RefGuid !== value) {
      initialQueryString.RefGuid = value || "";
    }
    if (keyName.toLowerCase() === "templateid" && initialQueryString.Templateid !== value) {
      initialQueryString.Templateid = value || "1";
    }
    if (keyName.toLowerCase() === "edit" && initialQueryString.Edit !== value) {
      initialQueryString.Edit = value || "1";
    }
    if (keyName.toLowerCase() === "review" && initialQueryString.Review !== value) {
      initialQueryString.Review = value || "1";
    }
  }
  return initialQueryString;
}

const GLCreate: React.FC<GLCreateProps> = (props) => {
  const [searchParams] = useSearchParams();
  const { appInsights } = useContext(LoggingContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [openHistoryPanel, setOpenHistoryPanel] = useState<boolean>(false);
  const [historyPanelScheduleId, setHistoryPanelScheduleId] = useState<string | number>("");

  // string - a valid refguid
  // null - no refguid in search params (new batch)
  // undefined - still loading either null or string from search params (initial load)
  const [settings, setSettings] = useState<{
    refGuid: string | null;
    templateId: string;
  } | null>(null);
  if (!appInsights) {
    throw new FatalError("Please use a LoggingContext Provider.");
  }
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new FatalError("Please use a UserContext Provider.");
  }
  const domainContext = useContext(JEMContext);
  if (!domainContext) {
    throw new FatalError("Please use a JEMContext Provider.");
  }

  const isFccwIntegrationLive =
    domainContext.initInfo.values?.JeParameters.filter((item) => item.name === "IsFCCWIntegrationLive").map(
      (item) => item.value.toLowerCase() == "true"
    )[0] ?? false;

  const getNoActivityReasonOptions = async () => {
    return await getTaskPropertyFromFCCW(
      props.configuration.GeneralLedgerApi,
      userContext.accessToken,
      FCCWTaskProperty.NoActivityReason,
      isFccwIntegrationLive
    );
  };

  useEffect(
    function getDraftFromEndpoint() {
      if (
        domainContext.initInfo.status === LoadingStatus.Resolved ||
        domainContext.initInfo.status === LoadingStatus.Rejected
      ) {
        console.log("GLCreate: getDraftFromEndpoint");
        const queryString = getQueryStringValues(searchParams);
        if (queryString.RefGuid.trim() !== "") {
          setSettings({
            refGuid: queryString.RefGuid,
            templateId: props.templateId === "4" ? props.templateId : queryString.Templateid
          });
        } else {
          setSettings({
            refGuid: null,
            templateId: props.templateId === "4" ? props.templateId : queryString.Templateid
          });
        }
      }
    },
    [searchParams, domainContext.initInfo.values]
  );

  const setHistoryPanelData = (guid: string | number) => {
    setHistoryPanelScheduleId(guid);
    setOpenHistoryPanel(true);
  };

  return (
    <>
      <GLCreateForm
        settings={settings}
        configuration={{
          GeneralLedgerApi: props.configuration.GeneralLedgerApi,
          DocumentsApi: props.configuration.DocumentsApi,
          featureFlags: props.configuration.featureFlags,
          environment: props.configuration.environment
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onNavigate={(refguid: string, navigateBack: any, recurringJeDetails?: any) => {
          if (navigateBack) {
            const from = location.state && (location.state as any).from ? (location.state as any).from : undefined;
            if (from) {
              navigate(from, { replace: true });
            } else {
              navigate("/gl/dashboard", { replace: true });
            }
          } else {
            const initialQueryString = { ...initialQueryStringValues };
            initialQueryString.Templateid = props.templateId === "4" ? props.templateId : initialQueryString.Templateid;
            initialQueryString.RefGuid = refguid;
            const searchParams = ObjectKeys(initialQueryString).reduce((ctr, key) => {
              ctr[`${key}`] = `${initialQueryString[key]}`;
              return ctr;
            }, {} as Record<string, string>);
            if (recurringJeDetails) {
              searchParams["fetchJeDraft"] = recurringJeDetails.fetchJeDraft;
              searchParams["Templateid"] = recurringJeDetails.templateId;
              initialQueryString.Templateid = recurringJeDetails.templateId;
            }

            navigate(
              {
                search: `?${createSearchParams(searchParams)}`
              },
              { replace: true }
            );
          }
        }}
        domainData={domainContext.initInfo.values}
        openHistoryPanel={setHistoryPanelData}
        getNoActivityReasonOptions={getNoActivityReasonOptions}
      />
      <GLCreateRecurringJeHistoryPanel
        isOpen={openHistoryPanel}
        historyPanelScheduleId={historyPanelScheduleId}
        onClosePanel={() => setOpenHistoryPanel(false)}
        userContext={userContext}
        configuration={props.configuration.GeneralLedgerApi}
      ></GLCreateRecurringJeHistoryPanel>
    </>
  );
};

GLCreate.displayName = "GLCreate";

export default GLCreate;
