import { CoherenceModal } from "@coherence-design-system/controls";
import {
  Stack,
  Label,
  ActionButton,
  PrimaryButton,
  DefaultButton,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { DeepPartial, FieldValues, UnpackNestedValue, useForm } from "react-hook-form";
import BulkEditRow, { IFormEditInputRow } from "./BulkEditRow";
import { ITaskFormInputChoice } from "./TaskBulkEdit.config";
import { FiscalPeriod, JEMChecklistDetailsRow } from "@jem/components";
import { TaskBulkEditInputDto } from "./TaskBulkEditInput";

interface IBulkEditProps<T> {
  //type: string;
  selectedItems?: JEMChecklistDetailsRow[] | [];
  //keyword?: string;
  //filters?: unknown;
  selectedItemsCount: number;
  hideBulkEditModal: () => void;
  loading?: boolean;
  checklistPeriod: Pick<FiscalPeriod, "fiscalMonth" | "fiscalYear">;
  getFormInputs: (
    setValue: (name: string, value: UnpackNestedValue<DeepPartial<T>>) => void,
    checklistPeriod: Pick<FiscalPeriod, "fiscalMonth" | "fiscalYear">
  ) => ITaskFormInputChoice[];
  onSaveItem: (bulkEditInput: TaskBulkEditInputDto) => void;
  IsFCCWIntegrationLive: boolean;
}

export enum BulkEditActionKeys {
  override = "override",
  append = "append",
  remove = "remove"
}
export enum BulkEditType {
  operations = "operations",
  tasks = "tasks"
}
const BulkEdit = <T extends FieldValues>(props: IBulkEditProps<T>): JSX.Element => {
  const {
    //type,
    //keyword,
    //filters,
    selectedItems,
    hideBulkEditModal,
    selectedItemsCount,
    loading,
    getFormInputs,
    onSaveItem,
    IsFCCWIntegrationLive
  } = props;
  const [actionMode, setActionMode] = useState<string>(BulkEditActionKeys.override);

  const form = useForm<T>();
  const { control, handleSubmit, setValue, getValues, errors } = form;
  const formInputs = getFormInputs(setValue, props.checklistPeriod);
  const [bulkEditState, setBulkEditState] = useState<IFormEditInputRow[]>([]);
  const [message, setMessage] = React.useState<string>("");
  const [manualChecklistItems, setManualChecklistItems] = React.useState<JEMChecklistDetailsRow[]>([]);
  const resetComponent = () => {
    setBulkEditState([]);
    hideBulkEditModal();
    setActionMode(BulkEditActionKeys.override);
  };

  useEffect(() => {
    if (IsFCCWIntegrationLive && selectedItems) {
      const nileJes = selectedItems.filter((item) => !item.isTaskEditable);
      const manualJes = selectedItems.filter((item) => item.isTaskEditable);
      if (nileJes.length > 0) {
        setMessage(
          `This action can be performed only on ${manualJes.length} records. ${nileJes.length} records are ineligible.`
        );
      }
      setManualChecklistItems(manualJes);
    }
  }, [selectedItems]);

  const handleOnSaveClick = (formData: UnpackNestedValue<T> | any): void => {
    const bulkEditInput: TaskBulkEditInputDto = {
      SelectedItems: IsFCCWIntegrationLive
        ? manualChecklistItems?.map((i) => i.refGuid).join(",") || ""
        : selectedItems?.map((i) => i.refGuid).join(",") || "",
      BulkEditType: actionMode == BulkEditActionKeys.override ? 1 : actionMode == BulkEditActionKeys.append ? 2 : 3,
      Frequency: formData.frequency?.text || "",
      DraftDateCode:
        Array.isArray(formData.draftDateCode) && formData.draftDateCode?.length !== 0
          ? formData.draftDateCode[0].draftDateCode
          : "",
      BackupJePosters: formData.backupPosters?.join(";") || "",
      BackUpReviewers: formData.backupReviewers?.join(";") || "",
      AssignedPoster: formData.assignedPoster || "",
      EstimatedTime: formData.estimatedTime || "",
      JETaskName: formData.taskName || "",
      Description: formData.description || "",
      ReasonCode: formData.reasonCode?.text || "",
      Purpose: formData.purpose || "",
      JEType: formData.jeType?.text || "",
      CompanyCode: formData.companyCode?.join(",") || "",
      Currency: formData.currencyCode?.text || "",
      ReferenceNumber: formData.referenceNumber || "",
      DocType: formData.documentType?.text || ""
    };
    console.log(bulkEditInput);
    onSaveItem(bulkEditInput);
  };
  const renderFormFields = () => {
    const activeFormInputs = bulkEditState.map((item) => {
      return item.selectedInputKey;
    });
    const unusedFormInputs = formInputs.filter((input) => {
      const isUnused = !activeFormInputs.includes(input.key.toString());
      const showValue = actionMode === BulkEditActionKeys.override ? true : input.isMultiValue;
      return isUnused && showValue;
    });
    return (
      <Stack styles={{ root: { padding: "0 20px 20px 20px ", minHeight: 150 } }}>
        <Stack horizontal>
          <Label styles={{ root: { flex: "0 0 50%" } }}>Field</Label>
          <Label styles={{ root: { flex: "0 0 50%" } }}>Value</Label>
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
          {bulkEditState?.map((bulkEditRow) => {
            return (
              <BulkEditRow
                inputRowProps={bulkEditRow}
                formInputs={formInputs}
                showSingleValueOptions={actionMode === BulkEditActionKeys.override}
                activeFormInputs={activeFormInputs}
                control={control}
                bulkEditState={bulkEditState}
                setBulkEditState={setBulkEditState}
                getValues={getValues}
                errors={errors}
              />
            );
          })}
        </Stack>
        {unusedFormInputs.length > 0 && (
          <Stack.Item>
            <ActionButton
              text="Add more"
              iconProps={{ iconName: "Add" }}
              onClick={() => {
                const currentState = bulkEditState;
                currentState.push({
                  selectedInputKey: unusedFormInputs[0]?.key.toString()
                });
                setBulkEditState([...currentState]);
              }}
            />
          </Stack.Item>
        )}
      </Stack>
    );
  };
  const getMessage = (actionModeString: string) => {
    if (IsFCCWIntegrationLive) {
      return <>{message}</>;
    }
    switch (actionModeString) {
      case BulkEditActionKeys.remove:
        return (
          <>
            Click save to remove selected values from fields on <strong> {selectedItemsCount} </strong> <br></br>
            Fields changes made for Currency Code, Reference Number and Document Type are only applicable for{" "}
            <strong>{"Draft JEs."}</strong>
            <br></br>
            <strong>{"They will not be applied to any selected tasks that are of Batch or Bulk draft type."}</strong>
            <br></br>
          </>
        );
      case BulkEditActionKeys.override:
        return (
          <>
            Click save to replace selected values from fields on <strong> {selectedItemsCount} </strong> <br></br>
            Fields changes made for Currency Code, Reference Number and Document Type are only applicable for{" "}
            <strong>{"Draft JEs."}</strong>
            <br></br>
            <strong>{"They will not be applied to any selected tasks that are of Batch or Bulk draft type."}</strong>
            <br></br>
          </>
        );
      default:
        return (
          <>
            Click save to add selected values to fields on <strong> {selectedItemsCount} </strong> items. <br></br>
            Fields changes made for Currency Code, Reference Number and Document Type are only applicable for{" "}
            <strong>{"Draft JEs."}</strong>
            <br></br>
            <strong>{"They will not be applied to any selected tasks that are of Batch or Bulk draft type."}</strong>
            <br></br>
          </>
        );
    }
  };
  return (
    <CoherenceModal
      isOpen={true}
      modalWidth="small"
      height="responsive"
      title={`Bulk edit`}
      onDismiss={() => {
        setBulkEditState([]);
        hideBulkEditModal();
      }}
    >
      <Stack verticalFill>
        <Stack grow>
          <Pivot
            styles={{ root: { marginBottom: 20, display: "flex" } }}
            onLinkClick={(item: PivotItem | any) => {
              setBulkEditState([]);
              setActionMode(item.props.itemKey);
            }}
          >
            <PivotItem headerText="Change" itemKey={BulkEditActionKeys.override}>
              {renderFormFields()}
            </PivotItem>
            <PivotItem headerText="Append" itemKey={BulkEditActionKeys.append}>
              {renderFormFields()}
            </PivotItem>
            <PivotItem headerText="Remove" itemKey={BulkEditActionKeys.remove}>
              {renderFormFields()}
            </PivotItem>
          </Pivot>
        </Stack>
        <MessageBar
          messageBarType={
            IsFCCWIntegrationLive
              ? manualChecklistItems.length
                ? MessageBarType.warning
                : MessageBarType.error
              : MessageBarType.warning
          }
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <span>{getMessage(actionMode)}</span>
          </Stack>
        </MessageBar>
        <Stack horizontal styles={{ root: { padding: "20px 0" } }} tokens={{ childrenGap: 30 }}>
          <PrimaryButton
            text="Save"
            onClick={handleSubmit(handleOnSaveClick)}
            ariaLabel="Save"
            disabled={(IsFCCWIntegrationLive ? manualChecklistItems.length === 0 : selectedItemsCount == 0) || loading}
          />
          <DefaultButton
            disabled={false}
            text="Cancel"
            onClick={() => {
              resetComponent();
            }}
            ariaLabel="Cancel"
          />
        </Stack>
      </Stack>
    </CoherenceModal>
  );
};
export default BulkEdit;
