/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { IButtonProps } from "@fluentui/react/lib/Button";
import { FocusTrapZone } from "@fluentui/react/lib/FocusTrapZone";
import { css } from "@emotion/css";
import { JEMChecklistDetailsRow } from "@jem/components";
import JemChecklistDetailsCommandBarItems from "./JEMChecklistDetailsCommandBar.config";

const overflowProps: IButtonProps = { ariaLabel: "More commands" };

export interface JEMChecklistDetailsCommandBarRef {
  refreshSelection: (items: any[]) => void;
}

export const JEMChecklistDetailsCommandBar: React.FunctionComponent<{
  onEditClick: (editItem: JEMChecklistDetailsRow) => void;
  onChecklistCloneClicked: () => void;
  onPublishChecklistClicked: () => void;
  onBulkEditClicked: () => void;
  onImportTasksClicked: () => void;
  onNoActivityChecklistClicked: () => void;
  onRefreshChecklistDetailsClicked: () => void;
  onBulkDeleteChecklistClicked: () => void;
  onSignOffClicked: () => void;
  onConvertNiletoManualClicked: () => void;
  disableSignOff: () => boolean;
  onReopenClicked: () => void;
  disableReopen: () => boolean;
  disableAllButSignOff: () => boolean;

  checklistGUID: string;
  backupReviewers: string[];
  backupPosters: string[];
  companyCodes: number[];
  customRef?: React.Ref<JEMChecklistDetailsCommandBarRef>;
  IsFCCWIntegrationLive: boolean;
}> = (props) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [enableFocusTrap] = React.useState(false);

  return (
    <div
      ref={containerRef}
      className={css`
        width: 100%;
        min-height: 1px;
        box-sizing: content-box;
        overflow: hidden;
      `}
    >
      <FocusTrapZone disabled={!enableFocusTrap}>
        <CommandBar
          items={JemChecklistDetailsCommandBarItems({
            onEditClick: props.onEditClick,
            checkListGUID: props.checklistGUID,
            onChecklistCloneClicked: props.onChecklistCloneClicked,
            onPublishChecklistClicked: props.onPublishChecklistClicked,
            onBulkEditClicked: props.onBulkEditClicked,
            onImportTasksClicked: props.onImportTasksClicked,
            onNoActivityChecklistClicked: props.onNoActivityChecklistClicked,
            onRefreshChecklistDetailsClicked: props.onRefreshChecklistDetailsClicked,
            onBulkDeleteChecklistClicked: props.onBulkDeleteChecklistClicked,
            onSignOffClicked: props.onSignOffClicked,
            onConvertNiletoManualClicked: props.onConvertNiletoManualClicked,
            disableSignOff: props.disableSignOff,
            disableAllButSignOff: props.disableAllButSignOff,
            onReopenClicked: props.onReopenClicked,
            disableReopen: props.disableReopen,
            backupReviewers: props.backupReviewers,
            backupPosters: props.backupPosters,
            companyCodes: props.companyCodes,
            IsFCCWIntegrationLive: props.IsFCCWIntegrationLive
          })}
          overflowButtonProps={overflowProps}
          styles={{
            root: {
              paddingLeft: 0
            }
          }}
          ariaLabel="Inbox actions"
          primaryGroupAriaLabel="Email actions"
          farItemsGroupAriaLabel="More actions"
        />
      </FocusTrapZone>
    </div>
  );
};
