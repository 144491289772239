import { JemConfiguration } from "../../../JemConfiguration";
import { MockDataFn } from "../../../Shared/utilities/RequestUtilities";

export interface GLCreateQueryStringParameters {
  RefGuid: string;
  Edit: string;
  Review: string;
  Templateid: string;
}

export interface GLCreateProps {
  configuration: Pick<JemConfiguration, "GeneralLedgerApi" | "DocumentsApi">;
  mockDashboardDataFn?: MockDataFn<any>;
}
export interface SXModelPrediction {
  predictedScore: number;
}

export interface JeLineItemErrors {
  lineNumber: number;
  description: string;
}

export interface GLDraftObjectFromAPI {
  createdBy: string | null;
  createdOn: string | null;
  isReadOnly: boolean | null;
  jeDraftLineItems: JeDraftLineItem[];
  jeDetailsInfo: JeDetailsInfo;
  modifiedBy: string | null;
  modifiedOn: string | null;
  posterComments: string | null;
  reviewerComments: string | null;
  jeId: number;
  rowWeightage: number;
  isExternalDraft: boolean;
}
export interface JeDetailsInfo {
  amounts: {
    currency?: string | null;
    credit: string;
    debit: string;
    total: string;
  }[];
  attachmentStatus: number;
  attachments: number;
  author: string | null;
  checkListAuthors: string | null;
  checkListRefGuid: string | null;
  fcw: {
    checklistGuid: string;
    checklistName: string;
    isAdhocDraft: boolean;
  };
  companyCode: string | null | number;
  currency: string | null;
  description: string | null;
  filePath: string | null;
  fiscalMonth: number;
  fiscalPeriod: string | null;
  fiscalYear: number;
  isbpoEntry: boolean;
  isEdited: boolean;
  isReusable: boolean;
  jePurpose: string | null;
  jeType: string | null;
  poster: string | null;
  postByDate: string | null;
  reasonCode: string | null;
  refGuid: string | null;
  refNumber: string | null;
  reversalDate: string | null;
  reviewer: string | null;
  sdData?: SupportingDocumentData;
  status: number;
  translationDate: string | null;
  uiAction: string | null;
  userComments: UserComments;
  versionNo: number;
  additionalPosters: string | null;
  additionalReviewers: string | null;
  draftName: string | null;
  fileName: string | null;
  hrdInfo: string | null;
  isRecurring: boolean;
  jeRefNo: number;
  lastComments: string | null;
  parentRefGuid: string | null;
  postedBy: string | null;
  postingType: number;
  procStatusAlias: string | null;
  processingStatus: number;
  sapPostingStatus: number;
  sequenceNo: string | null;
  tenantId: string | null;
  allowTenantPosting: boolean;
  templateId: string | null;
  actualTimeTakenMins: number;
  preReviewers: string | null;
  scheduleName: string;
  scheduleStartDate: string | null;
  scheduleEndDate: string | null;
  nextPostingDate: string | null;
  frequency: string;
  recurringJEScheduleId: number;
}

export enum SupportingDocumentMode {
  PrePostMode = 0,
  PostPostingMode = 1,
  BulkMode = 2
}

export interface SupportingDocumentData extends AttachmentInformation {
  bulkJEID: number[];
  countOfDocuments: number;
  currentMode?: SupportingDocumentMode;
  isHBI?: boolean;
  isSelectionEnabled: boolean;
  jeid: number;
  jeType?: number;
  refGuid: string | null;
  sdMode?: number;
}

export interface AttachmentInformation {
  sdInfos: SupportingDocumentDataInfo[];
  selectedStorage: SelectedStorage;
}

export interface SupportingDocumentDataInfo {
  documentType: string;
  fileSize: string;
  fileType: string;
  url?: any;
  size: any;
  type: any;
  fileName: any;
  blobName: string | null;
  // this is a byte[]
  document: any | null;
  // System.IO.FileInfo
  documentInfo: any | null;
  documentSize: number;
  documentURL: string | null;
  errorText: string | null;
  fileID: string | number;
  isSelected: boolean;
  isSelectionEnabled: boolean;
  isStoredInCloud: boolean;
  isStoredInCorpShare: boolean;
  name: string | null;
  status: number;
  statusCode: number;
  uploadedDate: string | null;
}

export interface SelectedStorage {
  regionKey: number;
  regionName: string | null;
}

export interface UserComments {
  addlPosters: string | null;
  comment: string | null;
  commentedAlias: string | null;
  poster: string | null;
}

export interface JeDraftLineItem {
  account: number | 0;
  action: string | null;
  assignment: string | null;
  costCenter: string | null;
  internalOrder: string | null;
  jEid: number | 0;
  lineAmount: number | 0;
  lineAmountLC: number | 0;
  lineAmountLC2: number | 0;
  lineAmountLC3: number | 0;
  lineNum: number | 0;
  lineRefGUID: string;
  lineDescription: string | null;
  lineOrder: number;
  processingStatus: string | null;
  profitCenter: string | null;
  soLineItem: string | null;
  salesOrder: string | null;
  taxCode: string | null;
  tradingPartner: string | null;
  wbsElement: string | null;
  extSourceId: number | 0;
  isExtSourceRow: boolean;
}

export interface GLCreateDraftObjectFromApi {
  result: GLCreateDraftObjectFromApiResult;
  id: number;
  exception: null;
  status: number;
  isCanceled: boolean;
  isCompleted: boolean;
  creationOptions: number;
  asyncState: null;
  isFaulted: boolean;
}

export interface GLCreateDraftObjectFromApiResult {
  fileName: string;
  filePath: null;
  refGuid: string;
  jeId: number;
  templateID: number;
  reviewer: string;
}

export enum GLCreateTabNames {
  jeDetails = "jeDetails",
  recurringJeSchedule = "recurringJeSchedule",
  attachments = "attachments",
  posters = "posters",
  reviewers = "reviewers",
  errorDetails = "errorDetails"
}

export interface FiscalPeriodModel {
  calendarMonth: number;
  calendarYear: number;
  fiscalYear: number;
  fiscalPeriod: string;
  fiscalMonth: number;
  startDate: Date;
  endDate: Date;
  // if there are no fiscal periods from the server, this will be null
  index: number | null;
}

export interface JeDraftRequest {
  jeDetailsInfo: JeDetailsInfoHeaderRequest;
  jeDraftLineItems: JeDraftLineItemRequest[];
}

export interface JeDetailsInfoHeaderRequest {
  additionalPosters: string | null;
  additionalReviewers: string | null;
  amounts: {
    currency: string;
    credit: string;
    debit: string;
    total: string;
  }[];
  attachmentStatus: number;
  attachments: number;
  author: string;
  checkListAuthors: string;
  checkListRefGuid: string;
  companyCode: number | null;
  currency: string;
  description: string;
  draftName: string;
  fileName: string | null;
  filePath: string | null;
  fiscalMonth: number;
  fiscalPeriod: string;
  fiscalYear: number;
  hrdInfo: string | null;
  isBPOEntry: boolean | null;
  isEdited: boolean;
  isReusable: boolean;
  isReccuring: boolean;
  jEPurpose: string | null;
  jEType: string;
  jERefNo: number;
  lastComments: string | null;
  poster: string;
  postByDate: string | null;
  parentRefGuid: string | null;
  postedBy: string;
  postingType: number;
  processingStatus: number;
  procStatusAlias: string;
  reasonCode: string;
  refGuid: string;
  refNumber: string;
  reversalDate: string | null;
  reviewer: string | null;
  sdData?: SupportingDocumentData | null;
  status: number;
  sAPPostingStatus: number;
  sequenceNo: string;
  translationDate: string | null;
  jeDate: string | null;
  uiAction: string | null;
  userComments: {
    addlPosters?: string | null;
    comment: string | null;
    commentedAlias: string | null;
    poster?: string | null;
  };
  versionNo: number;
  tenantId: string;
  allowTenantPosting: boolean;
  fcwChecklistGuid: string | null;
  fcwChecklistName: string | null;
  actualTimeTakenMins: number;
  preReviewers: string | null;
  recurringJEid: number;
  scheduleName: string | null;
  frequency: string;
  scheduleStartDate: string | null;
  scheduleEndDate: string | null;
  nextPostingDate: string | null;
  lastPostedDate: string | null;
  isLongTerm: boolean;
  allowedToPost: boolean;
  lastProcessed: string | null;
  templateJeId: number;
  featureFlag: boolean;
  recurringJEScheduleId: number;
}

export interface JeDraftLineItemRequest {
  lineRefGUID: string;
  account: number;
  costCenter: string | null;
  internalOrder?: string;
  salesOrder?: string;
  sOLineItem?: string;
  wBSElement?: string;
  tradingPartner?: string;
  profitCenter?: string;
  lineAmountLC?: number | null;
  lineAmountLC2?: number | 0;
  lineAmountLC3?: number | 0;
  lineAmount?: number | null;
  lineDescription?: string;
  assignment?: string;
  taxCode?: string;
  action: string;
  lineOrder: number;
  amount?: number;
  amountLc?: number;
  lineNum?: number;
}

export interface SXModelPrediction {
  predictedScore: number;
}

export interface SaveJeDraftResponse {
  jeHeaderError: string | null;
  jeAuditError: string | null;
  jeLineItemErrors:
    | null
    | {
        lineNumber: number;
        description: string;
      }[];
  otherErrors: string | null;
  warningMessage: string;
  hasError: boolean;
  result: number;
  jeDetails?: GLDraftObjectFromAPI;
}

export interface ValidateCallResponse {
  jeHeaderError: string | null;
  jeAuditError: string | null;
  jeLineItemErrors:
    | null
    | {
        lineNumber: number;
        description: string;
      }[];
  otherErrors: string | null;
  sapCallSuccess: boolean;
  sxModelPrediction: SXModelPrediction[];
  warningMessage: string;
  hasError: boolean;
  jeDetails?: GLDraftObjectFromAPI;
}

export interface PreReviewCallResponse {
  recurringJEScheduleId: number;
  errorCode: string;
  refGuid: string;
  submitStatus: boolean;
  notification: boolean;
}
