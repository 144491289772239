/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, DatePicker, IDropdownOption, ITextFieldProps, TextField } from "@fluentui/react";
import { FormEvent } from "react";
import { FieldValue, FieldValues } from "react-hook-form";
import {
  JEMAliasPicker,
  JEMAliasPickerProps,
  CompanyCodeMultiSelector,
  CompanyCodeMultiSelectorProps,
  FrequencyDropdown,
  IFrequencyProps,
  DraftDateCodeDropdown,
  DraftDateCodeDropdownProps,
  FiscalPeriod
} from "@jem/components";
import { TaskBulkEditInputDto } from "./TaskBulkEditInput";
import { IJETypeProps, JETypeDropdown } from "./JETypeDropDown";
import { IReasonCodeProps, ReasonCodeDropdown } from "./ReasonCodeDropDown";
import { DocumentTypeDropdown, IDocumentTypeProps } from "./DocumentTypeDropDown";
import { CurrencyCodeDropdown, ICurrencyCodeProps } from "./CurrencyCodeDropDown";

export interface ITaskFormInputChoice extends IDropdownOption {
  component: any; //(props: any) => React.FC; //Fc
  props?: () =>
    | JEMAliasPickerProps
    | Omit<CompanyCodeMultiSelectorProps, "defaultValue">
    | ITextFieldProps
    | IFrequencyProps
    | DraftDateCodeDropdownProps
    | Partial<IReasonCodeProps>
    | Partial<IDocumentTypeProps>
    | Partial<IJETypeProps>
    | Partial<ICurrencyCodeProps>;
  isMultiValue?: boolean;
}

export const getFormInputConfig = (
  setValue: <TFieldName extends string, TFieldValues extends FieldValues>(
    name: TFieldName,
    value: FieldValue<TFieldValues>
  ) => void,
  checklisPeriod: Pick<FiscalPeriod, "fiscalMonth" | "fiscalYear">
): ITaskFormInputChoice[] => {
  return [
    {
      key: "assignedPoster",
      text: "Assigned Poster",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("assignedPoster", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    },
    {
      key: "backupPosters",
      text: "Backup Posters",
      component: JEMAliasPicker,
      props: () => {
        return {
          hideLabel: true,
          onChange: (newBackupPosters) => {
            setValue("backupPosters", newBackupPosters);
          }
        } as JEMAliasPickerProps;
      },
      isMultiValue: true
    },
    {
      key: "backupReviewers",
      text: "Backup Reviewers",
      component: JEMAliasPicker,
      props: () => {
        return {
          hideLabel: true,
          onChange: (newBackupPosters) => {
            setValue("backupReviewers", newBackupPosters);
          }
        } as JEMAliasPickerProps;
      },
      isMultiValue: true
    },
    {
      key: "companyCode",
      text: "Company Code",
      component: CompanyCodeMultiSelector,
      props: () => {
        return {
          hideLabel: true,
          onChange: (companyCodeTags: number[]): void => {
            setValue("companyCode", companyCodeTags.length > 0 ? companyCodeTags : null);
          }
        } as Omit<CompanyCodeMultiSelectorProps, "defaultValue">;
      },
      isMultiValue: true
    },
    {
      key: "frequency",
      text: "Frequency",
      component: FrequencyDropdown,
      props: () => {
        return {
          hideLabel: true,
          onChange: (newValue: IDropdownOption<any> | undefined): void => {
            setValue("frequency", newValue?.text);
          }
        } as IFrequencyProps;
      },
      isMultiValue: false
    },
    {
      key: "draftDateCode",
      text: "Draft Date Code",
      component: DraftDateCodeDropdown,
      props: () => {
        return {
          selectedFiscalPeriod: checklisPeriod,
          fcwFiscalCalendarLink: "",
          hideLabel: true,
          selectedDraftDateCodes: [],
          onChange: (newValue) => {
            console.log(newValue);
            setValue("draftDateCode", newValue[0].draftDateCode);
          }
        } as DraftDateCodeDropdownProps;
      },
      isMultiValue: false
    },
    {
      key: "reasonCode",
      text: "Reason Code",
      component: ReasonCodeDropdown,
      props: () => {
        return {
          onChange: (newValue: IDropdownOption<any> | undefined): void => {
            setValue("reasonCode", newValue?.key);
          }
        } as Partial<IReasonCodeProps>;
      },
      isMultiValue: false
    },
    {
      key: "documentType",
      text: "Document Type",
      component: DocumentTypeDropdown,
      props: () => {
        return {
          onChange: (newValue: IDropdownOption<any> | undefined): void => {
            setValue("documentType", newValue?.key);
          }
        } as Partial<IDocumentTypeProps>;
      },
      isMultiValue: false
    },
    {
      key: "jeType",
      text: "JE Type",
      component: JETypeDropdown,
      props: () => {
        return {
          onChange: (newValue: IDropdownOption<any> | undefined): void => {
            setValue("jeType", newValue?.key);
          }
        } as Partial<IJETypeProps>;
      },
      isMultiValue: false
    },
    {
      key: "currencyCode",
      text: "Currency Code",
      component: CurrencyCodeDropdown,
      props: () => {
        return {
          onChange: (newValue: IDropdownOption<any> | undefined): void => {
            setValue("currencyCode", newValue?.key);
          }
        } as Partial<ICurrencyCodeProps>;
      },
      isMultiValue: false
    },
    {
      key: "description",
      text: "Task description",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("description", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    },
    {
      key: "taskName",
      text: "Task name",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("taskName", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    },
    {
      key: "estimatedTime",
      text: "Estimated Time (mins)",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("estimatedTime", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    },
    {
      key: "referenceNumber",
      text: "Reference Number",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("referenceNumber", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    },
    {
      key: "purpose",
      text: "Purpose",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("purpose", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    }
  ];
};

export const getNewFormInputConfig = (
  setValue: <TFieldName extends string, TFieldValues extends FieldValues>(
    name: TFieldName,
    value: FieldValue<TFieldValues>
  ) => void
  // checklisPeriod?: Pick<FiscalPeriod, "fiscalMonth" | "fiscalYear">
): ITaskFormInputChoice[] => {
  return [
    {
      key: "reasonCode",
      text: "Reason Code",
      component: ReasonCodeDropdown,
      props: () => {
        return {
          onChange: (newValue: IDropdownOption<any> | undefined): void => {
            setValue("reasonCode", newValue?.key);
          }
        } as Partial<IReasonCodeProps>;
      },
      isMultiValue: false
    },
    {
      key: "postingDueDate",
      text: "Posting Due Date",
      component: DatePicker,
      props: () => {
        return {
          onSelectDate: (newValue: any | undefined): void => {
            setValue("postingDueDate", newValue);
          }
        } as Partial<IReasonCodeProps>;
      },
      isMultiValue: false
    },
    {
      key: "purpose",
      text: "Purpose",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("purpose", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    },
    {
      key: "description",
      text: "Task description",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("description", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    },
    {
      key: "assignedPosters",
      text: "Assigned Posters",
      component: JEMAliasPicker,
      props: () => {
        return {
          hideLabel: true,
          onChange: (newBackupPosters) => {
            setValue("assignedPosters", newBackupPosters);
          }
        } as JEMAliasPickerProps;
      },
      isMultiValue: true
    },
    {
      key: "backupReviewers",
      text: "Backup Reviewers",
      component: JEMAliasPicker,
      props: () => {
        return {
          hideLabel: true,
          onChange: (newBackupPosters) => {
            setValue("backupReviewers", newBackupPosters);
          }
        } as JEMAliasPickerProps;
      },
      isMultiValue: true
    },
    {
      key: "referenceNumber",
      text: "Reference Number",
      component: TextField,
      props: () => {
        return {
          onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setValue("referenceNumber", newValue);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    },
    {
      key: "currencyCode",
      text: "Currency Code",
      component: CurrencyCodeDropdown,
      props: () => {
        return {
          onChange: (newValue: IDropdownOption<any> | undefined): void => {
            setValue("currencyCode", newValue?.key);
          }
        } as Partial<ICurrencyCodeProps>;
      },
      isMultiValue: false
    },
    {
      key: "documentType",
      text: "Document Type",
      component: DocumentTypeDropdown,
      props: () => {
        return {
          onChange: (newValue: IDropdownOption<any> | undefined): void => {
            setValue("documentType", newValue?.key);
          }
        } as Partial<IDocumentTypeProps>;
      },
      isMultiValue: false
    },
    {
      key: "allowDualCurrencyPosting",
      text: "Allow Dual currency Posting",
      component: Checkbox,
      props: () => {
        return {
          onChange: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
            setValue("allowDualCurrencyPosting", !!checked);
          }
        } as ITextFieldProps;
      },
      isMultiValue: false
    }
  ];
};

export const getUpdateTaskMapper = (taskInputData: TaskBulkEditInputDto) => {
  return {
    SelectedItems: taskInputData.SelectedItems,
    BulkEditType: taskInputData.BulkEditType,
    Frequency: taskInputData.Frequency,
    DraftDateCode: taskInputData.DraftDateCode,
    BackupJePosters: taskInputData.BackupJePosters,
    BackUpReviewers: taskInputData.BackUpReviewers,
    AssignedPosters: taskInputData.AssignedPoster,
    EstimatedTime: taskInputData.EstimatedTime,
    JETaskName: taskInputData.JETaskName,
    Description: taskInputData.Description,
    ReasonCode: taskInputData.ReasonCode,
    Purpose: taskInputData.Purpose,
    JEType: taskInputData.JEType,
    CompanyCode: taskInputData.CompanyCode,
    Currency: taskInputData.Currency,
    ReferenceNumber: taskInputData.ReferenceNumber,
    DocType: taskInputData.DocType
  };
};

export const getRemoveTaskMapper = (taskInputData: TaskBulkEditInputDto) => {
  return {
    BackupJePosters: taskInputData.BackupJePosters,
    BackUpReviewers: taskInputData.BackUpReviewers,
    CompanyCode: taskInputData.CompanyCode
  };
};
