import { CoherencePanel, CoherencePanelSize } from "@coherence-design-system/controls";
import React, { useEffect } from "react";
import ActionButtons from "../../copiedItems/ActionButtons";
import { JEMChecklistDetailsRow, LocalMessage } from "@jem/components";
import { useForm } from "react-hook-form";
import {
  Stack,
  Label,
  FocusZone,
  DetailsList,
  Text,
  DetailsListLayoutMode,
  MessageBar,
  MessageBarType
} from "@fluentui/react";
import InlineFormInputErrorMessage from "./InlineFormInputErrorMessage";

interface IJEMChecklistDetailsPublishPanelProps {
  checklistItems: JEMChecklistDetailsRow[];
  checklistName: string;
  checklistPeriod: string;
  checklistRefGUID: string;
  onClosePanel: () => void;
  onSaveItem: (checklistItems: JEMChecklistDetailsRow[] | []) => void;
  loading?: boolean;
  error: string[];
  IsFCCWIntegrationLive: boolean;
}

const JEMChecklistDetailsPublishPanel = (props: IJEMChecklistDetailsPublishPanelProps): JSX.Element => {
  const { checklistItems, onClosePanel, onSaveItem, error, loading, IsFCCWIntegrationLive } = props;
  const [message, setMessage] = React.useState<LocalMessage | null>(null);
  const [manualChecklistItems, setManualChecklistItems] = React.useState<JEMChecklistDetailsRow[]>(checklistItems);

  const form = useForm();
  const { handleSubmit } = form;

  useEffect(() => {
    if (IsFCCWIntegrationLive) {
      const nileJes = checklistItems.filter((item) => !item.isTaskEditable);
      const manualJes = checklistItems.filter((item) => item.isTaskEditable);
      if (nileJes.length > 0) {
        setMessage({
          type: manualJes.length ? MessageBarType.warning : MessageBarType.error,
          message: `This action can be performed only on ${manualJes.length} records. ${nileJes.length} records are ineligible.`
        });
      }
      setManualChecklistItems(manualJes);
    }
  }, [checklistItems]);

  const _columns = [
    { key: "column1", name: "JETaskName", fieldName: "jeTaskName", minWidth: 100, maxWidth: 200, isResizable: true },
    {
      key: "column2",
      name: "PublishedState",
      fieldName: "publishedState",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true
    }
  ];

  const onHandleSubmit = () => {
    //do something with form data and call onSaveItem

    onSaveItem(manualChecklistItems);
  };

  return (
    <CoherencePanel
      panelSize={CoherencePanelSize.small}
      titleText="Publish JE Tasks"
      isOpen
      onDismiss={onClosePanel}
      hasCloseButton
      closeButtonAriaLabel="Close Publish JE Tasks"
      onRenderFooter={(): JSX.Element => (
        <ActionButtons
          disabled={manualChecklistItems.length == 0 || loading}
          mutationLoading={false}
          closePanel={onClosePanel}
          handleSubmit={handleSubmit(onHandleSubmit)}
          saveLabel="Publish"
          saveTitle="Publish"
          cancelLabel="Cancel"
          cancelTitle="Cancel"
        />
      )}
    >
      <InlineFormInputErrorMessage errorMessages={error} />
      <form onSubmit={onHandleSubmit}>
        <Stack tokens={{ childrenGap: 20 }}>
          {message && (
            <MessageBar
              messageBarType={message.type}
              isMultiline={true}
              onDismiss={() => setMessage(null)}
              dismissButtonAriaLabel="Close"
            >
              {message.message}
            </MessageBar>
          )}
          <span></span>
          <Label> Checklist {props.checklistName}</Label>
          <Label>Posting Period {props.checklistPeriod}</Label>

          {manualChecklistItems.length > 0 ? (
            <>
              <Text
                variant="xLarge"
                style={{
                  color: "var(--accent-font-color, black)"
                }}
              >
                Checklist JE Tasks to be published {/*{checklistItemsJeTasks}*/}
              </Text>
              <FocusZone>
                <div>
                  <DetailsList
                    items={manualChecklistItems}
                    columns={_columns}
                    setKey="set"
                    layoutMode={DetailsListLayoutMode.justified}
                  />
                </div>
              </FocusZone>
            </>
          ) : (
            <Text
              variant="xLarge"
              style={{
                color: "var(--accent-font-color, black)"
              }}
            >
              No Items to show.
            </Text>
          )}
        </Stack>
      </form>
    </CoherencePanel>
  );
};
export default JEMChecklistDetailsPublishPanel;
